.ui-clickable {
  cursor: pointer;

  &-disabled {
    cursor: default;
  }
}

.p-hoverable:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.p-widget-overlay {
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-align-text-end {
  text-align: end;
}

.p-ink {
  display: none !important;
}

.p-disabled {
  opacity: 0.5;
  cursor: default !important;
}
