app-layout-navbar {
  display: block;

  .app-layout-navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;

    &.settings-navbar {
      justify-content: flex-start;
      overflow-y: auto;
      white-space: nowrap;

      .nav.nav-pills {
        flex-wrap: nowrap;
      }
    }

    .row {
      flex: 1;
    }
  }
}
