// Imput group
$ui-inputgroup-margin-bottom: 1.5rem;

// Buttons
$ui-button-font-family: null;
$ui-button-padding-y: $input-padding-y;
$ui-button-box-shadow: 0px;

// Inputs

// Selects, dropdowns, multiselect
$ui-select-bg: $input-bg;
$ui-select-color: $input-color;

$input-select-active-bg: $background-image-gradient;
$input-select-active-color: $white;

$input-select-focus-bg: $input-border-color;
$input-select-focus-color: $input-color;

// Multiselect
$ui-multiselect-min-width: 13rem;
$ui-multiselect-active-bg: $input-select-active-bg;
$ui-multiselect-active-color: $input-select-active-color;
$ui-multiselect-focus-bg: $input-select-focus-bg;
$ui-multiselect-focus-color: $input-select-focus-color;
$ui-multiselect-padding-y: $input-padding-y;
$ui-multiselect-padding-x: $input-padding-x;
$ui-multiselect-border-color: $input-border-color;
$ui-multiselect-border-width: $input-border-width;
$ui-multiselect-border: $ui-multiselect-border-width solid $ui-multiselect-border-color;
$ui-multiselect-border-radius: $input-border-radius;

// Dropdown
$ui-dropdown-bg: $input-bg;
$ui-dropdown-color: $input-color;
$ui-dropdown-font-size: $input-font-size;
$ui-dropdown-border-color: $input-border-color;
$ui-dropdown-border-width: $input-border-width;
$ui-dropdown-border: $ui-dropdown-border-width solid $ui-dropdown-border-color;
$ui-dropdown-border-radius: $input-border-radius;
$ui-dropdown-padding-y: $input-padding-y;
$ui-dropdown-padding-x: $input-padding-x;
$ui-dropdown-focus-bg: $input-select-focus-bg;
$ui-dropdown-focus-color: $input-select-focus-color;
$ui-dropdown-active-bg: $input-select-active-bg;
$ui-dropdown-active-color: $input-select-active-color;

$ui-dropdown-panel-bg: $ui-dropdown-bg;
$ui-dropdown-panel-color: $ui-dropdown-color;
$ui-dropdown-panel-border-color: $input-border-color;
$ui-dropdown-panel-border-width: $input-border-width;
$ui-dropdown-panel-border: $ui-dropdown-panel-border-width solid $ui-dropdown-panel-border-color;
$ui-dropdown-panel-item-padding-y: $ui-dropdown-padding-y;
$ui-dropdown-panel-item-padding-x: $ui-dropdown-padding-x;

// InputSwitch

// Slider
$ui-slider-bg: $input-border-color;
$ui-slider-range-bg: $success;

$ui-slider-handle-width: #{$input-line-height-sm}rem;
$ui-slider-handle-height: #{$input-line-height-sm}rem;
$ui-slider-handle-bg: $input-bg;
$ui-slider-handle-border-color: $ui-slider-range-bg;
$ui-slider-handle-border-width: 5px;
$ui-slider-handle-border: $ui-slider-handle-border-width solid $ui-slider-handle-border-color;

// Checkbox
$ui-checkbox-width: 20px;
$ui-checkbox-height: $ui-checkbox-width;
$ui-checkbox-font-size: $font-size-base;
$ui-checkbox-bg: $input-bg;
$ui-checkbox-color: $input-color;
$ui-checkbox-border-color: $input-border-color;
$ui-checkbox-border-width: $input-border-width;
$ui-checkbox-border: $ui-checkbox-border-width solid $ui-checkbox-border-color;
$ui-checkbox-border-radius: $input-border-radius;

$ui-checkbox-active-bg: $white;
$ui-checkbox-active-border-color: $ui-checkbox-border-color;
$ui-checkbox-active-color: $success;

// Autocomplete
$ui-autocomplete-focus-bg: $input-select-focus-bg;
$ui-autocomplete-focus-color: $input-select-focus-color;
$ui-autocomplete-active-bg: $input-select-active-bg;
$ui-autocomplete-active-color: $input-select-active-color;

$ui-autocomplete-panel-bg: $ui-dropdown-bg;
$ui-autocomplete-panel-color: $ui-dropdown-color;
$ui-autocomplete-panel-font-size: $font-size-sm;
$ui-autocomplete-panel-border-color: $input-border-color;
$ui-autocomplete-panel-border-width: $input-border-width;
$ui-autocomplete-panel-border: $ui-autocomplete-panel-border-width solid $ui-autocomplete-panel-border-color;
$ui-autocomplete-panel-item-padding-y: $ui-dropdown-padding-y;
$ui-autocomplete-panel-item-padding-x: $ui-dropdown-padding-x;

// Calendar / Datepicker
$ui-calendar-min-width: 13rem;
$ui-calendar-bg: $input-bg;
$ui-calendar-color: $input-color;
$ui-calendar-font-size: $input-font-size;
$ui-calendar-border-color: $input-border-color;
$ui-calendar-border-width: $input-border-width;
$ui-calendar-border: $ui-calendar-border-width solid $ui-calendar-border-color;
$ui-calendar-border-radius: $input-border-radius;
$ui-calendar-padding-y: $input-padding-y;
$ui-calendar-padding-x: $input-padding-x;

// Tooltip
$ui-tooltip-max-width: $popover-max-width;
$ui-tooltip-font-size: $popover-font-size;
$ui-tooltip-bg: $popover-bg;
$ui-tooltip-color: $popover-body-color;
$ui-tooltip-border-width: $popover-border-width;
$ui-tooltip-border-color: $popover-border-color;
$ui-tooltip-border-radius: $popover-border-radius;
$ui-tooltip-box-shadow: $popover-box-shadow;

$ui-tooltip-arrow-color: $popover-arrow-color;
$ui-tooltip-arrow-outer-color: $popover-arrow-outer-color;
$ui-tooltip-arrow-width: 0.5rem;
$ui-tooltip-arrow-height: 0.5rem;

$ui-tooltip-body-padding-y: $popover-body-padding-y;
$ui-tooltip-body-padding-x: $popover-body-padding-x;
