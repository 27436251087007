@use "sass:map";

app-layout-aside {
  .app-layout-aside {
    position: relative;
    width: $aside-menu-width;
    height: 100%;
    min-height: $aside-menu-height;
    background: $aside-menu-bg;
    color: $aside-menu-color;
    border-right: map.get($aside-menu-borders, 'right');

    .aside-toggle {
      cursor: pointer;
      width: 2rem;
      height: 100%;
      position: absolute;
      right: -3.5rem;
      top: 0;
      border: 1px solid $light;
      border-left: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background: $aside-menu-bg;
      padding: 0.5rem;
      z-index: 999;
    }

    nav {
      padding: $aside-padding-y;

      &.aside-buttons {
        padding: $aside-padding-y $aside-padding-x;

        li {
          width: 100%;

          & > *:not(.aside-toggle) {
            width: 100%;
          }
        }
      }

      &.aside-nav {
        ul.nav {
          overflow: hidden auto;

          li.nav-item {
            max-width: 100%;
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }

  &.aside-minimized {
    .app-layout-aside {
      width: 0;
      padding: $aside-padding-y 0;

      .aside-toggle {
        height: unset;
        right: -1.1rem;
      }
    }
  }
}
