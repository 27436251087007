@import '~primeng/resources/components/table/table';

.p-datatable {
  table {
    width: 100%;
    color: $table-color;
    background-color: $table-bg;
    border: $table-border;
  }

  .p-datatable-thead {
    background-color: $table-head-bg;

    th {
      font-weight: $font-weight-semibold;

      .p-sortable-column-icon {
        margin-left: 0.2rem;
        font-size: $font-size-sm;
      }
    }
  }

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: middle;
    border-bottom: none;
    word-break: break-word;
    position: relative;
  }

  .p-datatable-tbody {
    tr {
      background-color: $gray-200;
      cursor: pointer;

      &.click-disabled {
        cursor: default;
      }

      &:hover {
        background-color: $gray-255;
      }

      &:nth-child(even) {
        background-color: $white;

        &:hover {
          background-color: $gray-255;
        }
      }
    }
  }

  .p-datatable-footer {
    padding: $table-cell-padding;
    vertical-align: middle;
    border-top: 0 none;
    font-weight: $font-weight-semibold;
    text-align: right;
  }

  .ui-table-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    z-index: 1;
  }

  .ui-table-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1rem;
    margin-left: -1rem;
    font-size: 4rem;
    color: $gray-base;
  }

  .p-datatable-scrollable-body {
    position: relative;
    overflow: auto;
  }

  .p-datatable-scrollable-footer,
  .p-datatable-scrollable-header {
    overflow: visible !important;
  }

  .p-paginator-current {
    color: $gray-400;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: 2rem;
  }

  .p-paginator-bottom {
    margin: 0 !important;
    padding: 0.5rem 0;
    border-width: 0px 1px 1px 1px;
    border-color: $gray-200;
    border-style: solid;
  }

  .p-paginator .p-paginator-element {
    &.p-paginator-prev {
      padding: 0 0.5rem;
    }
  }

  p-dropdown {
    margin-left: 2rem;
  }

  .p-paginator-element {
    font-size: 14px;
    color: $gray-400;
  }

  .p-column-filter-container {
    .p-input-group {
      margin: 0;
    }

    .p-input-group-icon {
      max-width: fit-content;
    }

    .p-column-filter {
      max-width: max-content;

      .p-multiselect {
        min-width: 100%;
      }
    }
  }
}
