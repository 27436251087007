@use 'sass:map';

$menu-list: 'campaigns', 'groups', 'emails', 'sms', 'notification', 'whatsapp', 'changelog', 'dashboard';
$types: (
        'onetime': (
                'active',
                'draft',
                'finished',
                'hold',
        ),
        'continuous': (
                'active',
                'draft',
                'finished',
                'hold',
        ),
        'instant': (
                'active',
                'draft',
                'finished',
                'hold',
        ),
);
$channels: 'emails', 'notification', 'sms', 'whatsapp';

.icon:before {
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@each $li in $menu-list {
  .icon-menu-#{$li} {
    &:before {
      content: url('../assets/icons/#{$li}-white.svg');
    }

    &.active {
      &:before {
        content: url('../assets/icons/#{$li}-blue.svg');
      }
    }
  }
}

@each $type, $statuses in $types {
  .icon-#{$type} {
    @each $status in $statuses {
      &-#{$status} {
        &:before {
          content: url('../assets/icons/#{$type}-#{$status}.svg');
        }
      }
    }
  }
}

@each $channel in $channels {
  .icon-#{$channel}-dashboard {
    &:before {
      content: url('../assets/icons/#{$channel}-dashboard.svg');
    }
  }
}

.icon-edit {
  &:before {
    content: url('../assets/icons/edit.svg');
  }
}

.icon-voucher {
  &:before {
    content: url('../assets/icons/voucher.svg');
  }
}

.icon-settings {
  &:before {
    content: url('../assets/icons/settings.svg');
  }
}

.icon-logout {
  &:before {
    content: url('../assets/icons/logout.svg');
  }
}
