@import 'create-campaign';
@import 'insights/_insights.scss';

app-change-logs app-table {
  .date-column .p-input-group {
    max-width: fit-content;
  }
}

app-campaigns app-table {
  .campaign-name-col {
    width: 15rem;
    max-width: 15rem;
    min-width: 15rem;
  }

  .campaign-col {
    width: 10rem;
    max-width: 10rem;
  }

  .p-contextmenu {
    width: 7rem;
    transform: translate(-25%, 0);
    white-space: nowrap;
  }

  .contextmenu-cell {
    text-align: center;
  }

  .text-wrapping {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (min-width: 1440px) {
      white-space: nowrap;
    }
  }
}
