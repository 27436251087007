@mixin button-variant(
  $bg-start,
  $bg-end,
  $color: color-yiq($bg-start),
  $hover-background: darken($bg-start, 7.5%),
  $active-background: darken($bg-start, 10%)
) {
  color: $color;
  @if $enable-gradients {
    background-color: $bg-start;
    @include gradient-x($bg-start, $bg-end);
    border: none;
  } @else {
    background: $bg-start;
    border-color: $bg-start;
  }
  @include box-shadow($btn-box-shadow);

  @include hover {
    @if $enable-gradients {
      @include gradient-x(darken($bg-start, 10%), darken($bg-end, 10%));
      color: $color;
      border: none;
    } @else {
      color: $color;
      background: darken($bg-start, 10%);
      border-color: darken($bg-start, 10%);
    }
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($bg-start), $bg-end, 15%), 0.5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($bg-start), $bg-end, 15%), 0.5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $color;
    background-color: $bg-start;
    border: none;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border: none;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($bg-start), $bg-start, 15%), 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($bg-start), $bg-start, 15%), 0.5);
      }
    }
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  color: $black;
  border-color: $color;
  box-sizing: border-box;

  @include hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
      }
    }
  }
}

@mixin button-icon-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: $color;

  @include hover {
    color: $color;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
      }
    }
  }
}

@mixin button-outline-icon-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  border: 1px solid $color;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: $font-size-sm;
}
