ul.nav {
  display: flex;
  padding: 0;

  &.nav-pills {
    li.nav-item {
      cursor: pointer;
    }
  }

  &.nav-vertical {
    padding: $spacer $spacer 0;
    flex-direction: column;

    li.nav-item {
    }
  }

  &.nav-horizontal {
    flex-direction: row;

    li.nav-item {
      margin-right: $spacer / 2;
      //margin-top: $spacer / 4;
      margin-bottom: $spacer / 2;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
