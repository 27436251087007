app-settings-general {
  .control-title {
    span {
      display: block;
    }
  }

  .control-label {
    padding: $input-btn-padding-y-lg;
    text-align: center;
  }
}
