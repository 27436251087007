@import '~primeng/resources/components/accordion/accordion';

p-accordion {
  .p-accordion {
    background-color: $blue-255;
    margin: 0 auto;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);

    .p-accordion-tab {
      margin-bottom: 0;
    }

    .p-accordion-header {
      .p-accordion-header-link {
        padding: 1rem;
        color: #495057;
        transition: box-shadow 0.2s;
      }
    }

    .p-accordion-content {
      padding: 1rem;
      position: relative;
      background-color: $white;
    }
  }
}
