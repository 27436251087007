@import '~primeng/resources/components/dropdown/dropdown';

p-dropdown {
  &.is-invalid {
    .p-dropdown {
      border: 1px solid $red;
    }
  }

  .p-dropdown {
    position: relative;
    min-width: 100%;
    background: $ui-dropdown-bg;
    border: $ui-dropdown-border;
    transition: border-color 0.2s;
    border-radius: $ui-dropdown-border-radius;
    font-size: $ui-dropdown-font-size;
    display: flex;

    span.p-dropdown-label {
      cursor: pointer;
      display: block;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 0;
    }

    .p-dropdown-trigger {
      background-color: $ui-dropdown-bg;
      padding: $ui-dropdown-padding-y $ui-dropdown-padding-x $ui-dropdown-padding-y 0;
      cursor: pointer;
    }

    .p-dropdown-clear-icon {
      position: absolute;
      right: 2rem;
      top: 12px;
      cursor: pointer;
    }

    .p-dropdown-panel {
      position: absolute;
      background: $ui-dropdown-bg;
      width: 100%;
      border: $ui-dropdown-border;

      .p-dropdown-items-wrapper {
        overflow: auto;

        ul.p-dropdown-items {
          li.p-dropdown-item {
            cursor: pointer;
            padding: $ui-dropdown-panel-item-padding-y $ui-dropdown-panel-item-padding-x;

            &:hover {
              background: $gray-300;
              color: $ui-dropdown-focus-color;
            }

            &.p-highlight {
              background: $ui-dropdown-active-bg;
              color: $ui-dropdown-active-color;

              &:hover {
                background: $ui-dropdown-active-bg;
                color: $ui-dropdown-active-color;
              }
            }
          }
        }
      }
    }
  }
}
