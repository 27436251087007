app-conditions-template {
  .conditions-label {
    padding: 0.5rem 0;
    text-overflow:ellipsis;
    overflow:hidden;

    .control-label {
      font-size: 0.9rem;
    }
  }

  .condition-container {
    background-color: rgba(128, 128, 128, 0.3);
    padding: 1rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .no-gutter {
    padding-right: -15px;
    padding-left: -15px;
    @media (max-width: 1200px) {
      padding: 0;
    }
  }

  @media (min-width: 1440px) {
    .no-margin {
      margin: 0;
    }
  }

  .first-order {
    order: 1;
    @media (max-width: 1200px) {
      order: -1;
    }
  }

  .no-gutter-left {
    @media (min-width: 1440px) {
      padding-left: 0;
    }
  }

  .trigger-template {
    @media (max-width: 1440px) and (min-width: 1367px) {
      &.col-xl-4 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
    }
    @media (max-width: 1366px) and (min-width: 1200px) {
      &.col-xl-4 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
      }
    }
  }

  .condition-template {
    @media (max-width: 1440px) and (min-width: 1200px) {
      &.col-xl-2 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &.col-xl-3 {
        flex: 0 0 33.3333333%;
        max-width: 33.3333333%;
      }
    }
  }
}
