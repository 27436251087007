@import '~bootstrap/scss/variables';

$enable-shadows: true;
$enable-gradients: true;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
  'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-family-headings: 'Montserrat', 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial;
// stylelint-enable value-keyword-case

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.5;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base * 0.75;
$font-size-xxs: $font-size-base * 0.6;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer / 2;
$headings-font-family: $font-family-headings;
$headings-font-weight: 600;
$headings-line-height: 1.2;
$headings-color: null;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-400;

$blockquote-small-color: $gray-400;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// Components
//
// Define dictionary padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $gray-200;

$border-radius: 3px;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;

$rounded-pill: 50rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: $input-padding-y;
$input-btn-padding-x: $input-padding-x;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-sm;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-base;
$input-btn-line-height-lg: $line-height-base;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x * 2;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $font-size-base * 0.875;
$btn-line-height-sm: 1.2rem;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg * 2;
$btn-font-size-lg: $input-btn-font-size;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: 0;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $gray-400;

$btn-block-spacing-y: 0.5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

// Forms

$label-margin-bottom: 0.5rem;

$input-padding-y: 0.5rem;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $white;
$input-disabled-bg: $gray-100;

$input-color: $gray-base;
$input-border-color: $border-color;
$input-border-width: $input-btn-border-width;
$input-border: $input-border-width solid $input-border-color;
$input-box-shadow: null;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;
$input-focus-border-color: lighten($component-active-bg, 25%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-400;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
$input-height-inner-half: calc(#{$input-line-height * 0.5em} + #{$input-padding-y});
$input-height-inner-quarter: calc(#{$input-line-height * 0.25em} + #{$input-padding-y / 2});

$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-height-sm: calc(#{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border});
$input-height-lg: calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-text-margin-top: 0.25rem;

$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.3rem;
$form-check-input-margin-x: 0.25rem;

$form-check-inline-margin-x: 0.75rem;
$form-check-inline-input-margin-x: 0.3125rem;

$form-grid-gutter-width: 10px;
$form-group-padding-y: 1rem;

$input-group-addon-color: $primary;
$input-group-addon-bg: $white;
$input-group-addon-border-color: $input-border-color;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.55rem 1rem;
$table-cell-padding-sm: 0.3rem;

$table-border-radius: $border-radius;
$table-color: $body-color;
$table-bg: null;
$table-accent-bg: rgba($black, 0.05);
$table-hover-color: $table-color;
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $border-color;
$table-head-border-color: $white;
$table-border: ($table-border-width) solid $table-border-color;
$table-border-head: ($table-border-width) solid $table-head-border-color;

$table-head-bg: $white;
$table-head-color: $gray-base;
$table-head-font-size: $font-size-sm;

$table-dark-color: $white;
$table-dark-bg: $gray-400;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);
$table-dark-color: $white;

$table-striped-order: even;

$table-caption-color: $text-muted;

$table-bg-level: -9;
$table-border-level: -6;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.25rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// Custom variables
$label-font-size: $font-size-sm;
$label-font-weight: $font-weight-normal;

// Cards

$card-box-shadow: 0 1px 5px 0 rgba(85, 85, 85, 0.2);

$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, 0.125);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $white;
$card-cap-color: null;
$card-color: null;
$card-bg: $white;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: $grid-gutter-width / 2;
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// Popovers

$popover-font-size: $font-size-sm;
$popover-bg: $input-bg;
$popover-max-width: 276px;
$popover-border-width: 1px;
$popover-border-color: $input-border-color;
$popover-border-radius: $border-radius-lg;
$popover-box-shadow: 0 1px 3px 0 rgba(85, 85, 85, 0.5);

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: 0.5rem;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 2rem;
$popover-arrow-height: 1rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: $gray-400;

$popover-header-padding-y-lg: 0.75rem;
$popover-header-padding-x-lg: 0.75rem;
$popover-body-padding-y-lg: $popover-header-padding-y-lg;
$popover-body-padding-x-lg: $popover-header-padding-x-lg;
$popover-max-width-lg: 700px;

// Pagination

$pagination-base-margin: 1rem 0 0;
$pagination-margin-x: 0.15rem;
$pagination-margin-y: 0;
$pagination-base-padding: 0.15rem 0;
$pagination-item-width: 2.5rem;
$pagination-item-height: $pagination-item-width;
$pagination-item-line-height: #{$btn-line-height}rem;
$pagination-item-bg: $white;
$pagination-item-color: $primary;
$pagination-item-active-bg: $primary;
$pagination-item-active-color: $white;

// Carousel
$carousel-box-shadow: $card-box-shadow;
$carousel-border-radius: $card-border-radius;
$carousel-control-color: $gray-base;
$carousel-control-width: 7.5%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-padding-y: $card-spacer-y;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $primary;
$carousel-indicator-transition: opacity 0.6s ease;

$carousel-caption-width: 85%;
$carousel-caption-color: $white;
$carousel-caption-padding-x: 0;
$carousel-caption-padding-y: $card-spacer-y * 2;
$carousel-caption-font-size: $font-size-sm;
$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");

$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
