.p-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: $ui-checkbox-width;
  height: $ui-checkbox-height;

  .p-checkbox-box {
    border: $ui-checkbox-border;
    background-color: $ui-checkbox-bg;
    width: $ui-checkbox-width;
    height: $ui-checkbox-width;
    text-align: center;
    border-radius: $ui-checkbox-border-radius;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;

    .p-checkbox-icon {
      display: block;
      overflow: hidden;
      position: relative;
      font-size: $ui-checkbox-font-size;
    }
  }

  .p-highlight {
    border-color: $ui-checkbox-active-border-color;
    background-color: $ui-checkbox-active-bg;
    color: $ui-checkbox-active-color;
  }
}

.p-checkbox-label {
  line-height: 1.5;
  vertical-align: middle;
  margin: 0 0 0 0.5em;
}
