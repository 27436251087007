@import 'bootstrap/functions';
@import 'bootstrap/variables';

// stylelint-disable
$enable-sidebar-nav-rounded: false;
$layout-transition-speed: 0.25s;

// Wrapper
$wrapper-content-padding-y: 2rem;
$wrapper-content-padding-x: 2rem;
$wrapper-content-aside-padding-x: 3rem;

// Navbar
$navbar-height: 55px;
$navbar-bg: #fff;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);
$navbar-brand-width: 155px;
$navbar-brand-bg: transparent;
$navbar-brand-border: 0;

// Sidebar
$sidebar-width: 265px;
$sidebar-padding: 0;
$sidebar-minimized-width: 40px;
$sidebar-minimized-height: $sidebar-minimized-width;
$sidebar-compact-width: 150px;
$sidebar-compact-height: $sidebar-compact-width;
$sidebar-color: #fff;
$sidebar-bg: transparent;
$sidebar-bg-start: $primary;
$sidebar-bg-end: $accent;
$sidebar-borders: none;
$mobile-sidebar-width: 220px;

// Sidebar Header

$sidebar-header-height: auto;
$sidebar-header-bg: rgba(0, 0, 0, 0.2);
$sidebar-header-padding-y: 0.75rem;
$sidebar-header-padding-x: 1rem;

// Sidebar Form

$sidebar-form-border: 0;
$sidebar-form-bg: darken($sidebar-bg, 10%);
$sidebar-form-color: #fff;
$sidebar-form-placeholder-color: rgba(255, 255, 255, 0.7);

// Sidebar Navigation

$sidebar-nav-color: #fff;
$sidebar-nav-title-padding-y: 0.75rem;
$sidebar-nav-title-padding-x: 1rem;
$sidebar-nav-title-color: $gray-200;
$sidebar-nav-link-font-size: 1.1rem;
$sidebar-nav-link-height: 40px;
$sidebar-nav-link-padding-y: 0;
$sidebar-nav-link-padding-x: 0;
$sidebar-nav-link-color: #fff;
$sidebar-nav-link-bg: transparent;
$sidebar-nav-link-icon-color: $white;
$sidebar-nav-link-borders: 0;

$sidebar-nav-link-hover-color: #fff;
$sidebar-nav-link-hover-bg: rgba(255, 255, 255, 0.3);
$sidebar-nav-link-hover-icon-color: #fff;
$sidebar-nav-link-hover-borders: 0;

$sidebar-nav-link-active-color: $primary;
$sidebar-nav-link-active-bg: $gray-100;
$sidebar-nav-link-active-icon-color: $primary;
$sidebar-nav-link-active-borders: 0;

$sidebar-nav-link-disabled-color: darken(#fff, 30%);
$sidebar-nav-link-disabled-bg: $sidebar-bg;
$sidebar-nav-link-disabled-icon-color: $sidebar-nav-link-icon-color;
$sidebar-nav-link-disabled-borders: 0;

$sidebar-nav-icon-size: 1.5rem;

$sidebar-nav-dropdown-color: #fff;
$sidebar-nav-dropdown-bg: rgba(0, 0, 0, 0.2);
$sidebar-nav-dropdown-borders: 0;
$sidebar-nav-dropdown-indicator-color: $gray-400;
$sidebar-nav-dropdown-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
);
$sidebar-nav-dropdown-indicator-hover-color: $sidebar-nav-link-hover-color;
$sidebar-nav-dropdown-indicator-hover: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
);

// Sidebar Footer

$sidebar-footer-height: auto;
$sidebar-footer-bg: transparent;
$sidebar-footer-padding-y: 1.5rem;
$sidebar-footer-padding-x: 0rem;
$sidebar-footer-borders: 0;

// Sidebar Minimizer

$sidebar-minimizer-height: 50px;
$sidebar-minimizer-bg: rgba(0, 0, 0, 0.2);
$sidebar-minimizer-borders: 0;
$sidebar-minimizer-indicator-color: $gray-400;
$sidebar-minimizer-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
);
$sidebar-minimizer-hover-bg: rgba(0, 0, 0, 0.3);
$sidebar-minimizer-hover-indicator-color: $sidebar-nav-link-hover-color;
$sidebar-minimizer-hover-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
);

// Aside

$aside-menu-width: 20rem;
$aside-menu-minimized-width: 10rem;
$aside-menu-height: 100vh;
$aside-menu-color: $gray-400;
$aside-box-shadow: 2px 2px 4px rgba(126, 142, 177, 0.12);
$aside-menu-bg: #fff;
$aside-menu-borders: (
  'right': 1px solid $gray-300,
);

$aside-padding-y: $spacer;
$aside-padding-x: $spacer/2;

// Footer

$footer-height: 50px;
$footer-bg: $gray-100;
$footer-color: $body-color;
$footer-borders: (
  top: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);

// Cursor
$cursor-disabled: default;

// Images

$image-size-base: 80px;
$image-size-xs: 32px;
$image-size-sm: 64px;
$image-size: $image-size-base;
$image-size-lg: 128px;

$images-sizes: ();
$images-sizes: map-merge(
  (
    'xs': $image-size-xs,
    'sm': $image-size-sm,
    'md': $image-size,
    'lg': $image-size-lg,
  ),
  $images-sizes
);

// Toolbar
$toolbar-height: 62px;
$toolbar-padding: 1rem 0;

// Content Wrapper
$content-list-group-maxHeight: calc(100% - #{$toolbar-height});
$content-list-group-height: 100vh;
$content-list-group-place-maxHeight: 100%;
$content-list-group-place-height: 80vh;

$background-image-gradient: linear-gradient(to right, $primary, $accent);
