p-dynamicdialog {
  .p-dialog {
    width: 50%;
  }

  .p-dialog-mask {
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: auto;
  }

  .p-dialog-title {
    margin: 1rem auto 0 1rem;
  }

  .p-dialog-header-icons {
    margin: 5px;

    align-self: flex-start;

    .p-dialog-header-icon {
      padding: 5px;
      color: $gray-400;
      border-radius: 50%;
      border-color: transparent;
      background-color: transparent;

      &:hover {
        color: $gray-600;
        background-color: $gray-200;
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
  }

  .popup-details {
    font-weight: bold;
    color: rgb(189, 0, 0);
  }

  p-dynamicdialog .p-dialog-header {
    text-align: left !important;
  }

  textarea {
    min-height: 10rem;
  }

  textarea,
  input {
    margin-top: 1rem;
  }
}
