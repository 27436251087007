@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&subset=latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap");
@import '~primeicons/primeicons.css';
@import '~primeflex/primeflex.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
:root {
  --blue: #4276f2;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #263551;
  --red: #f52a29;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #23ae5d;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #bfbfbf;
  --gray-dark: #b3b3b3;
  --violet: #6f377a;
  --primary: #263551;
  --secondary: gray;
  --success: #23ae5d;
  --info: #4276f2;
  --warning: #ffc107;
  --danger: #f52a29;
  --light: #f0f1f2;
  --dark: #333333;
  --accent: #263551;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0d0d0d;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.55rem 1rem;
  padding-bottom: 0.55rem 1rem;
  color: #b3b3b3;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.row-cols > .col {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.row-cols > .col .card {
  height: 100%;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #f0f1f2;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #333333;
}
.form-control:focus {
  color: #333333;
  background-color: #ffffff;
  border-color: #4f6ea8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 53, 81, 0.25);
}
.form-control::placeholder {
  color: #b3b3b3;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #fafbfc;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #333333;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #333333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #b3b3b3;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #23ae5d;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(35, 174, 93, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #23ae5d;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2323ae5d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #23ae5d;
  box-shadow: 0 0 0 0.2rem rgba(35, 174, 93, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #23ae5d;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23737373' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2323ae5d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #23ae5d;
  box-shadow: 0 0 0 0.2rem rgba(35, 174, 93, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #23ae5d;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #23ae5d;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #23ae5d;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2fd574;
  background: #2fd574 linear-gradient(180deg, #4edb89, #2fd574) repeat-x;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(35, 174, 93, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #23ae5d;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #23ae5d;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #23ae5d;
  box-shadow: 0 0 0 0.2rem rgba(35, 174, 93, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f52a29;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(245, 42, 41, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f52a29;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f52a29' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f52a29' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f52a29;
  box-shadow: 0 0 0 0.2rem rgba(245, 42, 41, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f52a29;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23737373' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f52a29' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f52a29' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #f52a29;
  box-shadow: 0 0 0 0.2rem rgba(245, 42, 41, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f52a29;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f52a29;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #f52a29;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f75a5a;
  background: #f75a5a linear-gradient(180deg, #f87373, #f75a5a) repeat-x;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(245, 42, 41, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f52a29;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f52a29;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f52a29;
  box-shadow: 0 0 0 0.2rem rgba(245, 42, 41, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.form-control-label {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 0 0 10px;
  transition: all 350ms;
  opacity: 0.5;
  pointer-events: none;
  font-size: 0.875rem;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #999999;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #bfbfbf;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f0f1f2 #f0f1f2 #bfbfbf;
}
.nav-tabs .nav-link.disabled {
  color: #999999;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: gray;
  background-color: #ffffff;
  border-color: #bfbfbf #bfbfbf #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #263551;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #263551 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #161e2e !important;
}

.bg-secondary {
  background-color: gray !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #666666 !important;
}

.bg-success {
  background-color: #23ae5d !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1a8446 !important;
}

.bg-info {
  background-color: #4276f2 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1253ef !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #f52a29 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e10c0a !important;
}

.bg-light {
  background-color: #f0f1f2 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d5d8da !important;
}

.bg-dark {
  background-color: #333333 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important;
}

.bg-accent {
  background-color: #263551 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #161e2e !important;
}

.bg-gradient-primary {
  background: #263551 linear-gradient(180deg, #47536b, #263551) repeat-x !important;
}

.bg-gradient-secondary {
  background: gray linear-gradient(180deg, #939393, gray) repeat-x !important;
}

.bg-gradient-success {
  background: #23ae5d linear-gradient(180deg, #44ba75, #23ae5d) repeat-x !important;
}

.bg-gradient-info {
  background: #4276f2 linear-gradient(180deg, #5e8bf4, #4276f2) repeat-x !important;
}

.bg-gradient-warning {
  background: #ffc107 linear-gradient(180deg, #ffca2c, #ffc107) repeat-x !important;
}

.bg-gradient-danger {
  background: #f52a29 linear-gradient(180deg, #f74a49, #f52a29) repeat-x !important;
}

.bg-gradient-light {
  background: #f0f1f2 linear-gradient(180deg, #f2f3f4, #f0f1f2) repeat-x !important;
}

.bg-gradient-dark {
  background: #333333 linear-gradient(180deg, #525252, #333333) repeat-x !important;
}

.bg-gradient-accent {
  background: #263551 linear-gradient(180deg, #47536b, #263551) repeat-x !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #f0f1f2 !important;
}

.border-top {
  border-top: 1px solid #f0f1f2 !important;
}

.border-right {
  border-right: 1px solid #f0f1f2 !important;
}

.border-bottom {
  border-bottom: 1px solid #f0f1f2 !important;
}

.border-left {
  border-left: 1px solid #f0f1f2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #263551 !important;
}

.border-secondary {
  border-color: gray !important;
}

.border-success {
  border-color: #23ae5d !important;
}

.border-info {
  border-color: #4276f2 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #f52a29 !important;
}

.border-light {
  border-color: #f0f1f2 !important;
}

.border-dark {
  border-color: #333333 !important;
}

.border-accent {
  border-color: #263551 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 3px !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-lg {
  border-radius: 3px !important;
}

.rounded-circle, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td span, .p-paginator .p-paginator-element.p-paginator-page {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #263551 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0e131d !important;
}

.text-secondary {
  color: gray !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #595959 !important;
}

.text-success {
  color: #23ae5d !important;
}

a.text-success:hover, a.text-success:focus {
  color: #166e3b !important;
}

.text-info {
  color: #4276f2 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f4ad9 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #f52a29 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #c80a09 !important;
}

.text-light {
  color: #f0f1f2 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c7cbce !important;
}

.text-dark {
  color: #333333 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0d !important;
}

.text-accent {
  color: #263551 !important;
}

a.text-accent:hover, a.text-accent:focus {
  color: #0e131d !important;
}

.text-body {
  color: #333333 !important;
}

.text-muted {
  color: #b3b3b3 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-lg {
  font-size: 1.5rem;
}

.ui-clickable, .p-paginator .p-paginator-element {
  cursor: pointer;
}
.ui-clickable-disabled {
  cursor: default;
}

.p-hoverable:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.p-widget-overlay {
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-align-text-end {
  text-align: end;
}

.p-ink {
  display: none !important;
}

.p-disabled {
  opacity: 0.5;
  cursor: default !important;
}

.p-autocomplete {
  display: inline-flex;
  position: relative;
}

.p-autocomplete-loader {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-autocomplete-dd .p-autocomplete-input {
  flex: 1 1 auto;
  width: 1%;
}

.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0px;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
  top: 0;
  left: 0;
}

.p-autocomplete-panel {
  position: absolute;
  overflow: auto;
}

.p-autocomplete-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-autocomplete-item {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-autocomplete-multiple-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: text;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.p-autocomplete-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-autocomplete-token-icon {
  cursor: pointer;
}

.p-autocomplete-input-token {
  flex: 1 1 auto;
  display: inline-flex;
}

.p-autocomplete-input-token input {
  border: 0 none;
  outline: 0 none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
}

.p-fluid .p-autocomplete {
  display: flex;
}

.p-fluid .p-autocomplete-dd .p-autocomplete-input {
  width: 1%;
}

p-autoComplete.is-invalid .p-autocomplete {
  border: 1px solid #f52a29;
}
p-autoComplete .p-autocomplete {
  width: 100%;
  zoom: 1;
  cursor: pointer;
  box-shadow: none;
  position: relative;
  display: inline-block;
}
p-autoComplete .p-autocomplete:before {
  font-family: "primeicons";
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  height: 100%;
}
p-autoComplete .p-autocomplete.add-button:before {
  color: #23ae5d;
  content: "";
}
p-autoComplete .p-autocomplete .p-autocomplete-dropdown {
  height: 100%;
  width: 2rem;
  margin-right: 0;
  vertical-align: top;
}
p-autoComplete .p-autocomplete .p-autocomplete-panel {
  position: absolute;
  padding: 0;
  border: 1px solid #f0f1f2;
  background-color: #ffffff;
  font-size: 0.875rem;
  width: 100%;
}
p-autoComplete .p-autocomplete .p-autocomplete-panel .p-autocomplete-list {
  max-height: 200px;
  overflow: auto;
}
p-autoComplete .p-autocomplete .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
}
p-autoComplete .p-autocomplete .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item:hover {
  background: #f0f1f2;
  color: #333333;
}
p-autoComplete .p-autocomplete .pi-spinner:before,
p-autoComplete .p-autocomplete .pi-spin:before,
p-autoComplete .p-autocomplete .pi-spinner:before {
  content: "";
}
p-autoComplete .p-autocomplete .p-autocomplete-loader {
  display: none;
}
p-autoComplete .p-autocomplete.p-autocomplete-sm:before {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}
p-autoComplete .p-autocomplete-multiple {
  height: auto;
}
p-autoComplete .p-autocomplete-multiple:focus-within {
  box-shadow: 0 0 0 0.2rem rgba(38, 53, 81, 0.25);
}
p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container {
  list-style-type: none;
  border: none;
  padding: 0;
  margin: 0;
  height: auto;
}
p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token {
  margin-bottom: 0.25rem !important;
}
p-autoComplete .p-autocomplete-multiple input {
  border-style: none;
}
p-autoComplete .p-autocomplete-multiple input:focus {
  outline: none;
}
p-autoComplete .p-autocomplete-item {
  padding: 0.5rem;
}
p-autoComplete .p-autocomplete-item:hover {
  background-color: #f0f1f2 !important;
}

.p-contextmenu {
  position: absolute;
  display: none;
}

.p-contextmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-contextmenu .p-submenu-list {
  position: absolute;
  min-width: 100%;
  z-index: 1;
  display: none;
}

.p-contextmenu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.p-contextmenu .p-menuitem-text {
  line-height: 1;
}

.p-contextmenu .p-menuitem {
  position: relative;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  margin-left: auto;
}

.p-contextmenu .p-menuitem-active > p-contextmenusub > .p-submenu-list.p-submenu-list-active {
  display: block !important;
}

p-contextMenu + app-icon:hover {
  color: #bfbfbf;
}
p-contextMenu .p-contextmenu {
  padding: 0;
  background-color: #ffffff;
  position: absolute;
  top: unset !important;
  left: unset !important;
  display: none;
  width: 10rem;
}
p-contextMenu .p-contextmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
p-contextMenu .p-contextmenu ul li.p-menuitem:focus {
  outline: none;
}
p-contextMenu .p-contextmenu ul li.p-menuitem a.p-menuitem-link {
  display: block;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
}
p-contextMenu .p-contextmenu ul li.p-menuitem a.p-menuitem-link:hover {
  background-image: linear-gradient(to right, #263551, #263551);
  color: white;
}
p-contextMenu .p-contextmenu ul li.p-menuitem a.p-menuitem-link:focus {
  outline: none;
}
p-contextMenu .p-contextmenu ul li.p-menuitem a.p-menuitem-link .p-menuitem-icon {
  padding-right: 0.75rem;
}

.p-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.p-dialog-mask.p-component-overlay {
  pointer-events: auto;
}

.p-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  max-height: 90%;
  transform: scale(1);
  position: relative;
}

.p-dialog-content {
  overflow-y: auto;
  flex-grow: 1;
}

.p-dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.p-dialog-footer {
  flex-shrink: 0;
}

.p-dialog .p-dialog-header-icons {
  display: flex;
  align-items: center;
}

.p-dialog .p-dialog-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

/* Fluid */
.p-fluid .p-dialog-footer .p-button, .p-fluid .p-dialog-footer .ql-html-buttonCancel,
.p-fluid .p-dialog-footer .html-buttonCancel, .p-fluid .p-dialog-footer .ql-html-buttonOk,
.p-fluid .p-dialog-footer .html-buttonOk {
  width: auto;
}

/* Top, Bottom, Left, Right, Top* and Bottom* */
.p-dialog-top .p-dialog,
.p-dialog-bottom .p-dialog,
.p-dialog-left .p-dialog,
.p-dialog-right .p-dialog,
.p-dialog-top-left .p-dialog,
.p-dialog-top-right .p-dialog,
.p-dialog-bottom-left .p-dialog,
.p-dialog-bottom-right .p-dialog {
  margin: 0.75rem;
  transform: translate3d(0px, 0px, 0px);
}

/* Maximize */
.p-dialog-maximized {
  -webkit-transition: none;
  transition: none;
  transform: none;
  width: 100vw !important;
  height: 100vh !important;
  top: 0px !important;
  left: 0px !important;
  max-height: 100%;
  height: 100%;
}

.p-dialog-maximized .p-dialog-content {
  flex-grow: 1;
}

/* Position */
.p-dialog-left {
  justify-content: flex-start;
}

.p-dialog-right {
  justify-content: flex-end;
}

.p-dialog-top {
  align-items: flex-start;
}

.p-dialog-top-left {
  justify-content: flex-start;
  align-items: flex-start;
}

.p-dialog-top-right {
  justify-content: flex-end;
  align-items: flex-start;
}

.p-dialog-bottom {
  align-items: flex-end;
}

.p-dialog-bottom-left {
  justify-content: flex-start;
  align-items: flex-end;
}

.p-dialog-bottom-right {
  justify-content: flex-end;
  align-items: flex-end;
}

/* Resizable */
.p-dialog .p-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.p-confirm-dialog .p-dialog-content {
  display: flex;
  align-items: center;
}

.p-dialog {
  border-radius: 3px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #c8c8c8;
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid #c8c8c8;
  background: #f4f4f4;
  color: #333333;
  padding: 1rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #a6a6a6;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #007ad9;
  border-color: transparent;
  background: transparent;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #8dcdff;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #333333;
  padding: 1rem;
}

.p-dialog .p-dialog-footer {
  border-top: 1px solid #c8c8c8;
  background: #ffffff;
  color: #333333;
  padding: 0.571rem 1rem;
  text-align: right;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
  position: absolute;
  background-color: #fafbfc;
  color: #333333;
  padding: 0;
  border: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.p-overlaypanel:before {
  border-color: #fafbfc;
  border-bottom-color: #c8c8c8;
  border-width: 10px;
}
.p-overlaypanel:before, .p-overlaypanel:after {
  top: 100%;
  left: 10rem;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.p-overlaypanel:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fafbfc;
  border-width: 8px;
  margin-left: -8px;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
  position: absolute;
  top: 0;
  right: 0;
}

.p-inline-message ul {
  list-style: none;
  padding: 0;
}
.p-inline-message ul li {
  color: #333333;
  border-radius: 3px;
  padding: 0.25rem 1.25rem;
  font-size: 0.875rem;
}
.p-inline-message.p-inline-message-error li {
  background: #eed4d6;
}
.p-inline-message.p-inline-message-warn li {
  background: #6d5200;
}
.p-inline-message.p-inline-message-success li {
  background: #e0ecdb;
}
.p-inline-message .p-inline-message-icon {
  display: none;
}

.messages-container {
  position: fixed;
  z-index: 9999;
  width: 50%;
}

p-messages {
  margin-left: 40px;
}

.p-messages {
  margin: 0.5rem 0;
}
.p-messages .p-message.p-message-error {
  background: #f52a29;
  color: #ffffff;
}
.p-messages .p-message.p-message-error .p-message-wrapper {
  padding: 1rem;
  display: flex;
  align-items: center;
}
.p-messages .p-message.p-message-error .p-message-icon {
  display: none;
}
.p-messages .p-message.p-message-error .p-message-close:hover {
  background-image: unset !important;
}
.p-messages .p-message.p-message-success {
  background: #23ae5d;
  color: #ffffff;
}
.p-messages .p-message.p-message-success .p-message-wrapper {
  padding: 1rem;
  display: flex;
  align-items: center;
}
.p-messages .p-message.p-message-success .p-message-icon {
  display: none;
}
.p-messages .p-message.p-message-success .p-message-close:hover {
  background-image: unset !important;
}

p-toast .p-toast {
  position: fixed;
  width: 20rem;
}
p-toast .p-toast.p-toast-top-right {
  top: 1rem;
  right: 1rem;
}
p-toast .p-toast p-toastItem .p-toast-message {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin: 0.25rem 0 0.25rem 0;
  overflow: hidden;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}
p-toast .p-toast p-toastItem .p-toast-message-content {
  display: flex;
  align-items: center;
}
p-toast .p-toast p-toastItem .p-toast-message.p-toast-message-success {
  background: #e0ecdb;
}
p-toast .p-toast p-toastItem .p-toast-message.p-toast-message-warn {
  background: #6d5200;
}
p-toast .p-toast p-toastItem .p-toast-message.p-toast-message-error {
  background: #eed4d6;
}
p-toast .p-toast p-toastItem .p-message-icon,
p-toast .p-toast p-toastItem .p-toast-message-icon,
p-toast .p-toast p-toastItem .p-toast-icon-close {
  display: none;
}

.p-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-field label {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 0 0 10px;
  transition: all 350ms;
  opacity: 0.5;
  pointer-events: none;
  font-size: 0.875rem;
}
.p-field .p-inputtext:-webkit-autofill + label, .p-field .p-inputtext:focus + label, .p-field .p-inputtext:valid + label {
  top: -26px;
  opacity: 1;
  background: transparent;
  font-size: 0.75rem;
}

.p-datatable {
  position: relative;
}

.p-datatable table {
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
  user-select: none;
}

.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
  vertical-align: middle;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
  table-layout: auto;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper > table,
.p-datatable-auto-layout > .p-datatable-wrapper > table {
  table-layout: auto;
}

.p-datatable-hoverable-rows .p-selectable-row {
  cursor: pointer;
}

/* Scrollable */
.p-datatable-scrollable .p-datatable-wrapper {
  position: relative;
  overflow: auto;
}

.p-datatable-scrollable .p-datatable-thead,
.p-datatable-scrollable .p-datatable-tbody,
.p-datatable-scrollable .p-datatable-tfoot {
  display: block;
}

.p-datatable-scrollable .p-datatable-thead > tr,
.p-datatable-scrollable .p-datatable-tbody > tr,
.p-datatable-scrollable .p-datatable-tfoot > tr {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.p-datatable-scrollable .p-datatable-thead > tr > th,
.p-datatable-scrollable .p-datatable-tbody > tr > td,
.p-datatable-scrollable .p-datatable-tfoot > tr > td {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}

.p-datatable-scrollable .p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.p-datatable-scrollable .p-datatable-frozen-tbody {
  position: sticky;
  z-index: 1;
}

.p-datatable-scrollable .p-datatable-tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.p-datatable-scrollable .p-frozen-column {
  position: sticky;
  background: inherit;
}

.p-datatable-scrollable th.p-frozen-column {
  z-index: 1;
}

.p-datatable-scrollable-both .p-datatable-thead > tr > th,
.p-datatable-scrollable-both .p-datatable-tbody > tr > td,
.p-datatable-scrollable-both .p-datatable-tfoot > tr > td,
.p-datatable-scrollable-horizontal .p-datatable-thead > tr > th .p-datatable-scrollable-horizontal .p-datatable-tbody > tr > td,
.p-datatable-scrollable-horizontal .p-datatable-tfoot > tr > td {
  flex: 0 0 auto;
}

.p-datatable-flex-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-datatable-flex-scrollable .p-datatable-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.p-datatable-scrollable .p-rowgroup-header {
  position: sticky;
  z-index: 1;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot {
  display: table;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead > tr,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot > tr {
  display: table-row;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead > tr > th,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot > tr > td {
  display: table-cell;
}

/* Flex Scrollable */
.p-datatable-flex-scrollable {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.p-datatable-flex-scrollable .p-datatable-virtual-scrollable-body {
  flex: 1;
}

/* Resizable */
.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: hidden;
  white-space: nowrap;
}

.p-datatable-resizable .p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5rem;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

.p-datatable .p-row-editor-init,
.p-datatable .p-row-editor-save,
.p-datatable .p-row-editor-cancel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

/* Expand */
.p-datatable .p-row-toggler {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

/* Reorder */
.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
  position: absolute;
  display: none;
}

.p-datatable-reorderablerow-handle {
  cursor: move;
}

[pReorderableColumn] {
  cursor: move;
}

/* Loader */
.p-datatable .p-datatable-loading-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

/* Filter */
.p-column-filter-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.p-column-filter-menu {
  display: inline-flex;
}

.p-column-filter-row p-columnfilterformelement {
  flex: 1 1 auto;
  width: 1%;
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.p-column-filter-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.p-column-filter-row-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-column-filter-row-item {
  cursor: pointer;
}

.p-column-filter-add-button,
.p-column-filter-remove-button {
  justify-content: center;
}

.p-column-filter-add-button .p-button-label,
.p-column-filter-remove-button .p-button-label {
  flex-grow: 0;
}

.p-column-filter-buttonbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-column-filter-buttonbar .p-button, .p-column-filter-buttonbar .ql-html-buttonCancel,
.p-column-filter-buttonbar .html-buttonCancel, .p-column-filter-buttonbar .ql-html-buttonOk,
.p-column-filter-buttonbar .html-buttonOk {
  width: auto;
}

/* Responsive */
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  display: none;
}

/* Virtual Scroll*/
cdk-virtual-scroll-viewport {
  outline: 0 none;
}

.p-datatable table {
  width: 100%;
  color: #333333;
  border: 1px solid #f0f1f2;
}
.p-datatable .p-datatable-thead {
  background-color: #ffffff;
}
.p-datatable .p-datatable-thead th {
  font-weight: 600;
}
.p-datatable .p-datatable-thead th .p-sortable-column-icon {
  margin-left: 0.2rem;
  font-size: 0.875rem;
}
.p-datatable th,
.p-datatable td {
  padding: 0.55rem 1rem;
  vertical-align: middle;
  border-bottom: none;
  word-break: break-word;
  position: relative;
}
.p-datatable .p-datatable-tbody tr {
  background-color: #f0f1f2;
  cursor: pointer;
}
.p-datatable .p-datatable-tbody tr.click-disabled {
  cursor: default;
}
.p-datatable .p-datatable-tbody tr:hover {
  background-color: #d9d9d9;
}
.p-datatable .p-datatable-tbody tr:nth-child(even) {
  background-color: #ffffff;
}
.p-datatable .p-datatable-tbody tr:nth-child(even):hover {
  background-color: #d9d9d9;
}
.p-datatable .p-datatable-footer {
  padding: 0.55rem 1rem;
  vertical-align: middle;
  border-top: 0 none;
  font-weight: 600;
  text-align: right;
}
.p-datatable .ui-table-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: 1;
}
.p-datatable .ui-table-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1rem;
  margin-left: -1rem;
  font-size: 4rem;
  color: #333333;
}
.p-datatable .p-datatable-scrollable-body {
  position: relative;
  overflow: auto;
}
.p-datatable .p-datatable-scrollable-footer,
.p-datatable .p-datatable-scrollable-header {
  overflow: visible !important;
}
.p-datatable .p-paginator-current {
  color: #b3b3b3;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 2rem;
}
.p-datatable .p-paginator-bottom {
  margin: 0 !important;
  padding: 0.5rem 0;
  border-width: 0px 1px 1px 1px;
  border-color: #f0f1f2;
  border-style: solid;
}
.p-datatable .p-paginator .p-paginator-element.p-paginator-prev {
  padding: 0 0.5rem;
}
.p-datatable p-dropdown {
  margin-left: 2rem;
}
.p-datatable .p-paginator-element {
  font-size: 14px;
  color: #b3b3b3;
}
.p-datatable .p-column-filter-container .p-input-group {
  margin: 0;
}
.p-datatable .p-column-filter-container .p-input-group-icon {
  max-width: fit-content;
}
.p-datatable .p-column-filter-container .p-column-filter {
  max-width: max-content;
}
.p-datatable .p-column-filter-container .p-column-filter .p-multiselect {
  min-width: 100%;
}

.p-tooltip {
  position: absolute;
  display: none;
  max-width: 276px;
  font-size: 0.875rem;
  z-index: 3000 !important;
}
.p-tooltip .p-tooltip-text {
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #f0f1f2;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(85, 85, 85, 0.5);
  padding: 0.5rem 0.5rem;
  color: #333333;
}
.p-tooltip .p-tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0;
}
.p-tooltip .p-tooltip-arrow::before, .p-tooltip .p-tooltip-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.p-tooltip.p-tooltip-right > .p-tooltip-arrow, .p-tooltip.p-tooltip-left > .p-tooltip-arrow {
  top: 50%;
  margin-top: -0.25rem;
}
.p-tooltip.p-tooltip-top > .p-tooltip-arrow, .p-tooltip.p-tooltip-bottom > .p-tooltip-arrow {
  left: 50%;
  margin-left: -0.25rem;
}
.p-tooltip.p-tooltip-right > .p-tooltip-text {
  margin-left: 0.5rem;
}
.p-tooltip.p-tooltip-right > .p-tooltip-arrow {
  left: 0;
}
.p-tooltip.p-tooltip-right > .p-tooltip-arrow::before {
  left: 0;
  border-width: 0.25rem 0.5rem 0.25rem 0;
  border-right-color: #b3b3b3;
}
.p-tooltip.p-tooltip-right > .p-tooltip-arrow::after {
  left: 1px;
  border-width: 0.25rem 0.5rem 0.25rem 0;
  border-right-color: #ffffff;
}
.p-tooltip.p-tooltip-left > .p-tooltip-text {
  margin-right: 0.5rem;
}
.p-tooltip.p-tooltip-left > .p-tooltip-arrow {
  right: 0;
}
.p-tooltip.p-tooltip-left > .p-tooltip-arrow::before {
  right: 0;
  border-width: 0.25rem 0 0.25rem 0.5rem;
  border-left-color: #b3b3b3;
}
.p-tooltip.p-tooltip-left > .p-tooltip-arrow::after {
  right: 1px;
  border-width: 0.25rem 0 0.25rem 0.5rem;
  border-left-color: #ffffff;
}
.p-tooltip.p-tooltip-top > .p-tooltip-text {
  margin-bottom: 0.5rem;
}
.p-tooltip.p-tooltip-top > .p-tooltip-arrow {
  bottom: 0;
}
.p-tooltip.p-tooltip-top > .p-tooltip-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.25rem 0;
  border-top-color: #b3b3b3;
}
.p-tooltip.p-tooltip-top > .p-tooltip-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.25rem 0;
  border-top-color: #ffffff;
}
.p-tooltip.p-tooltip-bottom > .p-tooltip-text {
  margin-top: 0.5rem;
}
.p-tooltip.p-tooltip-bottom > .p-tooltip-arrow {
  top: 0;
}
.p-tooltip.p-tooltip-bottom > .p-tooltip-arrow::before {
  top: 0;
  border-width: 0 0.25rem 0.5rem 0.25rem;
  border-bottom-color: #b3b3b3;
}
.p-tooltip.p-tooltip-bottom > .p-tooltip-arrow::after {
  top: 1px;
  border-width: 0 0.25rem 0.5rem 0.25rem;
  border-bottom-color: #ffffff;
}

.p-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid #f0f1f2;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 3px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  display: block;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
}
.p-checkbox .p-highlight {
  border-color: #f0f1f2;
  background-color: #ffffff;
  color: #23ae5d;
}

.p-checkbox-label {
  line-height: 1.5;
  vertical-align: middle;
  margin: 0 0 0 0.5em;
}

.p-input-group {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
}
.p-input-group .p-input-group-icon {
  position: relative;
}
.p-input-group .p-label {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 0 0 10px;
  transition: all 350ms;
  opacity: 0.5;
  pointer-events: none;
  font-size: 0.875rem;
}
.p-input-group .p-inputtext:not(:last-child) {
  border-width: 1px;
}
.p-input-group .p-inputtext:-webkit-autofill + label, .p-input-group .p-inputtext:focus + label, .p-input-group .p-inputtext:valid + label {
  top: -26px;
  opacity: 1;
  background: transparent;
  font-size: 0.75rem;
}
.p-input-group .p-inline-message.p-inline-message-error {
  color: #f52a29;
}
.p-input-group .p-inline-message.p-inline-message-error .p-inline-message-text {
  font-size: 0.75rem;
}
.p-input-group .p-form-control-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: calc(1.5em + 1rem + 2px);
  height: calc(1.5em + 1rem + 2px);
  line-height: calc(1.5em + 1rem + 2px);
  text-align: center;
  pointer-events: none;
  color: #263551;
  font-size: 0.875rem;
  top: 0;
  background-color: white;
  border: 1px solid #f0f1f2;
  border-left: none;
}
.p-input-group .p-form-control-icon.p-form-control-icon-append {
  right: 0;
}
.p-input-group .p-form-control-icon.p-form-control-icon-prepend {
  left: 0;
}
.p-input-group .p-inputtext:focus + .p-form-control-icon {
  border: 2px solid black;
  border-left: none;
}

.p-inputtext {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #f0f1f2;
  border-radius: 3px;
}
.p-inputtext .p-inputtext-sm {
  padding: 0.25rem 1rem;
  border-radius: 3px;
}
.p-inputtext.is-invalid {
  border: 1px solid #f52a29;
}
.p-inputtext.is-invalid.custom-input + ng-component .p-inline-message-error {
  color: #f52a29;
  position: absolute;
  bottom: 0;
  /*
  I don't know what I'm doing...
   */
}
@media (max-width: 1199.98px) {
  .p-inputtext.is-invalid.custom-input + ng-component .p-inline-message-error {
    left: -90px;
  }
}
@media (max-width: 991.98px) {
  .p-inputtext.is-invalid.custom-input + ng-component .p-inline-message-error {
    left: -150px;
  }
}
.p-inputtext.is-invalid.custom-input + ng-component .p-inline-message-error .p-inline-message-text {
  font-size: 0.6rem;
  white-space: nowrap;
}
.p-inputtext[type=number]::-webkit-inner-spin-button, .p-inputtext[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

p-inputNumber .p-inputnumber {
  display: block;
}
p-inputNumber.is-invalid .p-inputtext {
  border: 1px solid #f52a29;
}
p-inputNumber.is-invalid + .p-inline-message.p-inline-message-error {
  color: #f52a29;
}
p-inputNumber.is-invalid + .p-inline-message.p-inline-message-error .p-inline-message-text {
  font-size: 0.75rem;
}

.p-inputswitch {
  position: relative;
  display: inline-block;
  user-select: none;
}

.p-inputswitch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.p-inputswitch-slider:before {
  position: absolute;
  content: "";
  top: 50%;
}

.p-inputswitch {
  width: 3rem;
  height: 1.5rem;
  position: relative;
  display: inline-block;
}
.p-inputswitch .p-inputswitch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  background: #f0f1f2;
}
.p-inputswitch .p-inputswitch-slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #ffffff;
  left: 0.25rem;
  bottom: 0.25rem;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.p-inputswitch.p-inputswitch-checked.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #23ae5d;
}
.p-inputswitch.p-inputswitch-checked.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(1.5em);
  -ms-transform: translateX(1.5em);
  transform: translateX(1.5em);
  background-color: #ffffff;
}

.p-inputswitch-primary.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #263551;
}
.p-inputswitch-primary.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-secondary.p-inputswitch-checked .p-inputswitch-slider {
  background-color: gray;
}
.p-inputswitch-secondary.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-success.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #23ae5d;
}
.p-inputswitch-success.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-info.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #4276f2;
}
.p-inputswitch-info.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-warning.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #ffc107;
}
.p-inputswitch-warning.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-danger.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #f52a29;
}
.p-inputswitch-danger.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-light.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #f0f1f2;
}
.p-inputswitch-light.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-dark.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #333333;
}
.p-inputswitch-dark.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

.p-inputswitch-accent.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #263551;
}
.p-inputswitch-accent.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff;
}

p-inputMask.is-invalid .p-inputtext {
  border: 1px solid #f52a29;
}

.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.p-dropdown-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-dropdown-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
}

.p-dropdown-label-empty {
  overflow: hidden;
  visibility: hidden;
}

input.p-dropdown-label {
  cursor: default;
}

.p-dropdown .p-dropdown-panel {
  min-width: 100%;
}

.p-dropdown-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-dropdown-filter {
  width: 100%;
}

.p-dropdown-filter-container {
  position: relative;
}

.p-dropdown-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
  width: 1%;
}

p-dropdown.is-invalid .p-dropdown {
  border: 1px solid #f52a29;
}
p-dropdown .p-dropdown {
  position: relative;
  min-width: 100%;
  background: #ffffff;
  border: 1px solid #f0f1f2;
  transition: border-color 0.2s;
  border-radius: 3px;
  font-size: 0.875rem;
  display: flex;
}
p-dropdown .p-dropdown span.p-dropdown-label {
  cursor: pointer;
  display: block;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
}
p-dropdown .p-dropdown .p-dropdown-trigger {
  background-color: #ffffff;
  padding: 0.5rem 0.75rem 0.5rem 0;
  cursor: pointer;
}
p-dropdown .p-dropdown .p-dropdown-clear-icon {
  position: absolute;
  right: 2rem;
  top: 12px;
  cursor: pointer;
}
p-dropdown .p-dropdown .p-dropdown-panel {
  position: absolute;
  background: #ffffff;
  width: 100%;
  border: 1px solid #f0f1f2;
}
p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  overflow: auto;
}
p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items li.p-dropdown-item {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
}
p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items li.p-dropdown-item:hover {
  background: #bfbfbf;
  color: #333333;
}
p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items li.p-dropdown-item.p-highlight {
  background: linear-gradient(to right, #263551, #263551);
  color: #ffffff;
}
p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items li.p-dropdown-item.p-highlight:hover {
  background: linear-gradient(to right, #263551, #263551);
  color: #ffffff;
}

.p-paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.p-paginator-left-content {
  margin-right: auto;
}

.p-paginator-right-content {
  margin-left: auto;
}

.p-paginator-page,
.p-paginator-next,
.p-paginator-last,
.p-paginator-first,
.p-paginator-prev,
.p-paginator-current {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  user-select: none;
  overflow: hidden;
  position: relative;
}

.p-paginator-element:focus {
  z-index: 1;
  position: relative;
}

.p-paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  margin: 1rem 0 0;
  padding: 0.15rem 0;
  padding-left: 0;
  list-style: none;
}
.p-paginator .p-paginator-pages {
  display: flex;
}
.p-paginator .p-paginator-pages:focus {
  outline: none;
}
.p-paginator .p-paginator-element:focus {
  outline: none;
}
.p-paginator .p-paginator-element.p-paginator-prev, .p-paginator .p-paginator-element.p-paginator-next {
  border: unset;
  background-color: #ffffff;
}
.p-paginator .p-paginator-element.p-paginator-prev:hover, .p-paginator .p-paginator-element.p-paginator-next:hover {
  background-color: #ffffff;
  color: unset;
  text-decoration: none;
}
.p-paginator .p-paginator-element.p-paginator-prev:focus, .p-paginator .p-paginator-element.p-paginator-next:focus {
  outline: none;
}
.p-paginator .p-paginator-element.p-paginator-prev {
  padding: 0.5rem 0;
}
.p-paginator .p-paginator-element.p-paginator-next {
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-element.p-paginator-page {
  background-color: #ffffff;
  color: #263551;
  margin: 0 0.15rem;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  text-align: center;
  line-height: 1.5rem;
  padding: 0.5rem 0;
  border: 1px solid;
}
.p-paginator .p-paginator-element.p-paginator-page:hover {
  text-decoration: none;
  color: #263551;
  background-color: #d9d9d9;
}
.p-paginator .p-paginator-element.p-paginator-page.p-highlight {
  background-color: #263551;
  color: #ffffff;
}
.p-paginator .p-paginator-element.p-paginator-page:focus {
  outline: none;
}
.p-paginator .p-paginator-current {
  order: 2;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
  display: none;
}

.p-selectbutton {
  display: inline-flex;
}
.p-selectbutton .p-button, .p-selectbutton .ql-html-buttonCancel,
.p-selectbutton .html-buttonCancel, .p-selectbutton .ql-html-buttonOk,
.p-selectbutton .html-buttonOk {
  cursor: pointer;
  padding: 0.4rem 1rem !important;
}
.p-selectbutton .p-button .p-button-label, .p-selectbutton .ql-html-buttonCancel .p-button-label,
.p-selectbutton .html-buttonCancel .p-button-label, .p-selectbutton .ql-html-buttonOk .p-button-label,
.p-selectbutton .html-buttonOk .p-button-label {
  padding: 0;
}

.p-selectbutton-green .p-selectbutton {
  display: inline-flex;
}
.p-selectbutton-green .p-selectbutton .p-button, .p-selectbutton-green .p-selectbutton .ql-html-buttonCancel,
.p-selectbutton-green .p-selectbutton .html-buttonCancel, .p-selectbutton-green .p-selectbutton .ql-html-buttonOk,
.p-selectbutton-green .p-selectbutton .html-buttonOk {
  background-image: none;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #23ae5d;
  cursor: pointer;
  padding: 0.4rem 1rem !important;
}
.p-selectbutton-green .p-selectbutton .p-button:hover, .p-selectbutton-green .p-selectbutton .ql-html-buttonCancel:hover,
.p-selectbutton-green .p-selectbutton .html-buttonCancel:hover, .p-selectbutton-green .p-selectbutton .ql-html-buttonOk:hover,
.p-selectbutton-green .p-selectbutton .html-buttonOk:hover {
  background-color: #23ae5d;
  color: #ffffff;
  background-image: none;
  border: 1px solid #23ae5d;
}
.p-selectbutton-green .p-selectbutton .p-button .p-button-label, .p-selectbutton-green .p-selectbutton .ql-html-buttonCancel .p-button-label,
.p-selectbutton-green .p-selectbutton .html-buttonCancel .p-button-label, .p-selectbutton-green .p-selectbutton .ql-html-buttonOk .p-button-label,
.p-selectbutton-green .p-selectbutton .html-buttonOk .p-button-label {
  padding: 0;
}

.p-slider {
  background-color: #f0f1f2;
  position: relative;
}
.p-slider-horizontal {
  height: 0.5rem;
}
.p-slider-horizontal .p-slider-handle {
  margin-left: -0.6rem;
}
.p-slider-range {
  position: absolute;
  display: block;
  height: 100%;
  background-color: #23ae5d;
}
.p-slider-handle {
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #ffffff;
  border: 5px solid #23ae5d;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

.p-button, .ql-html-buttonCancel,
.html-buttonCancel, .ql-html-buttonOk,
.html-buttonOk {
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.p-button-label {
  flex: 1 1 auto;
}

.p-button-icon-right {
  order: 1;
}

.p-button:disabled, .ql-html-buttonCancel:disabled,
.html-buttonCancel:disabled, .ql-html-buttonOk:disabled,
.html-buttonOk:disabled {
  cursor: default;
}

.p-button-icon-only {
  justify-content: center;
}

.p-button-icon-only .p-button-label {
  visibility: hidden;
  width: 0;
  flex: 0 0 auto;
}

.p-button-vertical {
  flex-direction: column;
}

.p-button-icon-bottom {
  order: 2;
}

.p-buttonset .p-button, .p-buttonset .ql-html-buttonCancel,
.p-buttonset .html-buttonCancel, .p-buttonset .ql-html-buttonOk,
.p-buttonset .html-buttonOk {
  margin: 0;
}

.p-buttonset .p-button:not(:last-child), .p-buttonset .ql-html-buttonCancel:not(:last-child),
.p-buttonset .html-buttonCancel:not(:last-child), .p-buttonset .ql-html-buttonOk:not(:last-child),
.p-buttonset .html-buttonOk:not(:last-child) {
  border-right: 0 none;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type), .p-buttonset .ql-html-buttonCancel:not(:first-of-type):not(:last-of-type),
.p-buttonset .html-buttonCancel:not(:first-of-type):not(:last-of-type), .p-buttonset .ql-html-buttonOk:not(:first-of-type):not(:last-of-type),
.p-buttonset .html-buttonOk:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.p-buttonset .p-button:first-of-type, .p-buttonset .ql-html-buttonCancel:first-of-type,
.p-buttonset .html-buttonCancel:first-of-type, .p-buttonset .ql-html-buttonOk:first-of-type,
.p-buttonset .html-buttonOk:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-buttonset .p-button:last-of-type, .p-buttonset .ql-html-buttonCancel:last-of-type,
.p-buttonset .html-buttonCancel:last-of-type, .p-buttonset .ql-html-buttonOk:last-of-type,
.p-buttonset .html-buttonOk:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-buttonset .p-button:focus, .p-buttonset .ql-html-buttonCancel:focus,
.p-buttonset .html-buttonCancel:focus, .p-buttonset .ql-html-buttonOk:focus,
.p-buttonset .html-buttonOk:focus {
  position: relative;
  z-index: 1;
}

.p-button-label {
  transition: all 0.2s;
}

.p-button, .ql-html-buttonCancel,
.html-buttonCancel, .ql-html-buttonOk,
.html-buttonOk {
  display: inline-block !important;
  font-weight: 400;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .p-button, .ql-html-buttonCancel,
.html-buttonCancel, .ql-html-buttonOk,
.html-buttonOk {
    transition: none;
  }
}
.p-button:hover, .ql-html-buttonCancel:hover,
.html-buttonCancel:hover, .ql-html-buttonOk:hover,
.html-buttonOk:hover {
  text-decoration: none;
}
.p-button:focus, .ql-html-buttonCancel:focus,
.html-buttonCancel:focus, .ql-html-buttonOk:focus,
.html-buttonOk:focus, .p-button.focus, .focus.ql-html-buttonCancel,
.focus.html-buttonCancel, .focus.ql-html-buttonOk,
.focus.html-buttonOk {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 53, 81, 0.25);
}
.p-button.p-calendar-button, .p-calendar-button.ql-html-buttonCancel,
.p-calendar-button.html-buttonCancel, .p-calendar-button.ql-html-buttonOk,
.p-calendar-button.html-buttonOk {
  box-shadow: none;
  outline: 0;
}
.p-button.disabled, .disabled.ql-html-buttonCancel,
.disabled.html-buttonCancel, .disabled.ql-html-buttonOk,
.disabled.html-buttonOk, .p-button:disabled, .ql-html-buttonCancel:disabled,
.html-buttonCancel:disabled, .ql-html-buttonOk:disabled,
.html-buttonOk:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.p-button:not(:disabled):not(.disabled):not(.ui-calendar-button), .ql-html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button),
.html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button), .ql-html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button),
.html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button) {
  cursor: pointer;
}
.p-button:not(:disabled):not(.disabled):not(.ui-calendar-button):active, .ql-html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button):active,
.html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button):active, .ql-html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button):active,
.html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button):active, .p-button:not(:disabled):not(.disabled):not(.ui-calendar-button).active, .ql-html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button).active,
.html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button).active, .ql-html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button).active,
.html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.p-button:not(:disabled):not(.disabled):not(.ui-calendar-button):active:focus, .ql-html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button):active:focus,
.html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button):active:focus, .ql-html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button):active:focus,
.html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button):active:focus, .p-button:not(:disabled):not(.disabled):not(.ui-calendar-button).active:focus, .ql-html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button).active:focus,
.html-buttonCancel:not(:disabled):not(.disabled):not(.ui-calendar-button).active:focus, .ql-html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button).active:focus,
.html-buttonOk:not(:disabled):not(.disabled):not(.ui-calendar-button).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 53, 81, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.p-button-icon {
  padding: 0.5rem;
}
.p-button-icon.p-button-sm {
  padding: 0.25rem;
}
.p-button-icon.p-button-lg {
  padding: 0.5rem;
}
.p-button span, .ql-html-buttonCancel span,
.html-buttonCancel span, .ql-html-buttonOk span,
.html-buttonOk span, .p-button-sm, .p-button-lg {
  padding: 0 0.5rem;
}

.p-button-primary, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight, .p-selectbutton .p-button.p-highlight, .p-selectbutton .p-highlight.ql-html-buttonCancel,
.p-selectbutton .p-highlight.html-buttonCancel, .p-selectbutton .p-highlight.ql-html-buttonOk,
.p-selectbutton .p-highlight.html-buttonOk {
  color: #ffffff;
  background-color: #263551;
  background-image: linear-gradient(to right, #263551 0%, #263551 100%);
  background-repeat: repeat-x;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.p-button-primary:hover, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight:hover, .p-selectbutton .p-button.p-highlight:hover, .p-selectbutton .p-highlight.ql-html-buttonCancel:hover,
.p-selectbutton .p-highlight.html-buttonCancel:hover, .p-selectbutton .p-highlight.ql-html-buttonOk:hover,
.p-selectbutton .p-highlight.html-buttonOk:hover {
  background-image: linear-gradient(to right, #161e2e 0%, #161e2e 100%);
  background-repeat: repeat-x;
  color: #ffffff;
  border: none;
}
.p-button-primary:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight:focus, .p-selectbutton .p-button.p-highlight:focus, .p-selectbutton .p-highlight.ql-html-buttonCancel:focus,
.p-selectbutton .p-highlight.html-buttonCancel:focus, .p-selectbutton .p-highlight.ql-html-buttonOk:focus,
.p-selectbutton .p-highlight.html-buttonOk:focus, .p-button-primary.focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .focus.p-highlight, .p-selectbutton .focus.p-button.p-highlight, .p-selectbutton .focus.p-highlight.ql-html-buttonCancel,
.p-selectbutton .focus.p-highlight.html-buttonCancel, .p-selectbutton .focus.p-highlight.ql-html-buttonOk,
.p-selectbutton .focus.p-highlight.html-buttonOk {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(71, 83, 107, 0.5);
}
.p-button-primary.disabled, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .disabled.p-highlight, .p-selectbutton .disabled.p-button.p-highlight, .p-selectbutton .disabled.p-highlight.ql-html-buttonCancel,
.p-selectbutton .disabled.p-highlight.html-buttonCancel, .p-selectbutton .disabled.p-highlight.ql-html-buttonOk,
.p-selectbutton .disabled.p-highlight.html-buttonOk, .p-button-primary:disabled, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight:disabled, .p-selectbutton .p-button.p-highlight:disabled, .p-selectbutton .p-highlight.ql-html-buttonCancel:disabled,
.p-selectbutton .p-highlight.html-buttonCancel:disabled, .p-selectbutton .p-highlight.ql-html-buttonOk:disabled,
.p-selectbutton .p-highlight.html-buttonOk:disabled {
  color: #ffffff;
  background-color: #263551;
  border: none;
  background-image: none;
}
.p-button-primary:not(:disabled):not(.disabled):active, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight:not(:disabled):not(.disabled):active, .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active, .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled):active,
.p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled):active, .p-selectbutton .p-highlight.ql-html-buttonOk:not(:disabled):not(.disabled):active,
.p-selectbutton .p-highlight.html-buttonOk:not(:disabled):not(.disabled):active, .p-button-primary:not(:disabled):not(.disabled).active, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight:not(:disabled):not(.disabled).active, .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active, .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled).active,
.p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled).active, .p-selectbutton .p-highlight.ql-html-buttonOk:not(:disabled):not(.disabled).active,
.p-selectbutton .p-highlight.html-buttonOk:not(:disabled):not(.disabled).active, .show > .p-button-primary.dropdown-toggle, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .show > .dropdown-toggle.p-highlight, .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight, .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonCancel,
.p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonCancel, .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonOk,
.p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonOk {
  color: #ffffff;
  background-color: #161e2e;
  background-image: none;
  border: none;
}
.p-button-primary:not(:disabled):not(.disabled):active:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight:not(:disabled):not(.disabled):active:focus, .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:focus, .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled):active:focus,
.p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled):active:focus, .p-selectbutton .p-highlight.ql-html-buttonOk:not(:disabled):not(.disabled):active:focus,
.p-selectbutton .p-highlight.html-buttonOk:not(:disabled):not(.disabled):active:focus, .p-button-primary:not(:disabled):not(.disabled).active:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-highlight:not(:disabled):not(.disabled).active:focus, .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:focus, .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled).active:focus,
.p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled).active:focus, .p-selectbutton .p-highlight.ql-html-buttonOk:not(:disabled):not(.disabled).active:focus,
.p-selectbutton .p-highlight.html-buttonOk:not(:disabled):not(.disabled).active:focus, .show > .p-button-primary.dropdown-toggle:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .show > .dropdown-toggle.p-highlight:focus, .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:focus, .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonCancel:focus,
.p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonCancel:focus, .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonOk:focus,
.p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonOk:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(71, 83, 107, 0.5);
}

.p-button-success, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button:first-of-type.p-highlight, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:first-of-type.p-highlight,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:first-of-type.p-highlight, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:first-of-type.p-highlight,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:first-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button:first-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:first-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:first-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:first-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:first-of-type.p-highlight, .ql-html-buttonOk,
.html-buttonOk, .p-messages .p-message.p-message-success .p-message-close, .p-selectbutton-green .p-selectbutton .p-button.p-highlight, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonOk,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonOk {
  color: #ffffff;
  background-color: #23ae5d;
  background-image: linear-gradient(to right, #23ae5d 0%, #23ae5d 100%);
  background-repeat: repeat-x;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.p-button-success:hover, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:hover:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:hover:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:hover:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:hover:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:hover:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:hover:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:hover:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:hover:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:hover:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:hover:first-of-type, .ql-html-buttonOk:hover,
.html-buttonOk:hover, .p-messages .p-message.p-message-success .p-message-close:hover, .p-selectbutton-green .p-selectbutton .p-button.p-highlight:hover, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel:hover,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel:hover, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonOk:hover,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonOk:hover {
  background-image: linear-gradient(to right, #1a8446 0%, #1a8446 100%);
  background-repeat: repeat-x;
  color: #ffffff;
  border: none;
}
.p-button-success:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:focus:first-of-type, .ql-html-buttonOk:focus,
.html-buttonOk:focus, .p-messages .p-message.p-message-success .p-message-close:focus, .p-selectbutton-green .p-selectbutton .p-button.p-highlight:focus, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel:focus,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel:focus, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonOk:focus,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonOk:focus, .p-button-success.focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.p-button.p-highlight:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonOk.p-highlight:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonOk.p-highlight:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonCancel.p-highlight:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonCancel.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.p-button.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonOk.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonOk.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonCancel.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonCancel.p-highlight:first-of-type, .focus.ql-html-buttonOk,
.focus.html-buttonOk, .p-messages .p-message.p-message-success .focus.p-message-close, .p-selectbutton-green .p-selectbutton .focus.p-button.p-highlight, .p-selectbutton-green .p-selectbutton .focus.p-highlight.ql-html-buttonCancel,
.p-selectbutton-green .p-selectbutton .focus.p-highlight.html-buttonCancel, .p-selectbutton-green .p-selectbutton .focus.p-highlight.ql-html-buttonOk,
.p-selectbutton-green .p-selectbutton .focus.p-highlight.html-buttonOk {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(68, 186, 117, 0.5);
}
.p-button-success.disabled, app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.p-button.p-highlight:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonOk.p-highlight:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonOk.p-highlight:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonCancel.p-highlight:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonCancel.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.p-button.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonOk.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonOk.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonCancel.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonCancel.p-highlight:first-of-type, .disabled.ql-html-buttonOk,
.disabled.html-buttonOk, .p-messages .p-message.p-message-success .disabled.p-message-close, .p-selectbutton-green .p-selectbutton .disabled.p-button.p-highlight, .p-selectbutton-green .p-selectbutton .disabled.p-highlight.ql-html-buttonCancel,
.p-selectbutton-green .p-selectbutton .disabled.p-highlight.html-buttonCancel, .p-selectbutton-green .p-selectbutton .disabled.p-highlight.ql-html-buttonOk,
.p-selectbutton-green .p-selectbutton .disabled.p-highlight.html-buttonOk, .p-button-success:disabled, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:disabled:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:disabled:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:disabled:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:disabled:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:disabled:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:disabled:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:disabled:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:disabled:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:disabled:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:disabled:first-of-type, .ql-html-buttonOk:disabled,
.html-buttonOk:disabled, .p-messages .p-message.p-message-success .p-message-close:disabled, .p-selectbutton-green .p-selectbutton .p-button.p-highlight:disabled, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel:disabled,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel:disabled, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonOk:disabled,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonOk:disabled {
  color: #ffffff;
  background-color: #23ae5d;
  border: none;
  background-image: none;
}
.p-button-success:not(:disabled):not(.disabled):active, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:first-of-type, .ql-html-buttonOk:not(:disabled):not(.disabled):active,
.html-buttonOk:not(:disabled):not(.disabled):active, .p-messages .p-message.p-message-success .p-message-close:not(:disabled):not(.disabled):active, .p-selectbutton-green .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled):active,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled):active, .p-button-success:not(:disabled):not(.disabled).active, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:first-of-type, .ql-html-buttonOk:not(:disabled):not(.disabled).active,
.html-buttonOk:not(:disabled):not(.disabled).active, .p-messages .p-message.p-message-success .p-message-close:not(:disabled):not(.disabled).active, .p-selectbutton-green .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled).active,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled).active, .show > .p-button-success.dropdown-toggle, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:first-of-type, .show > .dropdown-toggle.ql-html-buttonOk,
.show > .dropdown-toggle.html-buttonOk, .p-messages .p-message.p-message-success .show > .dropdown-toggle.p-message-close, .p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight, .p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonCancel,
.p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonCancel, .p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonOk,
.p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonOk {
  color: #ffffff;
  background-color: #1a8446;
  background-image: none;
  border: none;
}
.p-button-success:not(:disabled):not(.disabled):active:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:first-of-type, .ql-html-buttonOk:not(:disabled):not(.disabled):active:focus,
.html-buttonOk:not(:disabled):not(.disabled):active:focus, .p-messages .p-message.p-message-success .p-message-close:not(:disabled):not(.disabled):active:focus, .p-selectbutton-green .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:focus, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled):active:focus,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled):active:focus, .p-button-success:not(:disabled):not(.disabled).active:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:first-of-type, .ql-html-buttonOk:not(:disabled):not(.disabled).active:focus,
.html-buttonOk:not(:disabled):not(.disabled).active:focus, .p-messages .p-message.p-message-success .p-message-close:not(:disabled):not(.disabled).active:focus, .p-selectbutton-green .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:focus, .p-selectbutton-green .p-selectbutton .p-highlight.ql-html-buttonCancel:not(:disabled):not(.disabled).active:focus,
.p-selectbutton-green .p-selectbutton .p-highlight.html-buttonCancel:not(:disabled):not(.disabled).active:focus, .show > .p-button-success.dropdown-toggle:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:focus:first-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:focus:first-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:focus:first-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:focus:first-of-type, .show > .dropdown-toggle.ql-html-buttonOk:focus,
.show > .dropdown-toggle.html-buttonOk:focus, .p-messages .p-message.p-message-success .show > .dropdown-toggle.p-message-close:focus, .p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:focus, .p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonCancel:focus,
.p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonCancel:focus, .p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.ql-html-buttonOk:focus,
.p-selectbutton-green .p-selectbutton .show > .dropdown-toggle.p-highlight.html-buttonOk:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(68, 186, 117, 0.5);
}

.p-button-danger, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button:last-of-type.p-highlight, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:last-of-type.p-highlight,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:last-of-type.p-highlight, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:last-of-type.p-highlight,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:last-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button:last-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:last-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:last-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:last-of-type.p-highlight,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:last-of-type.p-highlight, .p-messages .p-message.p-message-error .p-message-close {
  color: #ffffff;
  background-color: #f52a29;
  background-image: linear-gradient(to right, #f52a29 0%, #eb1d26 100%);
  background-repeat: repeat-x;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.p-button-danger:hover, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:hover:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:hover:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:hover:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:hover:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:hover:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:hover:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:hover:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:hover:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:hover:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:hover:last-of-type, .p-messages .p-message.p-message-error .p-message-close:hover {
  background-image: linear-gradient(to right, #e10c0a 0%, #c41119 100%);
  background-repeat: repeat-x;
  color: #ffffff;
  border: none;
}
.p-button-danger:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:focus:last-of-type, .p-messages .p-message.p-message-error .p-message-close:focus, .p-button-danger.focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.p-button.p-highlight:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonOk.p-highlight:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonOk.p-highlight:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonCancel.p-highlight:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonCancel.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.p-button.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonOk.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonOk.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.ql-html-buttonCancel.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .focus.html-buttonCancel.p-highlight:last-of-type, .p-messages .p-message.p-message-error .focus.p-message-close {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(238, 63, 71, 0.5);
}
.p-button-danger.disabled, app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.p-button.p-highlight:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonOk.p-highlight:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonOk.p-highlight:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonCancel.p-highlight:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonCancel.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.p-button.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonOk.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonOk.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.ql-html-buttonCancel.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .disabled.html-buttonCancel.p-highlight:last-of-type, .p-messages .p-message.p-message-error .disabled.p-message-close, .p-button-danger:disabled, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:disabled:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:disabled:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:disabled:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:disabled:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:disabled:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:disabled:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:disabled:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:disabled:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:disabled:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:disabled:last-of-type, .p-messages .p-message.p-message-error .p-message-close:disabled {
  color: #ffffff;
  background-color: #f52a29;
  border: none;
  background-image: none;
}
.p-button-danger:not(:disabled):not(.disabled):active, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:last-of-type, .p-messages .p-message.p-message-error .p-message-close:not(:disabled):not(.disabled):active, .p-button-danger:not(:disabled):not(.disabled).active, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:last-of-type, .p-messages .p-message.p-message-error .p-message-close:not(:disabled):not(.disabled).active, .show > .p-button-danger.dropdown-toggle, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:last-of-type, .p-messages .p-message.p-message-error .show > .dropdown-toggle.p-message-close {
  color: #ffffff;
  background-color: #e10c0a;
  background-image: none;
  border: none;
}
.p-button-danger:not(:disabled):not(.disabled):active:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled):active:focus:last-of-type, .p-messages .p-message.p-message-error .p-message-close:not(:disabled):not(.disabled):active:focus, .p-button-danger:not(:disabled):not(.disabled).active:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel.p-highlight:not(:disabled):not(.disabled).active:focus:last-of-type, .p-messages .p-message.p-message-error .p-message-close:not(:disabled):not(.disabled).active:focus, .show > .p-button-danger.dropdown-toggle:focus, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:focus:last-of-type, app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:focus:last-of-type,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.p-button.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonOk.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel.p-highlight:focus:last-of-type,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .show > .dropdown-toggle.html-buttonCancel.p-highlight:focus:last-of-type, .p-messages .p-message.p-message-error .show > .dropdown-toggle.p-message-close:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(247, 74, 73, 0.5);
}

.p-button-secondary, .ql-html-buttonCancel,
.html-buttonCancel, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight {
  color: #ffffff;
  background-color: gray;
  background-image: linear-gradient(to right, gray 0%, gray 100%);
  background-repeat: repeat-x;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.p-button-secondary:hover, .ql-html-buttonCancel:hover,
.html-buttonCancel:hover, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight:hover {
  background-image: linear-gradient(to right, #666666 0%, #666666 100%);
  background-repeat: repeat-x;
  color: #ffffff;
  border: none;
}
.p-button-secondary:focus, .ql-html-buttonCancel:focus,
.html-buttonCancel:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight:focus, .p-button-secondary.focus, .focus.ql-html-buttonCancel,
.focus.html-buttonCancel, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .focus.p-state-highlight {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(147, 147, 147, 0.5);
}
.p-button-secondary.disabled, .disabled.ql-html-buttonCancel,
.disabled.html-buttonCancel, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .disabled.p-state-highlight, .p-button-secondary:disabled, .ql-html-buttonCancel:disabled,
.html-buttonCancel:disabled, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight:disabled {
  color: #ffffff;
  background-color: gray;
  border: none;
  background-image: none;
}
.p-button-secondary:not(:disabled):not(.disabled):active, .ql-html-buttonCancel:not(:disabled):not(.disabled):active,
.html-buttonCancel:not(:disabled):not(.disabled):active, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight:not(:disabled):not(.disabled):active, .p-button-secondary:not(:disabled):not(.disabled).active, .ql-html-buttonCancel:not(:disabled):not(.disabled).active,
.html-buttonCancel:not(:disabled):not(.disabled).active, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight:not(:disabled):not(.disabled).active, .show > .p-button-secondary.dropdown-toggle, .show > .dropdown-toggle.ql-html-buttonCancel,
.show > .dropdown-toggle.html-buttonCancel, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .show > .dropdown-toggle.p-state-highlight {
  color: #ffffff;
  background-color: #666666;
  background-image: none;
  border: none;
}
.p-button-secondary:not(:disabled):not(.disabled):active:focus, .ql-html-buttonCancel:not(:disabled):not(.disabled):active:focus,
.html-buttonCancel:not(:disabled):not(.disabled):active:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight:not(:disabled):not(.disabled):active:focus, .p-button-secondary:not(:disabled):not(.disabled).active:focus, .ql-html-buttonCancel:not(:disabled):not(.disabled).active:focus,
.html-buttonCancel:not(:disabled):not(.disabled).active:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .p-state-highlight:not(:disabled):not(.disabled).active:focus, .show > .p-button-secondary.dropdown-toggle:focus, .show > .dropdown-toggle.ql-html-buttonCancel:focus,
.show > .dropdown-toggle.html-buttonCancel:focus, p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td .show > .dropdown-toggle.p-state-highlight:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(147, 147, 147, 0.5);
}

.p-button-light, .p-selectbutton .p-button, .p-selectbutton .ql-html-buttonCancel,
.p-selectbutton .html-buttonCancel, .p-selectbutton .ql-html-buttonOk,
.p-selectbutton .html-buttonOk {
  color: #333333;
  background-color: #f0f1f2;
  background-image: linear-gradient(to right, #f0f1f2 0%, #f0f1f2 100%);
  background-repeat: repeat-x;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.p-button-light:hover, .p-selectbutton .p-button:hover, .p-selectbutton .ql-html-buttonCancel:hover,
.p-selectbutton .html-buttonCancel:hover, .p-selectbutton .ql-html-buttonOk:hover,
.p-selectbutton .html-buttonOk:hover {
  background-image: linear-gradient(to right, #d5d8da 0%, #d5d8da 100%);
  background-repeat: repeat-x;
  color: #333333;
  border: none;
}
.p-button-light:focus, .p-selectbutton .p-button:focus, .p-selectbutton .ql-html-buttonCancel:focus,
.p-selectbutton .html-buttonCancel:focus, .p-selectbutton .ql-html-buttonOk:focus,
.p-selectbutton .html-buttonOk:focus, .p-button-light.focus, .p-selectbutton .focus.p-button, .p-selectbutton .focus.ql-html-buttonCancel,
.p-selectbutton .focus.html-buttonCancel, .p-selectbutton .focus.ql-html-buttonOk,
.p-selectbutton .focus.html-buttonOk {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(209, 210, 212, 0.5);
}
.p-button-light.disabled, .p-selectbutton .disabled.p-button, .p-selectbutton .disabled.ql-html-buttonCancel,
.p-selectbutton .disabled.html-buttonCancel, .p-selectbutton .disabled.ql-html-buttonOk,
.p-selectbutton .disabled.html-buttonOk, .p-button-light:disabled, .p-selectbutton .p-button:disabled, .p-selectbutton .ql-html-buttonCancel:disabled,
.p-selectbutton .html-buttonCancel:disabled, .p-selectbutton .ql-html-buttonOk:disabled,
.p-selectbutton .html-buttonOk:disabled {
  color: #333333;
  background-color: #f0f1f2;
  border: none;
  background-image: none;
}
.p-button-light:not(:disabled):not(.disabled):active, .p-selectbutton .p-button:not(:disabled):not(.disabled):active, .p-selectbutton .ql-html-buttonCancel:not(:disabled):not(.disabled):active,
.p-selectbutton .html-buttonCancel:not(:disabled):not(.disabled):active, .p-selectbutton .ql-html-buttonOk:not(:disabled):not(.disabled):active,
.p-selectbutton .html-buttonOk:not(:disabled):not(.disabled):active, .p-button-light:not(:disabled):not(.disabled).active, .p-selectbutton .p-button:not(:disabled):not(.disabled).active, .p-selectbutton .ql-html-buttonCancel:not(:disabled):not(.disabled).active,
.p-selectbutton .html-buttonCancel:not(:disabled):not(.disabled).active, .p-selectbutton .ql-html-buttonOk:not(:disabled):not(.disabled).active,
.p-selectbutton .html-buttonOk:not(:disabled):not(.disabled).active, .show > .p-button-light.dropdown-toggle, .p-selectbutton .show > .dropdown-toggle.p-button, .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel,
.p-selectbutton .show > .dropdown-toggle.html-buttonCancel, .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk,
.p-selectbutton .show > .dropdown-toggle.html-buttonOk {
  color: #212529;
  background-color: #d5d8da;
  background-image: none;
  border: none;
}
.p-button-light:not(:disabled):not(.disabled):active:focus, .p-selectbutton .p-button:not(:disabled):not(.disabled):active:focus, .p-selectbutton .ql-html-buttonCancel:not(:disabled):not(.disabled):active:focus,
.p-selectbutton .html-buttonCancel:not(:disabled):not(.disabled):active:focus, .p-selectbutton .ql-html-buttonOk:not(:disabled):not(.disabled):active:focus,
.p-selectbutton .html-buttonOk:not(:disabled):not(.disabled):active:focus, .p-button-light:not(:disabled):not(.disabled).active:focus, .p-selectbutton .p-button:not(:disabled):not(.disabled).active:focus, .p-selectbutton .ql-html-buttonCancel:not(:disabled):not(.disabled).active:focus,
.p-selectbutton .html-buttonCancel:not(:disabled):not(.disabled).active:focus, .p-selectbutton .ql-html-buttonOk:not(:disabled):not(.disabled).active:focus,
.p-selectbutton .html-buttonOk:not(:disabled):not(.disabled).active:focus, .show > .p-button-light.dropdown-toggle:focus, .p-selectbutton .show > .dropdown-toggle.p-button:focus, .p-selectbutton .show > .dropdown-toggle.ql-html-buttonCancel:focus,
.p-selectbutton .show > .dropdown-toggle.html-buttonCancel:focus, .p-selectbutton .show > .dropdown-toggle.ql-html-buttonOk:focus,
.p-selectbutton .show > .dropdown-toggle.html-buttonOk:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(209, 210, 212, 0.5);
}

.p-button-white {
  color: black;
  background-color: #ffffff;
  background-image: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
  background-repeat: repeat-x;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.p-button-white:hover {
  background-image: linear-gradient(to right, #e6e6e6 0%, #e6e6e6 100%);
  background-repeat: repeat-x;
  color: black;
  border: none;
}
.p-button-white:focus, .p-button-white.focus {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(222, 222, 223, 0.5);
}
.p-button-white.disabled, .p-button-white:disabled {
  color: black;
  background-color: #ffffff;
  border: none;
  background-image: none;
}
.p-button-white:not(:disabled):not(.disabled):active, .p-button-white:not(:disabled):not(.disabled).active, .show > .p-button-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  background-image: none;
  border: none;
}
.p-button-white:not(:disabled):not(.disabled):active:focus, .p-button-white:not(:disabled):not(.disabled).active:focus, .show > .p-button-white.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(222, 222, 223, 0.5);
}

.p-button-outline-primary {
  color: #000000;
  border-color: #263551;
  box-sizing: border-box;
}
.p-button-outline-primary:hover {
  color: #ffffff;
  background-color: #263551;
  border-color: #263551;
}
.p-button-outline-primary:focus, .p-button-outline-primary.focus {
  box-shadow: none;
}
.p-button-outline-primary.disabled, .p-button-outline-primary:disabled {
  color: #263551;
  background-color: transparent;
}
.p-button-outline-primary:not(:disabled):not(.disabled):active, .p-button-outline-primary:not(:disabled):not(.disabled).active, .show > .p-button-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #263551;
  border-color: #263551;
}
.p-button-outline-primary:not(:disabled):not(.disabled):active:focus, .p-button-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(38, 53, 81, 0.5);
}

.p-button-outline-secondary {
  color: #000000;
  border-color: gray;
  box-sizing: border-box;
}
.p-button-outline-secondary:hover {
  color: #ffffff;
  background-color: gray;
  border-color: gray;
}
.p-button-outline-secondary:focus, .p-button-outline-secondary.focus {
  box-shadow: none;
}
.p-button-outline-secondary.disabled, .p-button-outline-secondary:disabled {
  color: gray;
  background-color: transparent;
}
.p-button-outline-secondary:not(:disabled):not(.disabled):active, .p-button-outline-secondary:not(:disabled):not(.disabled).active, .show > .p-button-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: gray;
  border-color: gray;
}
.p-button-outline-secondary:not(:disabled):not(.disabled):active:focus, .p-button-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(128, 128, 128, 0.5);
}

.p-button-outline-success {
  color: #000000;
  border-color: #23ae5d;
  box-sizing: border-box;
}
.p-button-outline-success:hover {
  color: #ffffff;
  background-color: #23ae5d;
  border-color: #23ae5d;
}
.p-button-outline-success:focus, .p-button-outline-success.focus {
  box-shadow: none;
}
.p-button-outline-success.disabled, .p-button-outline-success:disabled {
  color: #23ae5d;
  background-color: transparent;
}
.p-button-outline-success:not(:disabled):not(.disabled):active, .p-button-outline-success:not(:disabled):not(.disabled).active, .show > .p-button-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #23ae5d;
  border-color: #23ae5d;
}
.p-button-outline-success:not(:disabled):not(.disabled):active:focus, .p-button-outline-success:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(35, 174, 93, 0.5);
}

.p-button-outline-info {
  color: #000000;
  border-color: #4276f2;
  box-sizing: border-box;
}
.p-button-outline-info:hover {
  color: #ffffff;
  background-color: #4276f2;
  border-color: #4276f2;
}
.p-button-outline-info:focus, .p-button-outline-info.focus {
  box-shadow: none;
}
.p-button-outline-info.disabled, .p-button-outline-info:disabled {
  color: #4276f2;
  background-color: transparent;
}
.p-button-outline-info:not(:disabled):not(.disabled):active, .p-button-outline-info:not(:disabled):not(.disabled).active, .show > .p-button-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #4276f2;
  border-color: #4276f2;
}
.p-button-outline-info:not(:disabled):not(.disabled):active:focus, .p-button-outline-info:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(66, 118, 242, 0.5);
}

.p-button-outline-warning {
  color: #000000;
  border-color: #ffc107;
  box-sizing: border-box;
}
.p-button-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.p-button-outline-warning:focus, .p-button-outline-warning.focus {
  box-shadow: none;
}
.p-button-outline-warning.disabled, .p-button-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.p-button-outline-warning:not(:disabled):not(.disabled):active, .p-button-outline-warning:not(:disabled):not(.disabled).active, .show > .p-button-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.p-button-outline-warning:not(:disabled):not(.disabled):active:focus, .p-button-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.p-button-outline-danger {
  color: #000000;
  border-color: #f52a29;
  box-sizing: border-box;
}
.p-button-outline-danger:hover {
  color: #ffffff;
  background-color: #f52a29;
  border-color: #f52a29;
}
.p-button-outline-danger:focus, .p-button-outline-danger.focus {
  box-shadow: none;
}
.p-button-outline-danger.disabled, .p-button-outline-danger:disabled {
  color: #f52a29;
  background-color: transparent;
}
.p-button-outline-danger:not(:disabled):not(.disabled):active, .p-button-outline-danger:not(:disabled):not(.disabled).active, .show > .p-button-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #f52a29;
  border-color: #f52a29;
}
.p-button-outline-danger:not(:disabled):not(.disabled):active:focus, .p-button-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(245, 42, 41, 0.5);
}

.p-button-outline-light {
  color: #000000;
  border-color: #f0f1f2;
  box-sizing: border-box;
}
.p-button-outline-light:hover {
  color: #212529;
  background-color: #f0f1f2;
  border-color: #f0f1f2;
}
.p-button-outline-light:focus, .p-button-outline-light.focus {
  box-shadow: none;
}
.p-button-outline-light.disabled, .p-button-outline-light:disabled {
  color: #f0f1f2;
  background-color: transparent;
}
.p-button-outline-light:not(:disabled):not(.disabled):active, .p-button-outline-light:not(:disabled):not(.disabled).active, .show > .p-button-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f0f1f2;
  border-color: #f0f1f2;
}
.p-button-outline-light:not(:disabled):not(.disabled):active:focus, .p-button-outline-light:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(240, 241, 242, 0.5);
}

.p-button-outline-dark {
  color: #000000;
  border-color: #333333;
  box-sizing: border-box;
}
.p-button-outline-dark:hover {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.p-button-outline-dark:focus, .p-button-outline-dark.focus {
  box-shadow: none;
}
.p-button-outline-dark.disabled, .p-button-outline-dark:disabled {
  color: #333333;
  background-color: transparent;
}
.p-button-outline-dark:not(:disabled):not(.disabled):active, .p-button-outline-dark:not(:disabled):not(.disabled).active, .show > .p-button-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.p-button-outline-dark:not(:disabled):not(.disabled):active:focus, .p-button-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(51, 51, 51, 0.5);
}

.p-button-outline-accent {
  color: #000000;
  border-color: #263551;
  box-sizing: border-box;
}
.p-button-outline-accent:hover {
  color: #ffffff;
  background-color: #263551;
  border-color: #263551;
}
.p-button-outline-accent:focus, .p-button-outline-accent.focus {
  box-shadow: none;
}
.p-button-outline-accent.disabled, .p-button-outline-accent:disabled {
  color: #263551;
  background-color: transparent;
}
.p-button-outline-accent:not(:disabled):not(.disabled):active, .p-button-outline-accent:not(:disabled):not(.disabled).active, .show > .p-button-outline-accent.dropdown-toggle {
  color: #ffffff;
  background-color: #263551;
  border-color: #263551;
}
.p-button-outline-accent:not(:disabled):not(.disabled):active:focus, .p-button-outline-accent:not(:disabled):not(.disabled).active:focus, .show > .p-button-outline-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(38, 53, 81, 0.5);
}

.p-button-icon-primary {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #263551;
}
.p-button-icon-primary:hover {
  color: #263551;
}
.p-button-icon-primary:focus, .p-button-icon-primary.focus {
  box-shadow: 0 0 0 0 rgba(38, 53, 81, 0.5);
}
.p-button-icon-primary.disabled, .p-button-icon-primary:disabled {
  color: #263551;
  background-color: transparent;
}
.p-button-icon-primary:not(:disabled):not(.disabled):active, .p-button-icon-primary:not(:disabled):not(.disabled).active, .show > .p-button-icon-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #263551;
  border-color: #263551;
}
.p-button-icon-primary:not(:disabled):not(.disabled):active:focus, .p-button-icon-primary:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(38, 53, 81, 0.5);
}

.p-button-icon-secondary {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: gray;
}
.p-button-icon-secondary:hover {
  color: gray;
}
.p-button-icon-secondary:focus, .p-button-icon-secondary.focus {
  box-shadow: 0 0 0 0 rgba(128, 128, 128, 0.5);
}
.p-button-icon-secondary.disabled, .p-button-icon-secondary:disabled {
  color: gray;
  background-color: transparent;
}
.p-button-icon-secondary:not(:disabled):not(.disabled):active, .p-button-icon-secondary:not(:disabled):not(.disabled).active, .show > .p-button-icon-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: gray;
  border-color: gray;
}
.p-button-icon-secondary:not(:disabled):not(.disabled):active:focus, .p-button-icon-secondary:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(128, 128, 128, 0.5);
}

.p-button-icon-success {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #23ae5d;
}
.p-button-icon-success:hover {
  color: #23ae5d;
}
.p-button-icon-success:focus, .p-button-icon-success.focus {
  box-shadow: 0 0 0 0 rgba(35, 174, 93, 0.5);
}
.p-button-icon-success.disabled, .p-button-icon-success:disabled {
  color: #23ae5d;
  background-color: transparent;
}
.p-button-icon-success:not(:disabled):not(.disabled):active, .p-button-icon-success:not(:disabled):not(.disabled).active, .show > .p-button-icon-success.dropdown-toggle {
  color: #ffffff;
  background-color: #23ae5d;
  border-color: #23ae5d;
}
.p-button-icon-success:not(:disabled):not(.disabled):active:focus, .p-button-icon-success:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(35, 174, 93, 0.5);
}

.p-button-icon-info {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #4276f2;
}
.p-button-icon-info:hover {
  color: #4276f2;
}
.p-button-icon-info:focus, .p-button-icon-info.focus {
  box-shadow: 0 0 0 0 rgba(66, 118, 242, 0.5);
}
.p-button-icon-info.disabled, .p-button-icon-info:disabled {
  color: #4276f2;
  background-color: transparent;
}
.p-button-icon-info:not(:disabled):not(.disabled):active, .p-button-icon-info:not(:disabled):not(.disabled).active, .show > .p-button-icon-info.dropdown-toggle {
  color: #ffffff;
  background-color: #4276f2;
  border-color: #4276f2;
}
.p-button-icon-info:not(:disabled):not(.disabled):active:focus, .p-button-icon-info:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(66, 118, 242, 0.5);
}

.p-button-icon-warning {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #ffc107;
}
.p-button-icon-warning:hover {
  color: #ffc107;
}
.p-button-icon-warning:focus, .p-button-icon-warning.focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}
.p-button-icon-warning.disabled, .p-button-icon-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.p-button-icon-warning:not(:disabled):not(.disabled):active, .p-button-icon-warning:not(:disabled):not(.disabled).active, .show > .p-button-icon-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.p-button-icon-warning:not(:disabled):not(.disabled):active:focus, .p-button-icon-warning:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.p-button-icon-danger {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #f52a29;
}
.p-button-icon-danger:hover {
  color: #f52a29;
}
.p-button-icon-danger:focus, .p-button-icon-danger.focus {
  box-shadow: 0 0 0 0 rgba(245, 42, 41, 0.5);
}
.p-button-icon-danger.disabled, .p-button-icon-danger:disabled {
  color: #f52a29;
  background-color: transparent;
}
.p-button-icon-danger:not(:disabled):not(.disabled):active, .p-button-icon-danger:not(:disabled):not(.disabled).active, .show > .p-button-icon-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #f52a29;
  border-color: #f52a29;
}
.p-button-icon-danger:not(:disabled):not(.disabled):active:focus, .p-button-icon-danger:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(245, 42, 41, 0.5);
}

.p-button-icon-light {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #f0f1f2;
}
.p-button-icon-light:hover {
  color: #f0f1f2;
}
.p-button-icon-light:focus, .p-button-icon-light.focus {
  box-shadow: 0 0 0 0 rgba(240, 241, 242, 0.5);
}
.p-button-icon-light.disabled, .p-button-icon-light:disabled {
  color: #f0f1f2;
  background-color: transparent;
}
.p-button-icon-light:not(:disabled):not(.disabled):active, .p-button-icon-light:not(:disabled):not(.disabled).active, .show > .p-button-icon-light.dropdown-toggle {
  color: #212529;
  background-color: #f0f1f2;
  border-color: #f0f1f2;
}
.p-button-icon-light:not(:disabled):not(.disabled):active:focus, .p-button-icon-light:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(240, 241, 242, 0.5);
}

.p-button-icon-dark {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #333333;
}
.p-button-icon-dark:hover {
  color: #333333;
}
.p-button-icon-dark:focus, .p-button-icon-dark.focus {
  box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5);
}
.p-button-icon-dark.disabled, .p-button-icon-dark:disabled {
  color: #333333;
  background-color: transparent;
}
.p-button-icon-dark:not(:disabled):not(.disabled):active, .p-button-icon-dark:not(:disabled):not(.disabled).active, .show > .p-button-icon-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.p-button-icon-dark:not(:disabled):not(.disabled):active:focus, .p-button-icon-dark:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(51, 51, 51, 0.5);
}

.p-button-icon-accent {
  background-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  text-align: center;
  color: #263551;
}
.p-button-icon-accent:hover {
  color: #263551;
}
.p-button-icon-accent:focus, .p-button-icon-accent.focus {
  box-shadow: 0 0 0 0 rgba(38, 53, 81, 0.5);
}
.p-button-icon-accent.disabled, .p-button-icon-accent:disabled {
  color: #263551;
  background-color: transparent;
}
.p-button-icon-accent:not(:disabled):not(.disabled):active, .p-button-icon-accent:not(:disabled):not(.disabled).active, .show > .p-button-icon-accent.dropdown-toggle {
  color: #ffffff;
  background-color: #263551;
  border-color: #263551;
}
.p-button-icon-accent:not(:disabled):not(.disabled):active:focus, .p-button-icon-accent:not(:disabled):not(.disabled).active:focus, .show > .p-button-icon-accent.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0 rgba(38, 53, 81, 0.5);
}

.p-button-outline-icon-primary {
  border: 1px solid #263551;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-secondary {
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-success {
  border: 1px solid #23ae5d;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-info {
  border: 1px solid #4276f2;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-warning {
  border: 1px solid #ffc107;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-danger {
  border: 1px solid #f52a29;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-light {
  border: 1px solid #f0f1f2;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-dark {
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.p-button-outline-icon-accent {
  border: 1px solid #263551;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
}

.btn-small {
  height: 40px !important;
}

.p-calendar {
  position: relative;
  display: inline-flex;
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Fluid */
.p-fluid .p-calendar {
  display: flex;
}

.p-fluid .p-calendar .p-inputtext {
  width: 1%;
}

/* Datepicker */
.p-calendar .p-datepicker {
  min-width: 100%;
}

.p-datepicker {
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.p-datepicker-inline {
  position: static;
}

/* Header */
.p-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datepicker-header .p-datepicker-title {
  margin: 0 auto;
}

.p-datepicker-prev,
.p-datepicker-next {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

/* DatePicker Table */
.p-datepicker table {
  width: 100%;
  border-collapse: collapse;
}

.p-datepicker td > span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

/* Month Picker */
.p-monthpicker-month {
  width: 33.3%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

/*  Button Bar */
.p-datepicker-buttonbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Time Picker */
.p-timepicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-timepicker button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.p-timepicker > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
  transform: translate(-50%, -50%);
}

p-calendar {
  display: block;
}
p-calendar .p-calendar {
  font-size: 0.875rem;
  border: 1px solid #f0f1f2;
  border-radius: 3px;
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
}
p-calendar .p-calendar .p-inputtext {
  border: none;
  border-radius: unset;
}
p-calendar .p-calendar.p-calendar-w-btn .p-button-text {
  display: none;
}
p-calendar .p-calendar.p-calendar-w-btn .p-calendar-button {
  font-size: 0.875rem;
  height: auto;
  cursor: pointer;
}
p-calendar .p-calendar.p-calendar-w-btn .p-button-icon-left:focus {
  outline: none;
}
p-calendar .p-calendar .p-datepicker-multiple-month {
  display: flex;
  justify-content: flex-end;
}
p-calendar .p-calendar .p-datepicker-multiple-month .p-datepicker-group {
  background: #ffffff;
  padding: 20px 15px;
  border-top: 1px solid #f0f1f2 !important;
  border-bottom: 1px solid #f0f1f2 !important;
}
p-calendar .p-calendar .p-datepicker-multiple-month .p-datepicker-group:last-of-type {
  border-right: 1px solid #f0f1f2 !important;
}
p-calendar .p-calendar .p-datepicker-multiple-month p-footer {
  display: flex;
  flex-direction: column;
  order: -1;
  background-color: #ffffff;
  padding: 2px 10px;
  height: auto;
  width: auto;
  min-width: 150px;
  justify-content: space-around;
  border-right-width: 0;
}
p-calendar .p-calendar .p-datepicker {
  width: auto;
  min-width: 100%;
  background-color: #ffffff;
}
p-calendar .p-calendar .p-datepicker-inline {
  display: block;
  position: static;
}
p-calendar .p-calendar .p-datepicker.p-datepicker-timeonly .p-timepicker > div {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
p-calendar .p-calendar .p-datepicker.p-datepicker-timeonly .p-timepicker button {
  color: #bfbfbf;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
}
p-calendar .p-calendar .p-datepicker.p-datepicker-timeonly .p-timepicker button:last-child {
  margin-top: 0.2rem;
}
p-calendar .p-calendar .p-datepicker.p-datepicker-timeonly .p-timepicker span {
  font-size: 0.875rem;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group {
  border-width: 0;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header {
  border-bottom-width: 0;
  padding: 0.5rem 0.75rem;
  background: transparent;
  position: relative;
  color: #333333;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-prev,
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-next {
  top: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  border: 0 none;
  width: 2rem;
  height: 2rem;
  color: #333333;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-prev:enabled:hover,
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #333333;
  border-color: transparent;
  background: #f0f1f2;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-prev {
  position: absolute;
  left: 0.75rem;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
  margin: 0 auto;
  text-align: center;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin: 0.5rem;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  padding-left: 0.125rem;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-header .p-datepicker-next {
  position: absolute;
  right: 0.75rem;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar {
  border: 1px solid #f0f1f2;
  border-top-left-radius: 0;
  width: 100%;
  border-collapse: inherit;
  background: #ffffff;
  table-layout: auto;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar thead tr {
  background: transparent;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar thead tr th {
  text-align: center;
  color: #333333;
  text-transform: uppercase;
  border-bottom: 1px solid #f0f1f2;
  padding: 0.5rem 0.75rem;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr {
  background: transparent;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td {
  padding: 0.5rem;
  text-align: center;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td.p-datepicker-other-month {
  visibility: hidden;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td span {
  display: block;
  text-align: center;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  padding-top: 0.25rem;
  border: 1px solid transparent;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td span:hover {
  background: #f0f1f2;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-group .p-datepicker-calendar-container table.p-datepicker-calendar tbody tr td.p-datepicker-today span:not(.p-highlight) {
  border-color: transparent;
  background: #d9d9d9;
}
.p-multiselect {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.p-multiselect-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-multiselect-label-container {
  overflow: hidden;
  flex: 1 1 auto;
  cursor: pointer;
}

.p-multiselect-label {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-multiselect-label-empty {
  overflow: hidden;
  visibility: hidden;
}

.p-multiselect-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-multiselect-token-icon {
  cursor: pointer;
}

.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.p-multiselect-panel {
  position: absolute;
  top: 0;
  left: 0;
}

.p-multiselect-items-wrapper {
  overflow: auto;
}

.p-multiselect-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-multiselect-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-multiselect-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-multiselect-filter-container {
  position: relative;
  flex: 1 1 auto;
}

.p-multiselect-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-multiselect-filter-container .p-inputtext {
  width: 100%;
}

.p-multiselect-close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
}

.p-fluid .p-multiselect {
  display: flex;
}

.p-multiselect {
  position: relative;
  font-size: 0.875rem;
  border: 1px solid #f0f1f2;
  border-radius: 3px;
  min-width: 13rem;
  transition: border-color 0.2s;
  display: flex;
  justify-content: space-between;
}
.p-multiselect .p-multiselect-label-container {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}
.p-multiselect .p-multiselect-trigger {
  cursor: pointer;
  padding: 0.5rem 0.75rem 0.5rem 0;
}
.p-multiselect .p-multiselect-panel {
  overflow: auto;
  position: absolute;
  background: white;
  width: 100%;
  border: 1px solid #f0f1f2;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper {
  overflow-y: auto;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items {
  padding: 0;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items .p-multiselected-item-token,
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items .p-multiselected-empty-token {
  padding: 2px 4px;
  margin: 0 0.286em 0 0;
  display: inline-block;
  vertical-align: middle;
  height: 1.857em;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items .p-multiselected-item-token {
  background: #263551;
  color: #ffffff;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items .p-multiselected-empty-token {
  background: #4276f2;
  color: #ffffff;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items li.p-multiselect-item {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  cursor: pointer;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items li.p-multiselect-item span:not(.p-checkbox-icon) {
  margin-left: 0.5rem;
  font-size: 0.7rem;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items li.p-multiselect-item .p-widget {
  margin: 0 0.75rem 0 0;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items li.p-multiselect-item.p-state-highlight {
  background: linear-gradient(to right, #263551, #263551);
  color: #ffffff;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items li.p-multiselect-item.p-state-highlight:hover {
  background: linear-gradient(to right, #263551, #263551);
  color: #ffffff;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items-wrapper ul.p-multiselect-items li.p-multiselect-item:hover {
  background: #f0f1f2;
  color: #333333;
}

.p-chips {
  display: inline-flex;
}

.p-chips-multiple-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: text;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.p-chips-token, p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-chips-input-token {
  flex: 1 1 auto;
  display: inline-flex;
}

.p-chips-token-icon, p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token span.p-autocomplete-token-icon {
  cursor: pointer;
}

.p-chips-input-token input {
  border: 0 none;
  outline: 0 none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
}

.p-fluid .p-chips {
  display: flex;
}

p-chips .p-inputtext {
  display: inline-flex;
  flex-wrap: wrap;
}
p-chips .p-chips-token, p-chips p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token, p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container p-chips li.p-autocomplete-token {
  font-size: 100%;
  font-weight: 400;
  background-color: #b3b3b3;
  margin: 0 0.2rem;
}
p-chips .p-chips-token-label, p-chips p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token span.p-autocomplete-token-label, p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token p-chips span.p-autocomplete-token-label {
  color: #fafbfc;
}
p-chips .p-chips-token-icon, p-chips p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token span.p-autocomplete-token-icon, p-autoComplete .p-autocomplete-multiple ul.p-autocomplete-multiple-container li.p-autocomplete-token p-chips span.p-autocomplete-token-icon {
  margin: 0 0.5rem 0 0;
  color: #fafbfc;
}
p-chips .p-chips-input-token {
  list-style-type: none;
  padding-right: calc(calc(1.5em + 1rem + 2px) + 5px);
}
p-chips .p-chips-input-token input {
  border: none;
}
p-chips .p-chips-input-token input:focus {
  outline: none;
}
p-chips .p-chips-input-token input:disabled {
  display: none;
}

.p-card-header img {
  width: 100%;
}

p-card .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.p-radiobutton {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  vertical-align: bottom;
  position: relative;
}

.p-radiobutton-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-radiobutton-icon {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0) scale(0.1);
  border-radius: 50%;
  visibility: hidden;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1, 1);
  visibility: visible;
}

p-radiobutton {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}

.p-radiobutton-label {
  line-height: 1;
}

p-radioButton {
  display: flex;
  vertical-align: middle;
  margin-bottom: 0.5rem;
}
p-radioButton .p-radiobutton {
  display: inline-flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
p-radioButton .p-radiobutton .p-radiobutton-box {
  border: 2px solid #b3b3b3;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #a6a6a6;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}
p-radioButton .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #263551;
  background: #263551;
  color: #ffffff;
}
p-radioButton .p-radiobutton .p-radiobutton-label {
  position: relative;
  margin: 0;
}
p-radioButton .p-radiobutton .p-radiobutton-icon {
  font-size: 12px;
}
p-radioButton label {
  margin-left: 5px;
  line-height: 18px;
}

.p-accordion-header-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  text-decoration: none;
}

.p-accordion-header-link:focus {
  z-index: 1;
}

.p-accordion-header-text {
  line-height: 1;
}

p-accordion .p-accordion {
  background-color: #d7deec;
  margin: 0 auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}
p-accordion .p-accordion .p-accordion-tab {
  margin-bottom: 0;
}
p-accordion .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  color: #495057;
  transition: box-shadow 0.2s;
}
p-accordion .p-accordion .p-accordion-content {
  padding: 1rem;
  position: relative;
  background-color: #ffffff;
}

.p-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.p-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-menu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.p-menu .p-menuitem-text {
  line-height: 1;
}

p-editor .ql-container {
  font-size: 20px;
}
p-editor .p-editor-content {
  min-height: 25rem;
}
p-editor .p-editor-content .ql-editor {
  min-height: inherit;
}
p-editor .p-editor-content em[datatype] {
  user-select: none;
}
p-editor .p-editor-container {
  width: 1000px;
  max-width: 1000px;
  min-width: 1000px;
}
p-editor.is-invalid .p-editor-container {
  border: 1px solid #f52a29;
}
p-editor.is-invalid + .p-inline-message.p-inline-message-error {
  color: #f52a29;
}
p-editor.is-invalid + .p-inline-message.p-inline-message-error .p-inline-message-text {
  font-size: 0.75rem;
}

p-tabView .p-tabview-nav {
  border: 0;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: max-content;
}
p-tabView .p-tabview-nav {
  border-width: 0 0 2px 0;
}
p-tabView .p-tabview-nav li {
  margin-right: 0;
  border-bottom: 1px solid #d9d9d9;
}
p-tabView .p-tabview-nav li .p-tabview-nav-link {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  margin: 0 0 -2px 0;
  transition: box-shadow 0.2s;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  color: #b3b3b3;
  border: 0 solid transparent;
  border-bottom: 2px #d9d9d9;
}
p-tabView .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  margin: 0 auto;
}
p-tabView .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #263551;
  color: #263551;
  border-style: solid;
}
p-tabView .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  border-color: #bfbfbf;
  color: #bfbfbf;
}
p-tabView .p-tabview-title {
  line-height: 1;
}
p-tabView .p-tabview-panels {
  border: 0 none;
  color: #333333;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
p-tabView .p-tabview-panel {
  max-height: 20rem;
}
p-tabView .p-tabview-panel-content {
  margin: 0.25rem 0;
}
p-tabView .p-tabview-panel-content:first-of-type {
  margin-top: 0;
}

.p-fileupload-choose {
  padding: 0;
}

.p-fileupload-row > div {
  flex: unset !important;
  width: unset !important;
  padding: 0 0.5rem;
}

.p-fileupload-buttonbar {
  display: flex;
  align-items: center;
}

a:hover {
  text-decoration: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 30px;
}

@media (max-width: 575.98px) {
  html:not([dir=rtl]) .sidebar {
    margin-left: -265px;
  }
}
html:not([dir=rtl]) .aside-menu {
  right: 0;
  margin-right: -20rem;
}

html[dir=rtl] .sidebar {
  margin-right: -265px;
}
html[dir=rtl] .aside-menu {
  left: 0;
  margin-left: -20rem;
}

@media (min-width: 768px) {
  .header-fixed .app-header {
    position: fixed;
    z-index: 1020;
    width: 100%;
  }
  .header-fixed .app-body {
    margin-top: 55px;
  }

  .sidebar-fixed .sidebar {
    position: fixed;
    z-index: 1000;
    width: 265px;
    height: 100vh;
  }

  .sidebar-fixed .app-header + .app-body .sidebar {
    height: calc(100vh - 55px);
  }

  .sidebar-compact .sidebar {
    flex: 0 0 150px;
  }
  .sidebar-compact.sidebar-fixed .sidebar {
    width: 150px;
  }
  .sidebar-compact .sidebar-minimizer {
    display: none;
  }

  .sidebar-minimized .sidebar {
    flex: 0 0 40px;
  }
  .sidebar-minimized.sidebar-fixed .sidebar {
    width: 40px;
  }

  .sidebar-off-canvas .sidebar {
    position: fixed;
    z-index: 1019;
    height: 100%;
  }

  .sidebar-off-canvas .app-header + .app-body .sidebar {
    height: calc(100vh - 55px);
  }

  html:not([dir=rtl]) .sidebar-compact .sidebar {
    margin-left: -150px;
  }
  html:not([dir=rtl]) .sidebar-minimized .sidebar {
    margin-left: -40px;
  }

  html[dir=rtl] .sidebar-compact .sidebar {
    margin-right: -150px;
  }
  html[dir=rtl] .sidebar-minimized .sidebar {
    margin-right: -40px;
  }

  .aside-menu-fixed .aside-menu {
    position: fixed;
    height: 100%;
  }
  .aside-menu-fixed .aside-menu .tab-content {
    height: calc(100vh - 3rem - 55px);
  }

  .aside-menu-fixed .app-header + .app-body .aside-menu {
    height: calc(100vh - 55px);
  }

  .aside-menu-off-canvas .aside-menu {
    position: fixed;
    z-index: 1019;
    height: 100%;
  }

  .aside-menu-off-canvas .app-header + .app-body .aside-menu {
    height: calc(100vh - 55px);
  }

  html:not([dir=rtl]) .aside-menu-fixed .aside-menu,
html:not([dir=rtl]) .aside-menu-off-canvas .aside-menu {
    right: 0;
  }

  html[dir=rtl] .aside-menu-fixed .aside-menu,
html[dir=rtl] .aside-menu-off-canvas .aside-menu {
    left: 0;
  }
}
.breadcrumb-fixed .main {
  padding-top: 4rem;
}
.breadcrumb-fixed .breadcrumb {
  position: fixed;
  top: 55px;
  right: 0;
  left: 0;
  z-index: 1017;
}

html:not([dir=rtl]) .sidebar-show .sidebar,
html:not([dir=rtl]) .sidebar-show .sidebar {
  margin-left: 0;
}
html:not([dir=rtl]) .aside-menu-show .aside-menu,
html:not([dir=rtl]) .aside-menu-show .aside-menu {
  margin-right: 0;
}

html[dir=rtl] .sidebar-show .sidebar,
html[dir=rtl] .sidebar-show .sidebar {
  margin-right: 0;
}
html[dir=rtl] .aside-menu-show .aside-menu,
html[dir=rtl] .aside-menu-show .aside-menu {
  margin-left: 0;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 575.98px) {
  .sidebar-show .main,
.aside-menu-show .main {
    position: relative;
  }
  .sidebar-show .main::before,
.aside-menu-show .main::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1018;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    animation: opacity 0.25s;
  }
}

@media (min-width: 576px) {
  html:not([dir=rtl]) .sidebar-sm-show .sidebar,
html:not([dir=rtl]) .sidebar-show .sidebar {
    margin-left: 0;
  }
  html:not([dir=rtl]) .sidebar-sm-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-sm-show.sidebar-fixed .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 265px;
  }
  html:not([dir=rtl]) .sidebar-sm-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-sm-show.sidebar-fixed.sidebar-compact .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-left: 150px;
  }
  html:not([dir=rtl]) .sidebar-sm-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-sm-show.sidebar-fixed.sidebar-minimized .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 265px;
  }
  html:not([dir=rtl]) .sidebar-sm-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed .breadcrumb {
    left: 265px;
  }
  html:not([dir=rtl]) .sidebar-sm-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    left: 150px;
  }
  html:not([dir=rtl]) .sidebar-sm-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    left: 40px;
  }
  html:not([dir=rtl]) .aside-menu-show .aside-menu,
html:not([dir=rtl]) .aside-menu-sm-show .aside-menu {
    margin-right: 0;
  }
  html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .app-footer,
html:not([dir=rtl]) .aside-menu-sm-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-sm-show.aside-menu-fixed .app-footer {
    margin-right: 20rem;
  }
  html:not([dir=rtl]) .aside-menu-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .aside-menu-sm-show.breadcrumb-fixed .breadcrumb {
    right: 20rem;
  }

  html[dir=rtl] .sidebar-sm-show .sidebar,
html[dir=rtl] .sidebar-show .sidebar {
    margin-right: 0;
  }
  html[dir=rtl] .sidebar-sm-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-sm-show.sidebar-fixed .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-show.sidebar-fixed .app-footer {
    margin-right: 265px;
  }
  html[dir=rtl] .sidebar-sm-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-sm-show.sidebar-fixed.sidebar-compact .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-right: 150px;
  }
  html[dir=rtl] .sidebar-sm-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-sm-show.sidebar-fixed.sidebar-minimized .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-right: 265px;
  }
  html[dir=rtl] .sidebar-sm-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed .breadcrumb {
    right: 265px;
  }
  html[dir=rtl] .sidebar-sm-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    right: 150px;
  }
  html[dir=rtl] .sidebar-sm-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    right: 40px;
  }
  html[dir=rtl] .aside-menu-show .aside-menu,
html[dir=rtl] .aside-menu-sm-show .aside-menu {
    margin-left: 0;
  }
  html[dir=rtl] .aside-menu-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-show.aside-menu-fixed .app-footer,
html[dir=rtl] .aside-menu-sm-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-sm-show.aside-menu-fixed .app-footer {
    margin-left: 20rem;
  }
  html[dir=rtl] .aside-menu-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .aside-menu-sm-show.breadcrumb-fixed .breadcrumb {
    left: 20rem;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
@media (min-width: 768px) {
  html:not([dir=rtl]) .sidebar-md-show .sidebar,
html:not([dir=rtl]) .sidebar-show .sidebar {
    margin-left: 0;
  }
  html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 265px;
  }
  html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed.sidebar-compact .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-left: 150px;
  }
  html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 40px;
  }
  html:not([dir=rtl]) .sidebar-md-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed .breadcrumb {
    left: 265px;
  }
  html:not([dir=rtl]) .sidebar-md-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    left: 150px;
  }
  html:not([dir=rtl]) .sidebar-md-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    left: 40px;
  }
  html:not([dir=rtl]) .aside-menu-show .aside-menu,
html:not([dir=rtl]) .aside-menu-md-show .aside-menu {
    margin-right: 0;
  }
  html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .app-footer,
html:not([dir=rtl]) .aside-menu-md-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-md-show.aside-menu-fixed .app-footer {
    margin-right: 20rem;
  }
  html:not([dir=rtl]) .aside-menu-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .aside-menu-md-show.breadcrumb-fixed .breadcrumb {
    right: 20rem;
  }

  html[dir=rtl] .sidebar-md-show .sidebar,
html[dir=rtl] .sidebar-show .sidebar {
    margin-right: 0;
  }
  html[dir=rtl] .sidebar-md-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-md-show.sidebar-fixed .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-show.sidebar-fixed .app-footer {
    margin-right: 265px;
  }
  html[dir=rtl] .sidebar-md-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-md-show.sidebar-fixed.sidebar-compact .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-right: 150px;
  }
  html[dir=rtl] .sidebar-md-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-md-show.sidebar-fixed.sidebar-minimized .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-right: 40px;
  }
  html[dir=rtl] .sidebar-md-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed .breadcrumb {
    right: 265px;
  }
  html[dir=rtl] .sidebar-md-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    right: 150px;
  }
  html[dir=rtl] .sidebar-md-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    right: 40px;
  }
  html[dir=rtl] .aside-menu-show .aside-menu,
html[dir=rtl] .aside-menu-md-show .aside-menu {
    margin-left: 0;
  }
  html[dir=rtl] .aside-menu-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-show.aside-menu-fixed .app-footer,
html[dir=rtl] .aside-menu-md-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-md-show.aside-menu-fixed .app-footer {
    margin-left: 20rem;
  }
  html[dir=rtl] .aside-menu-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .aside-menu-md-show.breadcrumb-fixed .breadcrumb {
    left: 20rem;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
@media (min-width: 992px) {
  html:not([dir=rtl]) .sidebar-lg-show .sidebar,
html:not([dir=rtl]) .sidebar-show .sidebar {
    margin-left: 0;
  }
  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 265px;
  }
  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed.sidebar-compact .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-left: 150px;
  }
  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 40px;
  }
  html:not([dir=rtl]) .sidebar-lg-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed .breadcrumb {
    left: 265px;
  }
  html:not([dir=rtl]) .sidebar-lg-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    left: 150px;
  }
  html:not([dir=rtl]) .sidebar-lg-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    left: 40px;
  }
  html:not([dir=rtl]) .aside-menu-show .aside-menu,
html:not([dir=rtl]) .aside-menu-lg-show .aside-menu {
    margin-right: 0;
  }
  html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .app-footer,
html:not([dir=rtl]) .aside-menu-lg-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-lg-show.aside-menu-fixed .app-footer {
    margin-right: 20rem;
  }
  html:not([dir=rtl]) .aside-menu-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .aside-menu-lg-show.breadcrumb-fixed .breadcrumb {
    right: 20rem;
  }

  html[dir=rtl] .sidebar-lg-show .sidebar,
html[dir=rtl] .sidebar-show .sidebar {
    margin-right: 0;
  }
  html[dir=rtl] .sidebar-lg-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-lg-show.sidebar-fixed .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-show.sidebar-fixed .app-footer {
    margin-right: 265px;
  }
  html[dir=rtl] .sidebar-lg-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-lg-show.sidebar-fixed.sidebar-compact .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-right: 150px;
  }
  html[dir=rtl] .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-right: 40px;
  }
  html[dir=rtl] .sidebar-lg-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed .breadcrumb {
    right: 265px;
  }
  html[dir=rtl] .sidebar-lg-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    right: 150px;
  }
  html[dir=rtl] .sidebar-lg-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    right: 40px;
  }
  html[dir=rtl] .aside-menu-show .aside-menu,
html[dir=rtl] .aside-menu-lg-show .aside-menu {
    margin-left: 0;
  }
  html[dir=rtl] .aside-menu-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-show.aside-menu-fixed .app-footer,
html[dir=rtl] .aside-menu-lg-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-lg-show.aside-menu-fixed .app-footer {
    margin-left: 20rem;
  }
  html[dir=rtl] .aside-menu-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .aside-menu-lg-show.breadcrumb-fixed .breadcrumb {
    left: 20rem;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
@media (min-width: 1200px) {
  html:not([dir=rtl]) .sidebar-xl-show .sidebar,
html:not([dir=rtl]) .sidebar-show .sidebar {
    margin-left: 0;
  }
  html:not([dir=rtl]) .sidebar-xl-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-xl-show.sidebar-fixed .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 265px;
  }
  html:not([dir=rtl]) .sidebar-xl-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-xl-show.sidebar-fixed.sidebar-compact .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-left: 150px;
  }
  html:not([dir=rtl]) .sidebar-xl-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-xl-show.sidebar-fixed.sidebar-minimized .app-footer,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html:not([dir=rtl]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 40px;
  }
  html:not([dir=rtl]) .sidebar-xl-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed .breadcrumb {
    left: 265px;
  }
  html:not([dir=rtl]) .sidebar-xl-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    left: 150px;
  }
  html:not([dir=rtl]) .sidebar-xl-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html:not([dir=rtl]) .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    left: 40px;
  }
  html:not([dir=rtl]) .aside-menu-show .aside-menu,
html:not([dir=rtl]) .aside-menu-xl-show .aside-menu {
    margin-right: 0;
  }
  html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-show.aside-menu-fixed .app-footer,
html:not([dir=rtl]) .aside-menu-xl-show.aside-menu-fixed .main,
html:not([dir=rtl]) .aside-menu-xl-show.aside-menu-fixed .app-footer {
    margin-right: 20rem;
  }
  html:not([dir=rtl]) .aside-menu-show.breadcrumb-fixed .breadcrumb,
html:not([dir=rtl]) .aside-menu-xl-show.breadcrumb-fixed .breadcrumb {
    right: 20rem;
  }

  html[dir=rtl] .sidebar-xl-show .sidebar,
html[dir=rtl] .sidebar-show .sidebar {
    margin-right: 0;
  }
  html[dir=rtl] .sidebar-xl-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-xl-show.sidebar-fixed .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed .main,
html[dir=rtl] .sidebar-show.sidebar-fixed .app-footer {
    margin-right: 265px;
  }
  html[dir=rtl] .sidebar-xl-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-xl-show.sidebar-fixed.sidebar-compact .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
    margin-right: 150px;
  }
  html[dir=rtl] .sidebar-xl-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-xl-show.sidebar-fixed.sidebar-minimized .app-footer,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .main,
html[dir=rtl] .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-right: 40px;
  }
  html[dir=rtl] .sidebar-xl-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed .breadcrumb {
    right: 265px;
  }
  html[dir=rtl] .sidebar-xl-show.breadcrumb-fixed.sidebar-compact .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
    right: 150px;
  }
  html[dir=rtl] .sidebar-xl-show.breadcrumb-fixed.sidebar-minimized .breadcrumb,
html[dir=rtl] .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
    right: 40px;
  }
  html[dir=rtl] .aside-menu-show .aside-menu,
html[dir=rtl] .aside-menu-xl-show .aside-menu {
    margin-left: 0;
  }
  html[dir=rtl] .aside-menu-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-show.aside-menu-fixed .app-footer,
html[dir=rtl] .aside-menu-xl-show.aside-menu-fixed .main,
html[dir=rtl] .aside-menu-xl-show.aside-menu-fixed .app-footer {
    margin-left: 20rem;
  }
  html[dir=rtl] .aside-menu-show.breadcrumb-fixed .breadcrumb,
html[dir=rtl] .aside-menu-xl-show.breadcrumb-fixed .breadcrumb {
    left: 20rem;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.footer-fixed .app-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1020;
  height: 50px;
}
.footer-fixed .app-body {
  margin-bottom: 50px;
}

.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.sidebar-nav {
  transition: width 0.25s;
}

.breadcrumb {
  transition: left 0.25s, right 0.25s, width 0.25s;
}

@media (max-width: 767.98px) {
  .app-header {
    position: fixed;
    z-index: 1020;
    width: 100%;
    text-align: center;
    background-color: #fff;
  }
  .app-header .navbar-toggler {
    color: #fff;
  }
  .app-header .navbar-brand {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -77.5px;
  }

  .app-body {
    margin-top: 55px;
  }

  .sidebar {
    position: fixed;
    z-index: 1019;
    width: 265px;
    height: calc(100vh - 55px);
  }

  .sidebar-minimizer {
    display: none;
  }

  .aside-menu {
    position: fixed;
    height: 100%;
  }
}
ul.nav {
  display: flex;
  padding: 0;
}
ul.nav.nav-pills li.nav-item {
  cursor: pointer;
}
ul.nav.nav-vertical {
  padding: 1rem 1rem 0;
  flex-direction: column;
}
ul.nav.nav-horizontal {
  flex-direction: row;
}
ul.nav.nav-horizontal li.nav-item {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
ul.nav.nav-horizontal li.nav-item:last-child {
  margin-right: 0;
}

.icon:before {
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-campaigns:before {
  content: url("../assets/icons/campaigns-white.svg");
}
.icon-menu-campaigns.active:before {
  content: url("../assets/icons/campaigns-blue.svg");
}

.icon-menu-groups:before {
  content: url("../assets/icons/groups-white.svg");
}
.icon-menu-groups.active:before {
  content: url("../assets/icons/groups-blue.svg");
}

.icon-menu-emails:before {
  content: url("../assets/icons/emails-white.svg");
}
.icon-menu-emails.active:before {
  content: url("../assets/icons/emails-blue.svg");
}

.icon-menu-sms:before {
  content: url("../assets/icons/sms-white.svg");
}
.icon-menu-sms.active:before {
  content: url("../assets/icons/sms-blue.svg");
}

.icon-menu-notification:before {
  content: url("../assets/icons/notification-white.svg");
}
.icon-menu-notification.active:before {
  content: url("../assets/icons/notification-blue.svg");
}

.icon-menu-whatsapp:before {
  content: url("../assets/icons/whatsapp-white.svg");
}
.icon-menu-whatsapp.active:before {
  content: url("../assets/icons/whatsapp-blue.svg");
}

.icon-menu-changelog:before {
  content: url("../assets/icons/changelog-white.svg");
}
.icon-menu-changelog.active:before {
  content: url("../assets/icons/changelog-blue.svg");
}

.icon-menu-dashboard:before {
  content: url("../assets/icons/dashboard-white.svg");
}
.icon-menu-dashboard.active:before {
  content: url("../assets/icons/dashboard-blue.svg");
}

.icon-onetime-active:before {
  content: url("../assets/icons/onetime-active.svg");
}
.icon-onetime-draft:before {
  content: url("../assets/icons/onetime-draft.svg");
}
.icon-onetime-finished:before {
  content: url("../assets/icons/onetime-finished.svg");
}
.icon-onetime-hold:before {
  content: url("../assets/icons/onetime-hold.svg");
}

.icon-continuous-active:before {
  content: url("../assets/icons/continuous-active.svg");
}
.icon-continuous-draft:before {
  content: url("../assets/icons/continuous-draft.svg");
}
.icon-continuous-finished:before {
  content: url("../assets/icons/continuous-finished.svg");
}
.icon-continuous-hold:before {
  content: url("../assets/icons/continuous-hold.svg");
}

.icon-instant-active:before {
  content: url("../assets/icons/instant-active.svg");
}
.icon-instant-draft:before {
  content: url("../assets/icons/instant-draft.svg");
}
.icon-instant-finished:before {
  content: url("../assets/icons/instant-finished.svg");
}
.icon-instant-hold:before {
  content: url("../assets/icons/instant-hold.svg");
}

.icon-emails-dashboard:before {
  content: url("../assets/icons/emails-dashboard.svg");
}

.icon-notification-dashboard:before {
  content: url("../assets/icons/notification-dashboard.svg");
}

.icon-sms-dashboard:before {
  content: url("../assets/icons/sms-dashboard.svg");
}

.icon-whatsapp-dashboard:before {
  content: url("../assets/icons/whatsapp-dashboard.svg");
}

.icon-edit:before {
  content: url("../assets/icons/edit.svg");
}

.icon-voucher:before {
  content: url("../assets/icons/voucher.svg");
}

.icon-settings:before {
  content: url("../assets/icons/settings.svg");
}

.icon-logout:before {
  content: url("../assets/icons/logout.svg");
}

.app-draggable,
.cdk-drag:not(.cdk-drag-disabled) {
  cursor: pointer;
}

app-form-group.is-filled p-dropdown + .p-label,
app-form-group.is-filled p-multiSelect + .p-label,
app-form-group.is-filled p-autoComplete + .p-label,
app-form-group.is-filled p-calendar + .p-label,
app-form-group.is-filled p-inputNumber + .p-label,
app-form-group.is-filled .p-label, app-form-group.is-focused p-dropdown + .p-label,
app-form-group.is-focused p-multiSelect + .p-label,
app-form-group.is-focused p-autoComplete + .p-label,
app-form-group.is-focused p-calendar + .p-label,
app-form-group.is-focused p-inputNumber + .p-label,
app-form-group.is-focused .p-label, .app-form-group.is-filled p-dropdown + .p-label,
.app-form-group.is-filled p-multiSelect + .p-label,
.app-form-group.is-filled p-autoComplete + .p-label,
.app-form-group.is-filled p-calendar + .p-label,
.app-form-group.is-filled p-inputNumber + .p-label,
.app-form-group.is-filled .p-label, .app-form-group.is-focused p-dropdown + .p-label,
.app-form-group.is-focused p-multiSelect + .p-label,
.app-form-group.is-focused p-autoComplete + .p-label,
.app-form-group.is-focused p-calendar + .p-label,
.app-form-group.is-focused p-inputNumber + .p-label,
.app-form-group.is-focused .p-label {
  left: 0;
  top: -26px;
  opacity: 1;
  background: transparent;
  font-size: 0.75rem;
}
app-form-group.is-filled ckeditor + .p-label, app-form-group.is-focused ckeditor + .p-label, .app-form-group.is-filled ckeditor + .p-label, .app-form-group.is-focused ckeditor + .p-label {
  font-size: 1rem;
  top: -36px;
}
app-form-group.is-filled p-inputSwitch + .p-label, app-form-group.is-focused p-inputSwitch + .p-label, .app-form-group.is-filled p-inputSwitch + .p-label, .app-form-group.is-focused p-inputSwitch + .p-label {
  left: 3rem;
  top: -8px;
}
app-form-group.is-readonly p-dropdown .p-inputtext,
app-form-group.is-readonly p-dropdown .p-dropdown-trigger,
app-form-group.is-readonly p-multiSelect .p-inputtext,
app-form-group.is-readonly p-multiSelect .p-dropdown-trigger,
app-form-group.is-readonly p-autoComplete .p-inputtext,
app-form-group.is-readonly p-autoComplete .p-dropdown-trigger,
app-form-group.is-readonly p-calendar .p-inputtext,
app-form-group.is-readonly p-calendar .p-dropdown-trigger,
app-form-group.is-readonly p-inputNumber .p-inputtext,
app-form-group.is-readonly p-inputNumber .p-dropdown-trigger, .app-form-group.is-readonly p-dropdown .p-inputtext,
.app-form-group.is-readonly p-dropdown .p-dropdown-trigger,
.app-form-group.is-readonly p-multiSelect .p-inputtext,
.app-form-group.is-readonly p-multiSelect .p-dropdown-trigger,
.app-form-group.is-readonly p-autoComplete .p-inputtext,
.app-form-group.is-readonly p-autoComplete .p-dropdown-trigger,
.app-form-group.is-readonly p-calendar .p-inputtext,
.app-form-group.is-readonly p-calendar .p-dropdown-trigger,
.app-form-group.is-readonly p-inputNumber .p-inputtext,
.app-form-group.is-readonly p-inputNumber .p-dropdown-trigger {
  cursor: default;
  opacity: 0.6;
  background-color: #f0f1f2;
  pointer-events: none;
  user-select: none;
}
app-form-group.is-readonly p-dropdown .p-inputtext:focus,
app-form-group.is-readonly p-dropdown .p-dropdown-trigger:focus,
app-form-group.is-readonly p-multiSelect .p-inputtext:focus,
app-form-group.is-readonly p-multiSelect .p-dropdown-trigger:focus,
app-form-group.is-readonly p-autoComplete .p-inputtext:focus,
app-form-group.is-readonly p-autoComplete .p-dropdown-trigger:focus,
app-form-group.is-readonly p-calendar .p-inputtext:focus,
app-form-group.is-readonly p-calendar .p-dropdown-trigger:focus,
app-form-group.is-readonly p-inputNumber .p-inputtext:focus,
app-form-group.is-readonly p-inputNumber .p-dropdown-trigger:focus, .app-form-group.is-readonly p-dropdown .p-inputtext:focus,
.app-form-group.is-readonly p-dropdown .p-dropdown-trigger:focus,
.app-form-group.is-readonly p-multiSelect .p-inputtext:focus,
.app-form-group.is-readonly p-multiSelect .p-dropdown-trigger:focus,
.app-form-group.is-readonly p-autoComplete .p-inputtext:focus,
.app-form-group.is-readonly p-autoComplete .p-dropdown-trigger:focus,
.app-form-group.is-readonly p-calendar .p-inputtext:focus,
.app-form-group.is-readonly p-calendar .p-dropdown-trigger:focus,
.app-form-group.is-readonly p-inputNumber .p-inputtext:focus,
.app-form-group.is-readonly p-inputNumber .p-dropdown-trigger:focus {
  outline: none;
  user-select: none;
}
app-form-group.is-readonly .p-inputtext, .app-form-group.is-readonly .p-inputtext {
  cursor: default;
  opacity: 0.6;
  background-color: #f0f1f2;
  pointer-events: none;
  user-select: none;
}
app-form-group.is-readonly .p-inputtext:focus, .app-form-group.is-readonly .p-inputtext:focus {
  outline: none;
  user-select: none;
}
app-form-group.is-readonly p-editor .ql-editor,
app-form-group.is-readonly p-editor .ql-toolbar, .app-form-group.is-readonly p-editor .ql-editor,
.app-form-group.is-readonly p-editor .ql-toolbar {
  cursor: default;
  opacity: 0.6;
  background-color: #f0f1f2;
  user-select: none;
  pointer-events: none;
}
app-form-group.is-readonly p-editor .ql-editor *,
app-form-group.is-readonly p-editor .ql-toolbar *, .app-form-group.is-readonly p-editor .ql-editor *,
.app-form-group.is-readonly p-editor .ql-toolbar * {
  cursor: default;
  user-select: none;
}

.sidebar {
  font-family: "Montserrat", "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial;
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #fff;
  background-image: linear-gradient(to bottom, #263551 0%, #263551 100%);
  background-repeat: repeat-x;
}
.sidebar .sidebar-close {
  position: absolute;
  right: 0;
  display: none;
  padding: 0 1rem;
  font-size: 24px;
  font-weight: 800;
  line-height: 55px;
  color: #fff;
  background: 0;
  border: 0;
  opacity: 0.8;
}
.sidebar .sidebar-close:hover {
  opacity: 1;
}
.sidebar .brand {
  display: block;
  padding: 1rem 0;
  text-align: center;
}
.sidebar .brand img {
  color: #ffffff;
  width: 10rem;
}
.sidebar .brand-minimized {
  display: none;
}
.sidebar .sidebar-header {
  flex: 0 0 auto;
  padding: 0.75rem 1rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
}
.sidebar .sidebar-nav {
  position: relative;
  flex: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 265px;
}
.sidebar .nav {
  width: 265px;
  flex-direction: column;
  min-height: 100%;
  padding: 0;
}
.sidebar .nav-title {
  padding: 0.75rem 1rem;
  font-size: 80%;
  font-weight: 700;
  color: #f0f1f2;
  text-transform: uppercase;
}
.sidebar .nav-divider {
  height: 10px;
}
.sidebar .nav-item {
  position: relative;
  margin: 0;
  transition: background 0.3s ease-in-out;
}
.sidebar .nav-dropdown-items {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out;
}
.sidebar .nav-dropdown-items .nav-item {
  padding: 0;
  list-style: none;
}
.sidebar .nav-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 0 0;
  color: #fff;
  text-decoration: none;
  background: transparent;
}
.sidebar .nav-link .nav-icon {
  display: block;
  margin: 0 0 0 0;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
}
.sidebar .nav-link span {
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 300;
}
.sidebar .nav-link .badge {
  float: right;
  margin-top: 2px;
}
.sidebar .nav-link.active {
  color: #263551;
  background: #fafbfc;
}
.sidebar .nav-link.active .nav-icon {
  color: #263551;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}
.sidebar .nav-link:hover .nav-icon {
  color: #fff;
}
.sidebar .nav-link:hover.nav-dropdown-toggle::before {
  color: white;
}
.sidebar .nav-link.disabled {
  color: #b3b3b3;
  cursor: default;
  background: transparent;
}
.sidebar .nav-link.disabled .nav-icon {
  color: #ffffff;
}
.sidebar .nav-link.disabled:hover {
  color: #b3b3b3;
}
.sidebar .nav-link.disabled:hover .nav-icon {
  color: #ffffff;
}
.sidebar .nav-link.nav-link-primary {
  background: #263551;
}
.sidebar .nav-link.nav-link-primary .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-primary:hover {
  background: #1e2a40;
}
.sidebar .nav-link.nav-link-primary:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-secondary {
  background: gray;
}
.sidebar .nav-link.nav-link-secondary .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-secondary:hover {
  background: #737373;
}
.sidebar .nav-link.nav-link-secondary:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-success {
  background: #23ae5d;
}
.sidebar .nav-link.nav-link-success .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-success:hover {
  background: #1f9952;
}
.sidebar .nav-link.nav-link-success:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-info {
  background: #4276f2;
}
.sidebar .nav-link.nav-link-info .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-info:hover {
  background: #2a65f0;
}
.sidebar .nav-link.nav-link-info:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-warning {
  background: #ffc107;
}
.sidebar .nav-link.nav-link-warning .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-warning:hover {
  background: #edb100;
}
.sidebar .nav-link.nav-link-warning:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-danger {
  background: #f52a29;
}
.sidebar .nav-link.nav-link-danger .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-danger:hover {
  background: #f41211;
}
.sidebar .nav-link.nav-link-danger:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-light {
  background: #f0f1f2;
}
.sidebar .nav-link.nav-link-light .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-light:hover {
  background: #e2e4e6;
}
.sidebar .nav-link.nav-link-light:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-dark {
  background: #333333;
}
.sidebar .nav-link.nav-link-dark .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-dark:hover {
  background: #262626;
}
.sidebar .nav-link.nav-link-dark:hover i {
  color: #fff;
}
.sidebar .nav-link.nav-link-accent {
  background: #263551;
}
.sidebar .nav-link.nav-link-accent .nav-icon {
  color: rgba(255, 255, 255, 0.7);
}
.sidebar .nav-link.nav-link-accent:hover {
  background: #1e2a40;
}
.sidebar .nav-link.nav-link-accent:hover i {
  color: #fff;
}
.sidebar .nav-dropdown-toggle {
  position: relative;
}
.sidebar .nav-dropdown-toggle::before {
  position: absolute;
  top: calc(50% - 4px);
  right: 0;
  display: block;
  width: 8px;
  font-size: 0.75rem;
  height: 8px;
  padding: 0;
  color: white;
  transition: transform 0.3s;
}
.sidebar .nav-dropdown-toggle .badge {
  margin-right: 16px;
}
.sidebar .nav-dropdown.open > .nav-dropdown-items {
  background: rgba(0, 0, 0, 0.2);
  max-height: 1500px;
  font-size: 0.875rem;
}
.sidebar .nav-dropdown.open .nav-link {
  padding-left: 2rem;
  color: #fff;
  border-left: 0;
}
.sidebar .nav-dropdown.open .nav-link.disabled {
  color: #b3b3b3;
  background: transparent;
}
.sidebar .nav-dropdown.open .nav-link.disabled:hover {
  color: #b3b3b3;
}
.sidebar .nav-dropdown.open .nav-link.disabled:hover .nav-icon {
  color: #ffffff;
}
.sidebar .nav-dropdown.open .nav-dropdown.open {
  border-left: 0;
}
.sidebar .nav-label {
  display: block;
  padding: 0 0;
  color: #f0f1f2;
}
.sidebar .nav-label:hover {
  color: #fff;
  text-decoration: none;
}
.sidebar .nav-label .nav-icon {
  width: 20px;
  margin: -3px 0 0 0;
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
}
.sidebar .progress {
  background-color: rgba(38, 38, 38, 0) !important;
}
.sidebar .sidebar-footer {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 1.5rem 0rem;
  background: transparent;
}
.sidebar .sidebar-footer .avatar {
  height: 2rem;
  width: 2rem;
  margin: 0;
}
.sidebar .sidebar-footer ul.nav {
  flex-direction: row;
  justify-content: flex-end;
}
.sidebar .sidebar-minimizer {
  position: relative;
  flex: 0 0 50px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border: 0;
}
.sidebar .sidebar-minimizer::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23b3b3b3' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  transition: 0.3s;
}
.sidebar .sidebar-minimizer:focus, .sidebar .sidebar-minimizer.focus {
  outline: 0;
}
.sidebar .sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.sidebar .sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.sidebar .sidebar-user {
  display: block;
  text-align: center;
}
.sidebar .sidebar-user .user-image img {
  width: 4rem;
  height: 4rem;
}
.sidebar .sidebar-user .user-content {
  display: block;
  text-align: center;
}
.sidebar .sidebar-user .user-content p {
  padding: 0.25rem 0;
  margin: 0;
}
.sidebar .sidebar-user .user-content .group-name {
  font-size: 0.875rem;
}
.sidebar .sidebar-user ul.user-actions {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0 0;
  font-size: 1.5rem;
}
.sidebar .sidebar-user ul.user-actions li {
  padding: 0 0.25rem;
  cursor: pointer;
}
@media (min-width: 768px) {
  .sidebar-compact .sidebar .sidebar-nav {
    width: 150px;
  }
  .sidebar-compact .sidebar .nav {
    width: 150px;
  }
  .sidebar-compact .sidebar .d-compact-none {
    display: none;
  }
  .sidebar-compact .sidebar .nav-title {
    text-align: center;
  }
  .sidebar-compact .sidebar .nav-item {
    width: 150px;
    border-left: 0;
  }
  .sidebar-compact .sidebar .nav-link {
    text-align: center;
  }
  .sidebar-compact .sidebar .nav-link .nav-icon {
    display: block;
    width: 100%;
    margin: 0.25rem 0;
    font-size: 24px;
  }
  .sidebar-compact .sidebar .nav-link .badge {
    position: absolute;
    top: 18px;
    right: 10px;
  }
  .sidebar-compact .sidebar .nav-link.nav-dropdown-toggle::before {
    top: 30px;
  }

  .sidebar-minimized .sidebar {
    z-index: 1019;
    align-items: center;
  }
  .sidebar-minimized .sidebar .brand {
    display: none;
  }
  .sidebar-minimized .sidebar .brand-minimized {
    display: block;
    padding: 1rem 0;
    text-align: center;
  }
  .sidebar-minimized .sidebar .brand-minimized .logo-container {
    height: 31.5px;
    width: 31.5px;
    background-image: url("../../../../assets/img/sc_blue_neg_grey_e6.png");
    background-size: contain;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .sidebar-minimized .sidebar .sidebar-nav {
    overflow: visible;
    width: 40px;
  }
  .sidebar-minimized .sidebar .nav {
    width: 40px;
  }
  .sidebar-minimized .sidebar .nav-dropdown.open .nav-link {
    padding-left: 0;
  }
  .sidebar-minimized .sidebar .d-minimized-none,
.sidebar-minimized .sidebar .nav-divider,
.sidebar-minimized .sidebar .nav-label,
.sidebar-minimized .sidebar .nav-title,
.sidebar-minimized .sidebar .sidebar-form,
.sidebar-minimized .sidebar .sidebar-header {
    display: none;
  }
  .sidebar-minimized .sidebar .sidebar-minimizer {
    position: fixed;
    bottom: 0;
    width: 40px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
  }
  .sidebar-minimized .sidebar .sidebar-nav {
    padding-bottom: 50px;
  }
  .sidebar-minimized .sidebar .sidebar-minimizer::before {
    width: 100%;
    transform: rotate(-180deg);
  }
  .sidebar-minimized .sidebar .nav-item {
    width: 40px;
    overflow: hidden;
    padding: 0.2rem 0;
  }
  .sidebar-minimized .sidebar section :not(.nav-dropdown-items) > .nav-item:last-child::after {
    display: block;
    margin-bottom: 50px;
    content: "";
  }
  .sidebar-minimized .sidebar .nav-link {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0;
    white-space: nowrap;
    border-left: 0;
  }
  .sidebar-minimized .sidebar .nav-link .nav-icon {
    display: block;
    margin: 0;
  }
  .sidebar-minimized .sidebar .nav-link span {
    display: none;
  }
  .sidebar-minimized .sidebar .nav-link .badge {
    position: absolute;
    right: 15px;
    display: none;
  }
  .sidebar-minimized .sidebar .nav-link:hover {
    width: 40px;
    background: rgba(255, 255, 255, 0.3);
  }
  .sidebar-minimized .sidebar .nav-link:hover .badge {
    display: inline;
  }
  .sidebar-minimized .sidebar .nav-link.nav-dropdown-toggle::before {
    display: none;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
    width: 265px;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
    width: 265px;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    display: none;
    max-height: 1000px;
    background: transparent;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    position: absolute;
    left: 40px;
    display: inline;
  }
  .sidebar-minimized .sidebar .sidebar-user .user-image img {
    width: 2rem;
    height: 2rem;
  }
  .sidebar-minimized .sidebar .sidebar-user .user-content {
    display: none;
  }
  .sidebar-minimized .sidebar .sidebar-footer {
    justify-content: center;
    padding: 1.5rem 0rem;
  }
  .sidebar-minimized .sidebar .sidebar-footer ul.nav .nav-item {
    text-align: center;
    padding: 0;
  }

  *[dir=rtl] .sidebar-minimized .sidebar .nav {
    list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav .divider {
    height: 0;
  }
  *[dir=rtl] .sidebar-minimized .sidebar .sidebar-minimizer::before {
    width: 100%;
    transform: rotate(0deg);
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav-link {
    padding-right: 0;
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav-link .nav-icon {
    float: right;
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav-link .badge {
    right: auto;
    left: 15px;
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav-link:hover .badge {
    display: inline;
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    display: none;
    max-height: 1000px;
    background: transparent;
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  *[dir=rtl] .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    position: absolute;
    left: 0;
    display: inline;
  }
}
*[dir=rtl] .sidebar .nav-dropdown-toggle::before {
  position: absolute;
  right: auto;
  left: 0;
  transform: rotate(180deg);
}
*[dir=rtl] .sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  transform: rotate(270deg);
}
*[dir=rtl] .sidebar .nav-link .nav-icon {
  margin: 0 0 0 0;
}
*[dir=rtl] .sidebar .nav-link .badge {
  float: left;
  margin-top: 2px;
}
*[dir=rtl] .sidebar .nav-link.nav-dropdown-toggle .badge {
  margin-right: auto;
  margin-left: 16px;
}
*[dir=rtl] .sidebar .sidebar-minimizer::before {
  right: auto;
  left: 0;
  transform: rotate(180deg);
}
*[dir=rtl] .sidebar-toggler {
  margin-right: 0 !important;
}

.p-sidebar-content {
  height: 100%;
  background-color: #263551;
}
.p-sidebar-content a {
  color: white;
  text-decoration: none;
}
.p-sidebar-content a:hover {
  color: white;
}
.p-sidebar-content a .p-sidebar-close {
  color: white;
  background-color: #263551;
}
.p-sidebar-content a .p-sidebar-close .p-sidebar-close-icon {
  opacity: 1;
}
.p-sidebar-content .logout-btn {
  position: absolute;
  bottom: 0;
  left: 0;
}

app-layout-aside .app-layout-aside {
  position: relative;
  width: 20rem;
  height: 100%;
  min-height: 100vh;
  background: #fff;
  color: #b3b3b3;
  border-right: 1px solid #bfbfbf;
}
app-layout-aside .app-layout-aside .aside-toggle {
  cursor: pointer;
  width: 2rem;
  height: 100%;
  position: absolute;
  right: -3.5rem;
  top: 0;
  border: 1px solid #f0f1f2;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  padding: 0.5rem;
  z-index: 999;
}
app-layout-aside .app-layout-aside nav {
  padding: 1rem;
}
app-layout-aside .app-layout-aside nav.aside-buttons {
  padding: 1rem 0.5rem;
}
app-layout-aside .app-layout-aside nav.aside-buttons li {
  width: 100%;
}
app-layout-aside .app-layout-aside nav.aside-buttons li > *:not(.aside-toggle) {
  width: 100%;
}
app-layout-aside .app-layout-aside nav.aside-nav ul.nav {
  overflow: hidden auto;
}
app-layout-aside .app-layout-aside nav.aside-nav ul.nav li.nav-item {
  max-width: 100%;
  width: 100%;
  margin-right: 0;
}
app-layout-aside.aside-minimized .app-layout-aside {
  width: 0;
  padding: 1rem 0;
}
app-layout-aside.aside-minimized .app-layout-aside .aside-toggle {
  height: unset;
  right: -1.1rem;
}

app-loader {
  display: block;
}
app-loader .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 100%;
}
app-loader .spinner.spinner-primary {
  color: #263551;
}
app-loader .spinner.spinner-white {
  color: #ffffff;
}
app-loader.overlay, app-loader.default {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
app-loader.overlay {
  background: #d9d9d9;
  opacity: 0.5;
  border-radius: 3px;
  box-shadow: inset 0 0 10px #d9d9d9;
}

app-calendar.is-invalid .p-inputtext {
  border: 1px solid #f52a29;
}
app-calendar.is-invalid + .p-inline-message.p-inline-message-error {
  color: #f52a29;
}
app-calendar.is-invalid + .p-inline-message.p-inline-message-error .p-inline-message-text {
  font-size: 0.75rem;
}

p-dynamicdialog .p-dialog {
  width: 50%;
}
p-dynamicdialog .p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
p-dynamicdialog .p-dialog-title {
  margin: 1rem auto 0 1rem;
}
p-dynamicdialog .p-dialog-header-icons {
  margin: 5px;
  align-self: flex-start;
}
p-dynamicdialog .p-dialog-header-icons .p-dialog-header-icon {
  padding: 5px;
  color: #b3b3b3;
  border-radius: 50%;
  border-color: transparent;
  background-color: transparent;
}
p-dynamicdialog .p-dialog-header-icons .p-dialog-header-icon:hover {
  color: #999999;
  background-color: #f0f1f2;
}
p-dynamicdialog .button-row {
  display: flex;
  justify-content: flex-end;
}
p-dynamicdialog .popup-details {
  font-weight: bold;
  color: #bd0000;
}
p-dynamicdialog p-dynamicdialog .p-dialog-header {
  text-align: left !important;
}
p-dynamicdialog textarea {
  min-height: 10rem;
}
p-dynamicdialog textarea,
p-dynamicdialog input {
  margin-top: 1rem;
}

app-message-template .message-container, app-settings-footer .message-container {
  overflow: auto;
  flex-wrap: nowrap;
}
app-message-template div[contenteditable], app-settings-footer div[contenteditable] {
  background-color: #ffffff;
  min-height: 30rem;
  overflow-wrap: break-word;
}
app-message-template .ql-label-placeholder, app-settings-footer .ql-label-placeholder {
  color: blue;
  user-select: none;
  white-space: nowrap;
}
app-message-template .ql-image-placeholder, app-settings-footer .ql-image-placeholder {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: transparent;
  align-self: center;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}
app-message-template .ql-sms-toolbar > .ql-container, app-settings-footer .ql-sms-toolbar > .ql-container {
  border-top: 1px solid #ccc !important;
}
app-message-template .ql-sms-toolbar > .ql-toolbar, app-settings-footer .ql-sms-toolbar > .ql-toolbar {
  display: none;
}
app-message-template button[title=HTML], app-settings-footer button[title=HTML] {
  line-height: 12px;
}
app-message-template .ql-sms-toolbar > .p-editor-container > .ql-toolbar.ql-snow, app-settings-footer .ql-sms-toolbar > .p-editor-container > .ql-toolbar.ql-snow {
  padding: 0;
  border: none;
}
app-message-template .sticky, app-settings-footer .sticky {
  position: fixed;
  top: 30px;
}
app-message-template .editor-container, app-settings-footer .editor-container, app-settings-footer .button-container {
  width: 100%;
  min-width: 800px;
  margin: 0 2rem 1rem 0;
}
app-message-template .has-error, app-settings-footer .has-error {
  color: #f52a29;
}
app-message-template .p-dialog-header, app-settings-footer .p-dialog-header {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
app-message-template .p-dialog-header span, app-settings-footer .p-dialog-header span {
  color: white;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
}

app-template-attribute .attribute-searchbar {
  margin: 0.5rem auto;
}
app-template-attribute .attribute-searchbar .p-input-group {
  margin: 0;
}
app-template-attribute .attribute-container-header {
  margin-bottom: 0.5rem;
  text-align: center;
  margin: auto;
  color: #b3b3b3;
}
app-template-attribute .attribute-container-header span {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
  white-space: nowrap;
  font-size: 0.9rem;
  font-style: italic;
}

.ql-html-popupContainer {
  background: #263551 !important;
  border-radius: 0px !important;
}

.ql-html-textArea {
  padding: 20px 1rem 80px 1rem;
  height: 100% !important;
  left: 0 !important;
  width: 100% !important;
}

.ql-html-popupTitle {
  color: white;
  margin-left: 10px;
  margin-bottom: 10px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.ql-html-textContainer {
  height: 100%;
  text-align: center;
}

.ql-html-buttonGroup {
  bottom: -1.5rem !important;
}

.ql-container {
  font-size: 20px;
}

.htmlButton {
  font-size: 0.8rem;
}

.ql-html-buttonOk,
.html-buttonOk {
  padding: 0.4rem 1.2rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  border-radius: 2px;
}

.ql-html-buttonCancel,
.html-buttonCancel {
  padding: 0.4rem 1.2rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  border-radius: 2px;
}

app-settings-footer .quill-editor {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 0 1rem 1rem 0;
}
app-settings-footer .editor-container, app-settings-footer .button-container {
  width: 100%;
  min-width: 600px;
}
app-settings-footer .button-container {
  margin-right: 15px;
}

.blot-formatter__proxy-image {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

.poster-remove-button {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 16px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3rem;
  text-align: center;
  color: white;
  display: inline-block !important;
  cursor: pointer;
}
.poster-remove-button:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.mydialog {
  width: 60vw;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
  font-size: 0.8rem;
  background-color: #263551;
}
.mydialog textarea {
  resize: none;
}

.ql-formats:hover {
  cursor: pointer;
  color: blueviolet;
}

.poster-wrapper {
  display: inline-flex !important;
  flex-direction: column !important;
  flex: 1 1 auto !important;
  position: relative !important;
  vertical-align: middle !important;
  text-align: center !important;
  width: auto !important;
}

.htmlEditor {
  height: 75vh;
  font-size: 0.9rem;
  padding: 0.5rem;
}

app-conditions-template .conditions-label {
  padding: 0.5rem 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
app-conditions-template .conditions-label .control-label {
  font-size: 0.9rem;
}
app-conditions-template .condition-container {
  background-color: rgba(128, 128, 128, 0.3);
  padding: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
app-conditions-template .no-gutter {
  padding-right: -15px;
  padding-left: -15px;
}
@media (max-width: 1200px) {
  app-conditions-template .no-gutter {
    padding: 0;
  }
}
@media (min-width: 1440px) {
  app-conditions-template .no-margin {
    margin: 0;
  }
}
app-conditions-template .first-order {
  order: 1;
}
@media (max-width: 1200px) {
  app-conditions-template .first-order {
    order: -1;
  }
}
@media (min-width: 1440px) {
  app-conditions-template .no-gutter-left {
    padding-left: 0;
  }
}
@media (max-width: 1440px) and (min-width: 1367px) {
  app-conditions-template .trigger-template.col-xl-4 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}
@media (max-width: 1366px) and (min-width: 1200px) {
  app-conditions-template .trigger-template.col-xl-4 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}
@media (max-width: 1440px) and (min-width: 1200px) {
  app-conditions-template .condition-template.col-xl-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  app-conditions-template .condition-template.col-xl-3 {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }
}

app-table .action-column {
  width: 10rem;
  max-width: 10rem;
}

.app-layout-content {
  position: relative;
}

app-layout-navbar {
  display: block;
}
app-layout-navbar .app-layout-navbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}
app-layout-navbar .app-layout-navbar.settings-navbar {
  justify-content: flex-start;
  overflow-y: auto;
  white-space: nowrap;
}
app-layout-navbar .app-layout-navbar.settings-navbar .nav.nav-pills {
  flex-wrap: nowrap;
}
app-layout-navbar .app-layout-navbar .row {
  flex: 1;
}

app-layout-footer {
  display: block;
}
app-layout-footer .app-layout-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 2rem 0;
}
app-layout-footer .app-layout-footer.settings-navbar {
  justify-content: flex-start;
}
app-layout-footer .app-layout-footer .row {
  flex: 1;
}

.app-layout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}
.app-layout main.main {
  flex: 1;
  min-width: 0;
  padding: 0;
  min-height: 100vh;
  background: #ffffff;
}
.app-layout main.main #mainRoot + * > .wrapper-container > app-layout-content > .app-layout-content {
  padding: 2rem;
  height: 100%;
}
.app-layout main.main .wrapper-container {
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
}
.app-layout main.main .wrapper-container app-layout-content {
  width: 100%;
}

.auth-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.auth-layout .brand {
  flex: 1 1 50%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: url("../../assets/img/Fotolia_100549765_Subscription_Monthly_M.jpg");
  background-size: cover;
  object-fit: cover;
}
.auth-layout main.main {
  flex: 1 1 50%;
  display: flex;
  box-shadow: inset 5px 0 10px 0 rgba(0, 0, 0, 0.24);
  align-items: center;
  position: relative;
  flex-direction: column;
}
.auth-layout main.main .wrapper {
  flex: 1 1 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
}

app-auth-login,
app-wrong-browser {
  width: 400px;
  height: 22rem;
}
app-auth-login .logo-container,
app-wrong-browser .logo-container {
  height: 150px;
  background-image: url("../../assets/img/sc_blue_cinema_primary.svg");
  background-size: contain;
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

app-auth-layout .auth-footer {
  width: 100%;
  padding: 0 0.5rem 0.5rem 0.5rem;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
}
app-auth-layout .auth-footer .logo-container {
  width: 200px;
  height: 50px;
  background-image: url("../../assets/img/lsi-logo.jpg");
  background-size: contain;
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
app-auth-layout .auth-footer .url-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  width: 200px;
}
app-auth-layout .auth-footer .url-container .link {
  color: #4276f2;
}
app-auth-layout .auth-footer .copyright-container {
  width: 200px;
  color: #737373;
  text-align: center;
}

.static-image,
.p-fileupload-row > div > img {
  min-width: unset !important;
  width: 50px !important;
  height: auto !important;
  margin: unset !important;
}

.p-inputtext[readonly] {
  cursor: default;
  opacity: 0.6;
  background-color: #f0f1f2;
}
.p-inputtext[readonly]:focus {
  outline: none;
}

app-create-campaign .create-campaign-column,
app-edit-campaign .create-campaign-column {
  padding: 0.5rem 1rem;
}
app-create-campaign .create-campaign-type,
app-edit-campaign .create-campaign-type {
  padding-top: 0.5rem;
  height: 2rem;
}
app-create-campaign .create-campaign-desc-input,
app-edit-campaign .create-campaign-desc-input {
  min-height: 12rem;
  max-height: 12rem;
}
app-create-campaign .create-campaign-calendar,
app-edit-campaign .create-campaign-calendar {
  display: flex;
  align-items: center;
}
app-create-campaign .campaign-type,
app-edit-campaign .campaign-type {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
}
app-create-campaign .end-date-calendar-container app-form-group,
app-edit-campaign .end-date-calendar-container app-form-group {
  position: relative;
}
app-create-campaign .end-date-calendar-container app-icon,
app-edit-campaign .end-date-calendar-container app-icon {
  cursor: pointer;
  color: #bfbfbf;
  position: absolute;
  right: -2rem;
  top: 0.6rem;
  transform: translate(-50%, 0%);
}
app-create-campaign .end-date-calendar-container app-icon:hover,
app-edit-campaign .end-date-calendar-container app-icon:hover {
  color: #999999;
}
app-create-campaign app-calendar,
app-edit-campaign app-calendar {
  width: 100%;
}
app-create-campaign p-calendar .p-calendar .p-inputtext,
app-edit-campaign p-calendar .p-calendar .p-inputtext {
  min-width: auto;
}
app-create-campaign .p-dropdown-label.p-placeholder,
app-edit-campaign .p-dropdown-label.p-placeholder {
  opacity: 0.5;
}
app-create-campaign .p-selectbutton-statuses .p-selectbutton,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton {
  display: flex;
}
@media (min-width: 992px) {
  app-create-campaign .p-selectbutton-statuses .p-selectbutton,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton {
    align-items: center;
  }
}
@media (max-width: 768px) {
  app-create-campaign .p-selectbutton-statuses .p-selectbutton,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton {
    flex-direction: column;
  }
}
app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button:first-of-type .p-button-label, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:first-of-type .p-button-label,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:first-of-type .p-button-label, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:first-of-type .p-button-label,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:first-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button:first-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:first-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:first-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:first-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:first-of-type .p-button-label {
  padding: 0;
}
app-create-campaign .p-selectbutton-statuses .p-selectbutton .p-button:last-of-type .p-button-label, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:last-of-type .p-button-label,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:last-of-type .p-button-label, app-create-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:last-of-type .p-button-label,
app-create-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:last-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .p-button:last-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonOk:last-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonOk:last-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .ql-html-buttonCancel:last-of-type .p-button-label,
app-edit-campaign .p-selectbutton-statuses .p-selectbutton .html-buttonCancel:last-of-type .p-button-label {
  padding: 0;
}

app-campaign-insights-header .outer-container h2,
app-campaign-insights-header .outer-container h3 {
  margin: 0;
}
@media (min-width: 992px) {
  app-campaign-insights-header .outer-container h2 {
    font-size: 2rem;
  }
  app-campaign-insights-header .outer-container h3 {
    font-size: 1.5rem;
  }
}
app-campaign-insights-header .inner-container {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 0.5rem;
}
app-campaign-insights-header .inner-container .col-3 {
  text-align: center;
}
@media (max-width: 1440px) {
  app-campaign-insights-header .inner-container .col-7 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}
app-campaign-insights-header .container-box .inner-container {
  padding-bottom: unset;
}
app-campaign-insights-header .container-box .inner-container-blue {
  color: #4276f2;
}
app-campaign-insights-header .container-box .inner-container-green {
  color: #23ae5d;
}
app-campaign-insights-header .container-box .col-3 {
  max-width: 33.3333333333%;
}
@media (max-width: 1440px) and (min-width: 1200px) {
  app-campaign-insights-header .container-box .col-3 {
    max-width: 42%;
  }
}
@media (max-width: 1199.98px) {
  app-campaign-insights-header .container-box .col-3 {
    flex: 0;
    max-width: 62%;
  }
}
@media (min-width: 992px) {
  app-campaign-insights-header .container-box .col-3 h3 {
    font-size: 1.8rem;
  }
}
app-campaign-insights-header .container-box .col-3:last-of-type {
  align-self: flex-end;
}

app-campaign-insights-table .container-box {
  height: 100%;
}
app-campaign-insights-table .container-box .row-space {
  margin-bottom: 0.5rem;
}
app-campaign-insights-table .container-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
app-campaign-insights-table .container-header {
  margin-bottom: 0.5rem;
}
app-campaign-insights-table .text-wrapping {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

app-campaign-insights .insights-navbar {
  font-size: 2rem;
  justify-content: flex-start;
}
app-campaign-insights .container-box {
  border: 1px solid #d9d9d9;
  padding: 0.5rem;
}
app-campaign-insights .insights-container {
  margin-bottom: 1rem;
}
app-campaign-insights .insights-container .insights-special-space.col-6 {
  padding-left: 2rem;
}
app-campaign-insights .insights-width {
  width: 100%;
}
app-campaign-insights .inner-space {
  min-height: 12rem;
}
app-campaign-insights .insights-text {
  color: #737373;
}

app-change-logs app-table .date-column .p-input-group {
  max-width: fit-content;
}

app-campaigns app-table .campaign-name-col {
  width: 15rem;
  max-width: 15rem;
  min-width: 15rem;
}
app-campaigns app-table .campaign-col {
  width: 10rem;
  max-width: 10rem;
}
app-campaigns app-table .p-contextmenu {
  width: 7rem;
  transform: translate(-25%, 0);
  white-space: nowrap;
}
app-campaigns app-table .contextmenu-cell {
  text-align: center;
}
app-campaigns app-table .text-wrapping {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (min-width: 1440px) {
  app-campaigns app-table .text-wrapping {
    white-space: nowrap;
  }
}

app-group-template .attributes-container {
  justify-content: center;
  flex-direction: column;
}
app-group-template .group-nested-rule-item {
  flex-direction: column;
}
app-group-template .rule-comparator.col-1 {
  display: flex;
  height: min-content;
  padding: 0.5rem 0;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
}
app-group-template .rule-buttons.col-1,
app-group-template .rule-single-button.col-1 {
  padding: 0;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
app-group-template .rule-buttons.col-1 .ui-button,
app-group-template .rule-single-button.col-1 .ui-button {
  padding: 0.25rem 0;
}
app-group-template .rule-single-button.col-1 {
  padding: 0.5rem 0;
}
app-group-template .rule-child-container {
  flex-direction: column;
}
app-group-template .rule-icon-disabled {
  pointer-events: none;
  opacity: 0.3;
}
app-group-template .marketing-permission-container {
  display: flex;
  align-items: flex-start;
}
app-group-template .marketing-permission-container label {
  margin-left: 1rem;
}
app-group-template .group-attribute-container {
  display: flex;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.3);
  margin: 1rem 0;
  padding: 1rem 0;
}
app-group-template .group-attribute-container .p-selectbutton .p-button.p-highlight, app-group-template .group-attribute-container .p-selectbutton .p-highlight.ql-html-buttonOk,
app-group-template .group-attribute-container .p-selectbutton .p-highlight.html-buttonOk, app-group-template .group-attribute-container .p-selectbutton .p-highlight.ql-html-buttonCancel,
app-group-template .group-attribute-container .p-selectbutton .p-highlight.html-buttonCancel {
  background-color: gainsboro;
  color: black;
  border-color: black;
  border-width: 0px !important;
}
app-group-template .group-attribute-container .p-selectbutton .p-button.p-highlight:hover, app-group-template .group-attribute-container .p-selectbutton .p-highlight.ql-html-buttonOk:hover,
app-group-template .group-attribute-container .p-selectbutton .p-highlight.html-buttonOk:hover, app-group-template .group-attribute-container .p-selectbutton .p-highlight.ql-html-buttonCancel:hover,
app-group-template .group-attribute-container .p-selectbutton .p-highlight.html-buttonCancel:hover {
  border-width: 0px !important;
}
app-group-template .no-padding {
  padding: 0 !important;
}

app-group-rule app-form-group .p-inputtext {
  height: 80%;
}

app-messages .contextmenu-cell {
  text-align: center;
}
app-messages .column-action {
  width: 6rem;
  text-align: center;
}

app-settings-button .image-container,
app-settings-notification .image-container {
  width: 16rem;
  height: 11rem;
  border: 1.5px solid #bfbfbf;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 0.75rem;
}
app-settings-button .image-container .image-header,
app-settings-notification .image-container .image-header {
  font-size: 0.8rem;
  position: relative;
  height: calc(2.5rem - 0.75rem);
}
app-settings-button .image-container .image-footer,
app-settings-notification .image-container .image-footer {
  font-size: 0.8rem;
  position: relative;
  height: 1rem;
}
app-settings-button .image-container .image-footer .image-error,
app-settings-notification .image-container .image-footer .image-error {
  color: red;
}
app-settings-button .image-container .inner-container,
app-settings-notification .image-container .inner-container {
  background-color: #f0f1f2;
  position: relative;
  text-align: center;
  vertical-align: bottom;
  width: 100%;
  height: calc(10rem - (0.75rem * 2));
}
app-settings-button .image-container .inner-container .fully-cover, app-settings-button .image-container .inner-container:hover .overlay span,
app-settings-button .image-container .inner-container .overlay:hover span,
app-settings-notification .image-container .inner-container .fully-cover,
app-settings-notification .image-container .inner-container:hover .overlay span,
app-settings-notification .image-container .inner-container .overlay:hover span {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
}
app-settings-button .image-container .inner-container .overlay,
app-settings-notification .image-container .inner-container .overlay {
  position: absolute;
  bottom: 0;
  height: 2rem;
  width: 100%;
  opacity: 0.9;
  cursor: pointer;
}
app-settings-button .image-container .inner-container .overlay span,
app-settings-notification .image-container .inner-container .overlay span {
  display: none;
}
app-settings-button .image-container .inner-container:hover .overlay,
app-settings-button .image-container .inner-container .overlay:hover,
app-settings-notification .image-container .inner-container:hover .overlay,
app-settings-notification .image-container .inner-container .overlay:hover {
  border: solid 1px #fafbfc;
  background-color: #f0f1f2;
}
app-settings-button .image-container .inner-container .label-container,
app-settings-notification .image-container .inner-container .label-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
app-settings-button .image-container .inner-container img,
app-settings-notification .image-container .inner-container img {
  width: 100px;
  height: auto;
  vertical-align: bottom;
  cursor: pointer;
}
app-settings-button .image-container .inner-container input[type=file],
app-settings-notification .image-container .inner-container input[type=file] {
  display: none;
}
app-settings-button .image-container .inner-container i,
app-settings-notification .image-container .inner-container i {
  display: inline-block;
}
app-settings-button .image-container .image-header + .inner-container,
app-settings-notification .image-container .image-header + .inner-container {
  height: calc(10rem - 2.5rem - 0.75rem);
}
app-settings-button .right-positioned,
app-settings-notification .right-positioned {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}
app-settings-button .left-positioned,
app-settings-notification .left-positioned {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}
app-settings-button .left-positioned > span:first-child,
app-settings-notification .left-positioned > span:first-child {
  cursor: pointer;
}
app-settings-button .ui-dialog p,
app-settings-notification .ui-dialog p {
  white-space: normal;
}

app-settings-user th,
app-settings-user td {
  width: 15rem;
}
app-settings-user th.column-action,
app-settings-user td.column-action {
  width: 7rem;
}
app-settings-user .custom-input {
  margin-bottom: 0.5rem;
}

app-settings-footer p-editor .p-editor-container {
  max-width: unset;
  width: unset;
}
app-settings-footer div[contenteditable] {
  background-color: #ffffff;
  min-height: 30rem;
  overflow-wrap: break-word;
  border: 1px solid #ccc;
  border-top: none;
}
app-settings-footer .ql-toolbar {
  border: 1px solid #ccc;
}
app-settings-footer .ql-container {
  font-size: 20px;
  border: none !important;
}
app-settings-footer .message-container {
  overflow: auto hidden;
}

app-settings-general .control-title span {
  display: block;
}
app-settings-general .control-label {
  padding: 0.5rem;
  text-align: center;
}

app-dashboard app-layout-navbar .app-layout-navbar {
  margin-bottom: 1rem;
}
app-dashboard .row {
  min-height: 90vh;
  box-shadow: 0 1px 5px 0 rgba(85, 85, 85, 0.2);
  text-align: center;
}
app-dashboard .col {
  padding: 0;
  box-shadow: 0 1px 5px 0 rgba(85, 85, 85, 0.2);
  max-height: 90vh;
}
app-dashboard .col:nth-child(2n) {
  box-shadow: none;
}
app-dashboard .col .title {
  padding: 0.5rem;
}
app-dashboard .col .title--active {
  color: #23ae5d;
}
app-dashboard .col .title--draft {
  color: #f52a29;
}
app-dashboard .col .title--finished {
  color: #000;
}
app-dashboard .col .title--hold {
  color: #ffc107;
}
app-dashboard .campaign-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 85vh;
  overflow: auto;
}
@media (min-width: 992px) {
  app-dashboard .campaign-container.no-content {
    min-height: 80vh;
  }
}
@media (max-width: 991px) {
  app-dashboard .campaign-container.no-content {
    min-height: 10vh;
  }
}
app-dashboard .campaign-container .campaign-content {
  margin: 0.5rem;
}

app-campaign-information .p-card {
  width: 12rem;
  min-width: 12rem;
  max-width: 12rem;
}
app-campaign-information .p-card .p-card-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
app-campaign-information .p-card .p-card-content .content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
app-campaign-information .p-card .p-card-content .content-container:first-child .content:first-child, app-campaign-information .p-card .p-card-content .content-container:nth-child(2) .content:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9rem;
  white-space: nowrap;
}
app-campaign-information .p-card .p-card-content .content-container:last-of-type {
  justify-content: flex-start;
}
app-campaign-information .p-card .p-card-content .content-container:last-of-type .content:last-child {
  margin-left: auto;
}
app-campaign-information .p-card .p-card-content .content-container .content {
  font-size: 0.8rem;
}
app-campaign-information .p-card .p-card-content .content-container .content.content-title {
  font-size: 14px;
  font-weight: 600;
}
app-campaign-information .p-card .p-card-content .content-container .content.p-button-icon {
  padding: 0 0.2rem 0 0;
}

.activation-form {
  border: solid 1px;
}