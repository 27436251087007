p-editor {
  .ql-container {
    font-size: 20px;
  }

  .p-editor-content {
    min-height: 25rem;

    .ql-editor {
      min-height: inherit;
    }

    em[datatype] {
      user-select: none;
    }
  }
  .p-editor-container {
    width: 1000px;
    max-width: 1000px;
    min-width: 1000px;
  }

  &.is-invalid {
    .p-editor-container {
      border: 1px solid $danger;
    }

    & + .p-inline-message {
      &.p-inline-message-error {
        color: $danger;

        .p-inline-message-text {
          font-size: $font-size-xs;
        }
      }
    }
  }
}
