@import '~primeng/resources/components/button/button';

.p-button {
  display: inline-block !important;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-transform: uppercase;
  color: $black;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-line-height,
    $btn-border-radius
  ); //breaks p select button corners
  @include transition($btn-transition);

  @include hover() {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &.p-calendar-button {
    box-shadow: none;
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):not(.ui-calendar-button) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

    &:active,
    &.active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }

  &-icon {
    padding: $btn-padding-y;

    &.p-button-sm {
      padding: $btn-padding-y-sm;
    }

    &.p-button-lg {
      padding: $btn-padding-y-lg;
    }
  }

  span,
  &-sm,
  &-lg {
    padding: 0 0.5rem;
  }
}

@each $color, $value in $theme-schemas {
  .p-button-#{$color} {
    @include button-variant(map-get($value, bg-start), map-get($value, bg-end), map-get($value, color));
  }
}

@each $color, $value in $theme-colors {
  .p-button-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .p-button-icon-#{$color} {
    @include button-icon-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .p-button-outline-icon-#{$color} {
    @include button-outline-icon-variant($value);
  }
}

.btn-small {
  height: 40px !important;
}