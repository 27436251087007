@import 'shared/layout/aside';
@import 'shared/layout/content';
@import 'shared/layout/navbar';
@import 'shared/layout/footer';

.app-layout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  main.main {
    flex: 1;
    min-width: 0;
    padding: 0;
    min-height: 100vh;
    background: $body-bg;

    #mainRoot + * {
      & > .wrapper-container {
        & > app-layout-content > .app-layout-content {
          padding: 2rem;
          height: 100%;
        }
      }
    }

    .wrapper-container {
      padding: 0;
      position: relative;
      height: 100%;
      width: 100%;
      margin: 0;
      min-height: 100vh;
      display: flex;
      justify-content: flex-start;

      app-layout-content {
        width: 100%;
      }
    }
  }
}

.auth-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .brand {
    flex: 1 1 50%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center right;
    // background-image: url('../../assets/img/1.png');
    // background-image: url('../../assets/img/felix-mooneeram-222805-unsplash.jpg');
    background-image: url('../../assets/img/Fotolia_100549765_Subscription_Monthly_M.jpg');
    // background-image: url('../../assets/img/jonatan-moerman-4nUQH0EXzfg-unsplash.jpg');
    // background-image: url('../../assets/img/kilyan-sockalingum-478724-unsplash (1).jpg');
    background-size: cover;
    object-fit: cover;
  }

  main.main {
    flex: 1 1 50%;
    display: flex;
    box-shadow: inset 5px 0 10px 0 rgba(0, 0, 0, 0.24);
    align-items: center;
    position: relative;
    flex-direction: column;

    .wrapper {
      flex: 1 1 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;
    }
  }
}

app-auth-login,
app-wrong-browser {
  width: 400px;
  height: 22rem;

  .logo-container {
    height: 150px;
    background-image: url('../../assets/img/sc_blue_cinema_primary.svg');
    background-size: contain;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

app-auth-layout {
  .auth-footer {
    width: 100%;
    padding: 0 0.5rem 0.5rem 0.5rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;

    .logo-container {
      width: 200px;
      height: 50px;
      background-image: url('../../assets/img/lsi-logo.jpg');
      background-size: contain;
      object-fit: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .url-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
      width: 200px;

      .link {
        color: $blue;
      }
    }

    .copyright-container {
      width: 200px;
      color: $gray-800;
      text-align: center;
    }
  }
}

.static-image,
.p-fileupload-row > div > img {
  min-width: unset !important;
  width: 50px !important;
  height: auto !important;
  margin: unset !important;
}

.p-inputtext[readonly] {
  cursor: default;
  opacity: 0.6;
  background-color: $gray-200;

  &:focus {
    outline: none;
  }
}
