.p-tooltip {
  position: absolute;
  display: none;
  max-width: $ui-tooltip-max-width;
  font-size: $ui-tooltip-font-size;
  z-index: 3000 !important;

  .p-tooltip-text {
    @include reset-text();
    @include font-size($ui-tooltip-font-size);
    word-wrap: break-word;
    background-color: $ui-tooltip-bg;
    background-clip: padding-box;
    border: $ui-tooltip-border-width solid $ui-tooltip-border-color;
    @include border-radius($ui-tooltip-border-radius);
    @include box-shadow($ui-tooltip-box-shadow);
    padding: $ui-tooltip-body-padding-y $ui-tooltip-body-padding-x;
    color: $ui-tooltip-color;
  }

  .p-tooltip-arrow {
    position: absolute;
    display: block;
    width: $ui-tooltip-arrow-width;
    height: $ui-tooltip-arrow-height;
    margin: 0;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }

  &.p-tooltip-right,
  &.p-tooltip-left {
    > .p-tooltip-arrow {
      top: 50%;
      margin-top: -$ui-tooltip-arrow-height / 2;
    }
  }

  &.p-tooltip-top,
  &.p-tooltip-bottom {
    > .p-tooltip-arrow {
      left: 50%;
      margin-left: -$ui-tooltip-arrow-width / 2;
    }
  }

  &.p-tooltip-right {
    > .p-tooltip-text {
      margin-left: $ui-tooltip-arrow-width;
    }
    > .p-tooltip-arrow {
      left: 0;

      &::before {
        left: 0;
        border-width: ($ui-tooltip-arrow-width / 2) $ui-tooltip-arrow-height ($ui-tooltip-arrow-width / 2) 0;
        border-right-color: $ui-tooltip-arrow-outer-color;
      }

      &::after {
        left: $ui-tooltip-border-width;
        border-width: ($ui-tooltip-arrow-width / 2) $ui-tooltip-arrow-height ($ui-tooltip-arrow-width / 2) 0;
        border-right-color: $ui-tooltip-arrow-color;
      }
    }
  }

  &.p-tooltip-left {
    > .p-tooltip-text {
      margin-right: $ui-tooltip-arrow-width;
    }

    > .p-tooltip-arrow {
      right: 0;
      &::before {
        right: 0;
        border-width: ($ui-tooltip-arrow-width / 2) 0 ($ui-tooltip-arrow-width / 2) $ui-tooltip-arrow-height;
        border-left-color: $ui-tooltip-arrow-outer-color;
      }
      &::after {
        right: $ui-tooltip-border-width;
        border-width: ($ui-tooltip-arrow-width / 2) 0 ($ui-tooltip-arrow-width / 2) $ui-tooltip-arrow-height;
        border-left-color: $ui-tooltip-arrow-color;
      }
    }
  }

  &.p-tooltip-top {
    > .p-tooltip-text {
      margin-bottom: $ui-tooltip-arrow-height;
    }

    > .p-tooltip-arrow {
      bottom: 0;
      &::before {
        bottom: 0;
        border-width: $ui-tooltip-arrow-height ($ui-tooltip-arrow-width / 2) 0;
        border-top-color: $ui-tooltip-arrow-outer-color;
      }
      &::after {
        bottom: $ui-tooltip-border-width;
        border-width: $ui-tooltip-arrow-height ($ui-tooltip-arrow-width / 2) 0;
        border-top-color: $ui-tooltip-arrow-color;
      }
    }
  }

  &.p-tooltip-bottom {
    > .p-tooltip-text {
      margin-top: $ui-tooltip-arrow-height;
    }

    > .p-tooltip-arrow {
      top: 0;
      &::before {
        top: 0;
        border-width: 0 ($ui-tooltip-arrow-width / 2) $ui-tooltip-arrow-height ($ui-tooltip-arrow-width / 2);
        border-bottom-color: $ui-tooltip-arrow-outer-color;
      }
      &::after {
        top: $ui-tooltip-border-width;
        border-width: 0 ($ui-tooltip-arrow-width / 2) $ui-tooltip-arrow-height ($ui-tooltip-arrow-width / 2);
        border-bottom-color: $ui-tooltip-arrow-color;
      }
    }
  }
}
