app-settings-footer {
  p-editor .p-editor-container {
    max-width: unset;
    width: unset;
  }

  div[contenteditable] {
    background-color: $white;
    min-height: 30rem;
    overflow-wrap: break-word;
    border: 1px solid #ccc;
    border-top: none;
  }

  .ql-toolbar {
    border: 1px solid #ccc;
  }

  .ql-container {
    font-size: 20px;
    border: none !important;
  }

  .message-container {
    overflow: auto hidden;
  }
}
