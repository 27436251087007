@import '~primeng/resources/components/chips/chips';

p-chips {
  .p-inputtext {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .p-chips-token {
    //@extend .badge;
    font-size: 100%;
    font-weight: $font-weight-normal;
    background-color: $gray-400;
    margin: 0 0.2rem;
  }

  .p-chips-token-label {
    color: $gray-100;
  }

  .p-chips-token-icon {
    margin: 0 0.5rem 0 0;
    color: $gray-100;
  }

  .p-chips-input-token {
    list-style-type: none;
    padding-right: calc(#{$input-height} + 5px);

    input {
      border: none;

      &:focus {
        outline: none;
      }

      &:disabled {
        display: none;
      }
    }
  }
}
