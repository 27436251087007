app-message-template, app-settings-footer {
  .message-container {
    overflow: auto;
    flex-wrap: nowrap;
  }

  div[contenteditable] {
    background-color: $white;
    min-height: 30rem;
    overflow-wrap: break-word;
  }

  .ql-label-placeholder {
    color: blue;
    user-select: none;
    white-space: nowrap;
  }
  

  .ql-image-placeholder {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: transparent;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
  }

  .ql-sms-toolbar {
    & > .ql-container {
      border-top: 1px solid #ccc !important;
    }

    & > .ql-toolbar {
      display: none;
    }
  }

  button[title='HTML'] {
    line-height: 12px;
  }

  .ql-sms-toolbar > .p-editor-container > .ql-toolbar.ql-snow {
    padding: 0;
    border: none;
  }

  .sticky {
    position: fixed;
    top: 30px;
  }

  .editor-container {
    width: 100%;
    min-width: 800px;
    margin: 0 2rem 1rem 0;
  }

  .has-error {
    color: $red;
  }

  .p-dialog-header {
    display:flex;
    justify-content:center;
    padding-top:1rem;
    span {
      color:white;
      text-align: center;
      font-size: 1rem;
      text-transform: uppercase;
    }
  }

}

app-template-attribute {
  .attribute-searchbar {
    margin: 0.5rem auto;

    .p-input-group {
      margin: 0;
    }
  }

  .attribute-container-header {
    margin-bottom: 0.5rem;
    text-align: center;
    margin: auto;
    color: $gray-400;

    span {
      display: inline-block;
      width: 100%;
      margin-bottom: 1rem;
      white-space: nowrap;
      font-size: 0.9rem;
      font-style: italic;
    }
  }
}

.ql-html-popupContainer {
  background: $primary !important;
  border-radius: 0px !important;
}

.ql-html-textArea {
  padding: 20px 1rem 80px 1rem;
  height: 100% !important;
  left: 0 !important;
  width: 100% !important;
}

.ql-html-popupTitle {
  color: white;
  margin-left: 10px;
  margin-bottom: 10px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.ql-html-textContainer {
  height: 100%;
  text-align: center;
}

.ql-html-buttonGroup {
  bottom: -1.5rem !important;
}

.ql-container {
  font-size: 20px;
}

.htmlButton {
  font-size: 0.8rem;
}

.ql-html-buttonOk,
.html-buttonOk {
  @extend .p-button-success;
  @extend .p-button;
  padding: 0.4rem 1.2rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  border-radius: 2px;
}

.ql-html-buttonCancel,
.html-buttonCancel {
  @extend .p-button-secondary;
  @extend .p-button;
  padding: 0.4rem 1.2rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  border-radius: 2px;
}

app-settings-footer {
  .quill-editor {
    width: 100%;
    height: 100%;
    display: inline-block;
    margin: 0 1rem 1rem 0;
  }

  .editor-container {
    width: 100%;
    min-width: 600px;
    // margin: 0 0 1rem 0;
  }

  .button-container {
    @extend .editor-container;
    margin-right: 15px;
  }
}

.blot-formatter__proxy-image {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

.poster-remove-button {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 16px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3rem;
  text-align: center;
  color: white;
  display: inline-block !important;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.mydialog {
  width: 60vw;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
  font-size: 0.8rem;
  background-color:$primary;

  textarea {
    resize: none;
  }
}

.ql-formats:hover {
  cursor: pointer;
  color: blueviolet;
}

.poster-wrapper {
  display: inline-flex !important;
  flex-direction: column !important;
  flex: 1 1 auto !important;
  position: relative !important;
  vertical-align: middle !important;
  text-align: center !important;
  width: auto !important;
}

.htmlEditor {
  height: 75vh;
  font-size:0.9rem;
  padding:0.5rem;


}
