.p-overlaypanel {
  //@extend .card-shadowed;
  position: absolute;
  background-color: $gray-100;
  color: $body-color;
  padding: 0;
  border: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;

  &:before {
    border-color: $gray-100;
    border-bottom-color: #c8c8c8;
    border-width: 10px;
  }

  &:before,
  &:after {
    top: 100%;
    left: 10rem;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $gray-100;
    border-width: 8px;
    margin-left: -8px;
  }

  .p-overlaypanel-content {
    padding: 1rem;
  }
  .p-overlaypanel-close {
    position: absolute;
    top: 0;
    right: 0;
  }
}
