@import '~primeng/resources/components/inputswitch/inputswitch';

.p-inputswitch {
  width: 3rem;
  height: #{$input-line-height-sm}rem;
  position: relative;
  display: inline-block;

  .p-inputswitch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    background: $gray-200;

    &:before {
      position: absolute;
      content: '';
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background-color: $white;
      left: 0.25rem;
      bottom: 0.25rem;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
  }

  &.p-inputswitch-checked {
    @include inputswitch-color($success);

    .p-inputswitch-slider {
      &:before {
        -webkit-transform: translateX(1.5em);
        -ms-transform: translateX(1.5em);
        transform: translateX(1.5em);
        background-color: $white;
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .p-inputswitch-#{$color} {
    @include inputswitch-color($value);
  }
}
