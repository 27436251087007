a:hover {
  text-decoration: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: $gray-500;
  border-radius: 30px;
}

html:not([dir='rtl']) {
  @include media-breakpoint-down(xs) {
    .sidebar {
      margin-left: -$sidebar-width;
    }
  }

  .aside-menu {
    right: 0;
    margin-right: -$aside-menu-width;
  }
}

html[dir='rtl'] {
  .sidebar {
    margin-right: -$sidebar-width;
  }

  .aside-menu {
    left: 0;
    margin-left: -$aside-menu-width;
  }
}

@include media-breakpoint-up(md) {
  //
  // Header
  //
  .header-fixed {
    .app-header {
      position: fixed;
      z-index: $zindex-sticky;
      width: 100%;
    }

    .app-body {
      margin-top: $navbar-height;
    }
  }
  //
  // Sidebar
  //
  .sidebar-fixed {
    .sidebar {
      position: fixed;
      z-index: $zindex-sticky - 20;
      width: $sidebar-width;
      height: 100vh;
    }

    // &:not(.sidebar-minimized) {
    //   .drag-drop-header,
    //   .drag-drop-nav,
    //   .drag-drop-footer {
    //     width: 80%;
    //   }

    //   #rightcard {
    //     right: 5rem;
    //   }
    // }
  }
  .sidebar-fixed {
    .app-header + .app-body .sidebar {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .sidebar-compact {
    .sidebar {
      flex: 0 0 $sidebar-compact-width;
    }

    &.sidebar-fixed {
      .sidebar {
        width: $sidebar-compact-width;
      }
    }

    .sidebar-minimizer {
      display: none;
    }
  }

  .sidebar-minimized {
    .sidebar {
      flex: 0 0 $sidebar-minimized-width;
    }

    &.sidebar-fixed {
      .sidebar {
        width: $sidebar-minimized-width;
      }
    }
  }

  .sidebar-off-canvas {
    .sidebar {
      position: fixed;
      z-index: $zindex-sticky - 1;
      height: 100%;
    }
  }

  .sidebar-off-canvas {
    .app-header + .app-body .sidebar {
      height: calc(100vh - #{$navbar-height});
    }
  }

  html:not([dir='rtl']) {
    .sidebar-compact {
      .sidebar {
        margin-left: -$sidebar-compact-width;
      }
    }

    .sidebar-minimized {
      .sidebar {
        margin-left: -$sidebar-minimized-width;
      }
    }
  }
  html[dir='rtl'] {
    .sidebar-compact {
      .sidebar {
        margin-right: -$sidebar-compact-width;
      }
    }

    .sidebar-minimized {
      .sidebar {
        margin-right: -$sidebar-minimized-width;
      }
    }
  }

  //
  // Aside Menu
  //
  .aside-menu-fixed {
    .aside-menu {
      position: fixed;
      height: 100%;

      .tab-content {
        height: calc(100vh - #{$aside-padding-y * 2 + $font-size-base} - #{$navbar-height});
      }
    }
  }

  .aside-menu-fixed {
    .app-header + .app-body .aside-menu {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .aside-menu-off-canvas {
    .aside-menu {
      position: fixed;
      z-index: $zindex-sticky - 1;
      height: 100%;
    }
  }

  .aside-menu-off-canvas {
    .app-header + .app-body .aside-menu {
      height: calc(100vh - #{$navbar-height});
    }
  }

  html:not([dir='rtl']) {
    .aside-menu-fixed,
    .aside-menu-off-canvas {
      .aside-menu {
        right: 0;
      }
    }
  }
  html[dir='rtl'] {
    .aside-menu-fixed,
    .aside-menu-off-canvas {
      .aside-menu {
        left: 0;
      }
    }
  }
}

//
// Breadcrumb
//
.breadcrumb-fixed {
  .main {
    $breadcrumb-height: 2 * $breadcrumb-padding-y + $font-size-base + 1.5 * $spacer;
    padding-top: $breadcrumb-height;
  }

  .breadcrumb {
    position: fixed;
    top: $navbar-height;
    right: 0;
    left: 0;
    z-index: $zindex-sticky - 3;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    html:not([dir='rtl']) {
      .sidebar#{$infix}-show,
      .sidebar-show {
        .sidebar {
          margin-left: 0;
        }

        @if $breakpoint != 'xs' {
          &.sidebar-fixed {
            .main,
            .app-footer {
              margin-left: $sidebar-width;
            }

            &.sidebar-compact {
              .main,
              .app-footer {
                margin-left: $sidebar-compact-width;
              }
            }

            &.sidebar-minimized {
              .main,
              .app-footer {
                margin-left: if(
                  $breakpoint != 'lg' and $breakpoint != 'xl' and $breakpoint != 'md',
                  $sidebar-width,
                  $sidebar-minimized-width
                );
              }
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              left: $sidebar-width;
            }

            &.sidebar-compact {
              .breadcrumb {
                left: $sidebar-compact-width;
              }
            }

            &.sidebar-minimized {
              .breadcrumb {
                left: $sidebar-minimized-width;
              }
            }
          }
        }
      }

      .aside-menu-show,
      .aside-menu#{$infix}-show {
        .aside-menu {
          margin-right: 0;
        }

        @if $breakpoint != 'xs' {
          &.aside-menu-fixed {
            .main,
            .app-footer {
              margin-right: $aside-menu-width;
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              right: $aside-menu-width;
            }
          }
        }
      }
    }

    html[dir='rtl'] {
      .sidebar#{$infix}-show,
      .sidebar-show {
        .sidebar {
          margin-right: 0;
        }

        @if $breakpoint != 'xs' {
          &.sidebar-fixed {
            .main,
            .app-footer {
              margin-right: $sidebar-width;
            }

            &.sidebar-compact {
              .main,
              .app-footer {
                margin-right: $sidebar-compact-width;
              }
            }

            &.sidebar-minimized {
              .main,
              .app-footer {
                margin-right: if(
                  $breakpoint != 'lg' and $breakpoint != 'xl' and $breakpoint != 'md',
                  $sidebar-width,
                  $sidebar-minimized-width
                );
              }
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              right: $sidebar-width;
            }

            &.sidebar-compact {
              .breadcrumb {
                right: $sidebar-compact-width;
              }
            }

            &.sidebar-minimized {
              .breadcrumb {
                right: $sidebar-minimized-width;
              }
            }
          }
        }
      }

      .aside-menu-show,
      .aside-menu#{$infix}-show {
        .aside-menu {
          margin-left: 0;
        }

        @if $breakpoint != 'xs' {
          &.aside-menu-fixed {
            .main,
            .app-footer {
              margin-left: $aside-menu-width;
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              left: $aside-menu-width;
            }
          }
        }
      }
    }

    .sidebar#{$infix}-show,
    .aside-menu#{$infix}-show {
      @keyframes opacity {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @if $breakpoint == 'xs' {
        @include media-breakpoint-down(xs) {
          .main {
            position: relative;

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              z-index: $zindex-sticky - 2;
              width: 100%;
              height: 100%;
              content: '';
              background: rgba(0, 0, 0, 0.7);
              animation: opacity $layout-transition-speed;
            }
          }
        }
      }
    }
  }
}

//
// Footer
//
.footer-fixed {
  .app-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky;
    height: $footer-height;
  }

  .app-body {
    margin-bottom: $footer-height;
  }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed,
    width $layout-transition-speed, flex $layout-transition-speed;
}

.sidebar-nav {
  transition: width $layout-transition-speed;
}

.breadcrumb {
  transition: left $layout-transition-speed, right $layout-transition-speed, width $layout-transition-speed;
}

//
// Mobile layout
//
@include media-breakpoint-down(sm) {
  .app-header {
    position: fixed;
    z-index: $zindex-sticky;
    width: 100%;
    text-align: center;
    @if $navbar-brand-bg == transparent {
      background-color: $navbar-bg;
    } @else {
      background-color: $navbar-brand-bg;
    }
    //@include borders($navbar-brand-border);

    .navbar-toggler {
      @if (lightness($navbar-brand-bg) > 40) {
        color: $navbar-color;
      } @else {
        color: #fff;
      }
    }

    .navbar-brand {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -($navbar-brand-width / 2);
    }
  }

  .app-body {
    margin-top: $navbar-height;
  }

  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    width: $sidebar-width;
    height: calc(100vh - #{$navbar-height});
  }

  .sidebar-minimizer {
    display: none;
  }

  .aside-menu {
    position: fixed;
    height: 100%;
  }
}
