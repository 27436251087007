.p-inputtext {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;

  .p-inputtext-sm {
    padding: $input-padding-y-sm $input-padding-x-sm;
    border-radius: $input-border-radius-sm;
  }

  .p-inputtext-lg {
  }

  &.is-invalid {
    border: 1px solid $red;

    &.custom-input + ng-component {
      .p-inline-message-error {
        color: $danger;
        position: absolute;
        bottom: 0;
        /*
        I don't know what I'm doing...
         */
        @include media-breakpoint-down(lg) {
          left: -90px;
        }
        @include media-breakpoint-down(md) {
          left: -150px;
        }

        .p-inline-message-text {
          font-size: $font-size-xxs;
          white-space: nowrap;
        }
      }
    }
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}
