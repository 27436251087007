@import '~primeng/resources/components/calendar/calendar';

p-calendar {
  display: block;

  .p-calendar {
    font-size: $font-size-sm;
    border: $ui-calendar-border;
    border-radius: $ui-calendar-border-radius;
    background: $ui-calendar-bg;
    position: relative;
    display: flex;
    justify-content: space-between;

    .p-inputtext {
      border: none;
      border-radius: unset;
    }

    &.p-calendar-w-btn {
      .p-button-text {
        display: none;
      }

      .p-calendar-button {
        font-size: $font-size-sm;
        height: auto;
        cursor: pointer;
        //padding: $ui-calendar-padding-y $ui-calendar-padding-y $ui-calendar-padding-y 0;
      }

      .p-button-icon-left:focus {
        outline: none;
      }
    }

    .p-datepicker-multiple-month {
      display: flex;
      justify-content: flex-end;

      .p-datepicker-group {
        background: $input-bg;
        padding: 20px 15px;
        border-top: $input-border !important;
        border-bottom: $input-border !important;
      }

      .p-datepicker-group:last-of-type {
        border-right: $input-border !important;
      }

      p-footer {
        display: flex;
        flex-direction: column;
        order: -1;
        background-color: $white;
        padding: 2px 10px;
        height: auto;
        width: auto;
        min-width: 150px;
        justify-content: space-around;
        border-right-width: 0;
      }
    }

    .p-datepicker {
      width: auto;
      min-width: 100%;
      background-color: $ui-calendar-bg;

      &-inline {
        display: block;
        position: static;
      }

      &.p-datepicker-timeonly {
        .p-timepicker {
          & > div {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
          }

          button {
            color: $gray-300;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            align-content: center;

            &:last-child {
              margin-top: 0.2rem;
            }
          }

          span {
            font-size: $font-size-sm;
          }
        }
      }

      .p-datepicker-group {
        border-width: 0;

        .p-datepicker-header {
          border-bottom-width: 0;
          padding: $input-padding-y $input-padding-x;
          background: transparent;
          position: relative;
          color: $gray-base;

          .p-datepicker-prev,
          .p-datepicker-next {
            top: $input-padding-y;
            font-size: $font-size-sm;
            cursor: pointer;
            border: 0 none;
            width: 2rem;
            height: 2rem;
            color: $gray-base;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &:enabled:hover {
              color: $gray-base;
              border-color: transparent;
              background: $gray-200;
            }
          }

          .p-datepicker-prev {
            position: absolute;
            left: $input-padding-x;
          }

          .p-datepicker-title {
            line-height: 2rem;
            margin: 0 auto;
            text-align: center;

            .p-datepicker-month {
              margin: 0.5rem;
            }

            .p-datepicker-year {
              padding-left: 0.125rem;
            }
          }

          .p-datepicker-next {
            position: absolute;
            right: $input-padding-x;
          }
        }

        .p-datepicker-calendar-container {
          table.p-datepicker-calendar {
            border: $input-border;
            border-top-left-radius: 0;
            width: 100%;
            border-collapse: inherit;
            background: $input-bg;
            table-layout: auto;

            thead {
              tr {
                background: transparent;

                th {
                  text-align: center;
                  color: $gray-base;
                  text-transform: uppercase;
                  border-bottom: $input-border-width solid $input-border-color;
                  padding: $input-padding-y $input-padding-x;
                }
              }
            }

            tbody {
              tr {
                background: transparent;

                td {
                  padding: 0.5rem;
                  text-align: center;

                  &.p-datepicker-other-month {
                    visibility: hidden;
                  }

                  span {
                    display: block;
                    @extend .rounded-circle;
                    text-align: center;
                    cursor: pointer;
                    width: 2rem;
                    height: 2rem;
                    padding-top: 0.25rem;
                    border: 1px solid transparent;

                    &:hover {
                      background: $gray-200;
                    }
                  }

                  &.p-datepicker-today {
                    span:not(.p-highlight) {
                      border-color: transparent;
                      background: $gray-255;
                    }
                  }

                  .p-state-highlight {
                    @extend .p-button-secondary;
                  }

                  .p-highlight {
                    @extend .p-button-primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
