.p-slider {
  background-color: $ui-slider-bg;
  position: relative;

  &-horizontal {
    height: 0.5rem;

    .p-slider-handle {
      margin-left: -0.6rem;
    }
  }

  &-range {
    position: absolute;
    display: block;
    height: 100%;
    background-color: $ui-slider-range-bg;
  }

  &-handle {
    position: absolute;
    top: 50%;
    margin-top: -0.75rem;
    height: $ui-slider-handle-height;
    width: $ui-slider-handle-width;
    background-color: $ui-slider-handle-bg;
    border: $ui-slider-handle-border;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
  }
}
