@import '~quill/dist/quill.core.css';

.p-inline-message {
  ul {
    list-style: none;
    padding: 0;

    li {
      color: $gray-base;
      border-radius: $border-radius;
      padding: $alert-padding-y $alert-padding-x;
      font-size: $font-size-sm;
    }
  }

  &.p-inline-message-error {
    li {
      background: $alert-error;
    }
  }

  &.p-inline-message-warn {
    li {
      background: $alert-warn;
    }
  }

  &.p-inline-message-success {
    li {
      background: $alert-success;
    }
  }

  .p-inline-message-icon {
    display: none;
  }
}

.messages-container {
  position: fixed;
  z-index: 9999;
  width: 50%;
}

p-messages {
  margin-left: 40px;
}

.p-messages {
  margin: 0.5rem 0;

  .p-message {
    &.p-message-error {
      background: $danger;
      color: $white;

      .p-message-wrapper {
        padding: 1rem;
        display: flex;
        align-items: center;
      }

      .p-message-icon {
        display: none;
      }

      .p-message-close {
        @extend .p-button-danger;

        &:hover {
          background-image: unset !important;
        }
      }
    }

    &.p-message-success {
      background: $success;
      color: $white;

      .p-message-wrapper {
        padding: 1rem;
        display: flex;
        align-items: center;
      }

      .p-message-icon {
        display: none;
      }

      .p-message-close {
        @extend .p-button-success;

        &:hover {
          background-image: unset !important;
        }
      }
    }
  }
}
