@import "~bootstrap/scss/grid";

.row-cols {
  > .col {
    margin-top: $card-spacer-y;
    margin-bottom: $card-spacer-y;

    .card {
      height: 100%;
    }
  }
}
