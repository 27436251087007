app-dashboard {
  app-layout-navbar {
    .app-layout-navbar {
      margin-bottom: 1rem;
    }
  }

  .row {
    min-height: 90vh;
    box-shadow: $card-box-shadow;
    text-align: center;
  }

  .col {
    padding: 0;
    box-shadow: $card-box-shadow;
    max-height: 90vh;

    &:nth-child(2n) {
      box-shadow: none;
    }

    .title {
      padding: 0.5rem;

      &--active {
        color: #23ae5d;
      }

      &--draft {
        color: #f52a29;
      }

      &--finished {
        color: #000;
      }

      &--hold {
        color: #ffc107;
      }
    }
  }

  .campaign-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 85vh;
    overflow: auto;

    &.no-content {
      @media (min-width: 992px) {
        min-height: 80vh;
      }
      @media (max-width: 991px) {
        min-height: 10vh;
      }
    }

    .campaign-content {
      margin: 0.5rem;
    }
  }
}

app-campaign-information {
  .p-card {
    width: 12rem;
    min-width: 12rem;
    max-width: 12rem;

    .p-card-content {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;

      .content-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child,
        &:nth-child(2) {
          .content:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 9rem;
            white-space: nowrap;
          }
        }

        &:last-of-type {
          justify-content: flex-start;

          .content {
            &:last-child {
              margin-left: auto;
            }
          }
        }

        .content {
          &.content-title {
            font-size: 14px;
            font-weight: $font-weight-semibold;
          }

          &.p-button-icon {
            padding: 0 0.2rem 0 0;
          }

          font-size: 0.8rem;
        }
      }
    }
  }
}
