app-group-template {
  .attributes-container {
    justify-content: center;
    flex-direction: column;

    // & > .col {
    //   margin: 1.5rem 0;
    // }
  }

  .group-nested-rule-item {
    flex-direction: column;
  }

  .rule-comparator {
    &.col-1 {
      display: flex;
      height: min-content;
      padding: 0.5rem 0;
      align-items: center;
      justify-content: flex-start;
      text-transform: uppercase;
    }
  }

  .rule-buttons,
  .rule-single-button {
    &.col-1 {
      padding: 0;
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .ui-button {
        padding: 0.25rem 0;
      }
    }
  }

  .rule-single-button.col-1 {
    padding: 0.5rem 0;
  }

  .rule-child-container {
    flex-direction: column;
  }

  .rule-icon-disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .marketing-permission-container {
    display: flex;
    align-items: flex-start;

    label {
      margin-left: 1rem;
    }
  }

  .group-attribute-container {
    display: flex;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    margin: 1rem 0;
    padding: 1rem 0;

    .p-selectbutton .p-button.p-highlight {
      background-color: gainsboro;
      color: black;
      border-color: black;
      border-width: 0px !important;

      &:hover {
        border-width: 0px !important;
      }
    }
  }

  .no-padding {
    padding: 0 !important;
  }
}

app-group-rule {
  app-form-group .p-inputtext {
    height: 80%;
  }
}
