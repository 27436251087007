app-loader {
  display: block;

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: 100%;

    &.spinner-primary {
      color: $primary;
    }

    &.spinner-white {
      color: $white;
    }
  }

  &.overlay,
  &.default {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.overlay {
    background: $gray-255;
    opacity: 0.5;
    border-radius: $border-radius;
    box-shadow: inset 0 0 10px $gray-255;
  }
}
