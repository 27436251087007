app-form-group, .app-form-group {
  &.is-filled,
  &.is-focused {
    p-dropdown + .p-label,
    p-multiSelect + .p-label,
    p-autoComplete + .p-label,
    p-calendar + .p-label,
    p-inputNumber + .p-label,
    .p-label {
      left: 0;
      top: -26px;
      opacity: 1;
      background: transparent;
      font-size: $font-size-xs;
    }

    ckeditor + .p-label {
      font-size: 1rem;
      top: -36px;
    }

    p-inputSwitch + .p-label {
      left: 3rem;
      top: -8px;
    }
  }

  &.is-readonly {
    p-dropdown,
    p-multiSelect,
    p-autoComplete,
    p-calendar,
    p-inputNumber {
      .p-inputtext,
      .p-dropdown-trigger {
        cursor: default;
        opacity: 0.6;
        background-color: $gray-200;
        pointer-events: none;
        user-select: none;

        &:focus {
          outline: none;
          user-select: none;
        }
      }
    }
    .p-inputtext {
      cursor: default;
      opacity: 0.6;
      background-color: $gray-200;
      pointer-events: none;
      user-select: none;

      &:focus {
        outline: none;
        user-select: none;
      }
    }

    p-editor {
      .ql-editor,
      .ql-toolbar {
        cursor: default;
        opacity: 0.6;
        background-color: $gray-200;
        user-select: none;
        pointer-events: none;

        * {
          cursor: default;
          user-select: none;
        }
      }
    }
  }
}
