@import '~primeng/resources/components/multiselect/multiselect';

.p-multiselect {
  position: relative;
  font-size: $font-size-sm;
  border: $ui-multiselect-border;
  border-radius: $ui-multiselect-border-radius;
  min-width: $ui-multiselect-min-width;
  transition: border-color 0.2s;
  display: flex;
  justify-content: space-between;

  .p-multiselect-label-container {
    padding: $ui-multiselect-padding-y $ui-multiselect-padding-x;
    cursor: pointer;
  }

  .p-multiselect-trigger {
    cursor: pointer;
    padding: $ui-multiselect-padding-y $ui-multiselect-padding-x $ui-multiselect-padding-y 0;
  }

  .p-multiselect-panel {
    overflow: auto;
    position: absolute;
    background: white;
    width: 100%;
    border: $ui-multiselect-border;

    .p-multiselect-items-wrapper {
      overflow-y: auto;

      ul.p-multiselect-items {
        padding: 0;

        .p-multiselected-item-token,
        .p-multiselected-empty-token {
          padding: 2px 4px;
          margin: 0 0.286em 0 0;
          display: inline-block;
          vertical-align: middle;
          height: 1.857em;
        }

        .p-multiselected-item-token {
          background: $primary;
          color: $white;
        }

        .p-multiselected-empty-token {
          background: $info;
          color: $white;
        }

        li.p-multiselect-item {
          padding: $input-padding-y $input-padding-x;
          font-size: $font-size-sm;
          cursor: pointer;

          span:not(.p-checkbox-icon) {
            margin-left: $input-padding-y;
            font-size: 0.7rem;
          }

          .p-widget {
            margin: 0 $input-padding-x 0 0;
          }

          &.p-state-highlight {
            background: $ui-multiselect-active-bg;
            color: $ui-multiselect-active-color;

            &:hover {
              background: $ui-multiselect-active-bg;
              color: $ui-multiselect-active-color;
            }
          }

          &:hover {
            background: $ui-multiselect-focus-bg;
            color: $ui-multiselect-focus-color;
          }
        }
      }
    }
  }
}
