@import '~primeng/resources/components/radiobutton/radiobutton';

p-radioButton {
  display: flex;
  vertical-align: middle;
  margin-bottom: 0.5rem;

  .p-radiobutton {
    display: inline-flex;
    cursor: pointer;
    width: 20px;
    height: 20px;

    .p-radiobutton-box {
      border: 2px solid $gray-400;
      background: $white;
      width: 20px;
      height: 20px;
      color: $gray-500;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;

      &.p-highlight {
        border-color: $primary;
        background: $primary;
        color: $white;
      }
    }

    .p-radiobutton-label {
      position: relative;
      margin: 0;
    }

    .p-radiobutton-icon {
      font-size: 12px;
      // padding-right:1px;
      // position: absolute;
      // left: 50%;
      // top:50%;
      // transform: translate(-50%, -50%);
      // margin:0;
    }
  }

  label {
    margin-left: 5px;
    line-height: 18px;
  }
}
