app-settings-user {
  th,
  td {
    width: 15rem;
  }

  th.column-action,
  td.column-action {
    width: 7rem;
  }

  .custom-input {
    margin-bottom: 0.5rem;
  }
}
