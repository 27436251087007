@mixin inputswitch-color($color) {
  .p-inputswitch-slider {
    &:before {
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background-color: $color;

      &:before {
        background-color: $white;
      }
    }
  }
}
