@import "~bootstrap/scss/reboot";

// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

label {
  // Allow labels to use `margin` for spacing.
  display: inline-block;
  margin-bottom: $label-margin-bottom;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
}
