@import '~primeng/resources/components/paginator/paginator';

.p-paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $pagination-item-bg;
  margin: $pagination-base-margin;
  padding: $pagination-base-padding;
  @include list-unstyled();

  .p-paginator-pages {
    display: flex;

    &:focus {
      outline: none;
    }
  }

  .p-paginator-element {
    //@extend .page-item;
    @extend .ui-clickable;

    &:focus {
      outline: none;
    }

    &.p-paginator-prev,
    &.p-paginator-next {
      //@extend .page-link;
      border: unset;
      background-color: $pagination-item-bg;

      &:hover {
        background-color: $pagination-item-bg;
        color: unset;
        text-decoration: none;
      }

      &:focus {
        outline: none;
      }
    }

    &.p-paginator-prev {
      padding: $pagination-padding-y 0;
    }

    &.p-paginator-next {
      padding: 0 $pagination-padding-y;
    }

    &.p-paginator-page {
      //@extend .page-link;
      @extend .rounded-circle;
      background-color: $pagination-item-bg;
      color: $pagination-item-color;
      margin: $pagination-margin-y $pagination-margin-x;
      width: $pagination-item-height;
      height: $pagination-item-width;
      font-size: $btn-font-size-sm;
      text-align: center;
      line-height: $pagination-item-line-height;
      padding: $pagination-padding-y 0;
      border: 1px solid;

      &:hover {
        text-decoration: none;
        color: $pagination-item-color;
        background-color: $gray-255;
      }

      &.p-highlight {
        background-color: $pagination-item-active-bg;
        color: $pagination-item-active-color;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .p-paginator-current {
    order: 2;
  }

  .p-paginator-first,
  .p-paginator-last {
    // aktualnie nie używamy bo nie ma ikonek pod to. jak będziemy używać to wyrzucić stąd
    display: none;
  }
}
