@import '~primeng/resources/components/autocomplete/autocomplete';

p-autoComplete {
  &.is-invalid {
    .p-autocomplete {
      border: 1px solid $red;
    }
  }

  .p-autocomplete {
    width: 100%;
    zoom: 1;
    cursor: pointer;
    box-shadow: none;
    position: relative;
    display: inline-block;

    &:before {
      font-family: 'primeicons';
      content: '\e908';
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem 0.75rem;
      background-color: transparent;
      height: 100%;
    }

    &.add-button:before {
      color: $success;
      content: '\f174';
    }

    .p-autocomplete-input {
      //@extend .form-control;
    }

    .p-autocomplete-dropdown {
      height: 100%;
      width: 2rem;
      margin-right: 0;
      vertical-align: top;
    }

    .p-autocomplete-panel {
      position: absolute;
      padding: 0;
      border: $ui-autocomplete-panel-border;
      background-color: $ui-autocomplete-panel-bg;
      font-size: $ui-autocomplete-panel-font-size;
      width: 100%;

      .p-autocomplete-list {
        max-height: 200px;
        overflow: auto;

        .p-autocomplete-list-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: $ui-autocomplete-panel-item-padding-y $ui-autocomplete-panel-item-padding-x;

          &:hover {
            background: $ui-autocomplete-focus-bg;
            color: $ui-autocomplete-focus-color;
          }
        }
      }
    }

    .pi-spinner:before,
    .pi-spin:before,
    .pi-spinner:before {
      content: '\f16c';
    }

    .p-autocomplete-loader {
      display: none;
    }

    &.p-autocomplete-sm {
      &:before {
        font-size: $input-font-size-sm;
        padding: 0.25rem 0.5rem;
      }

      .ui-autocomplete-input {
        //@extend .form-control-sm;
      }
    }
  }

  .p-autocomplete-multiple {
    //@extend .form-control;
    height: auto;

    &:focus-within {
      box-shadow: $input-focus-box-shadow;
    }

    ul.p-autocomplete-multiple-container {
      list-style-type: none;
      border: none;
      padding: 0;
      margin: 0;
      height: auto;

      li.p-autocomplete-token {
        @extend .p-chips-token !optional;
        margin-bottom: $spacer / 4 !important;

        span.p-autocomplete-token-icon {
          @extend .p-chips-token-icon !optional;
        }

        span.p-autocomplete-token-label {
          @extend .p-chips-token-label !optional;
        }
      }
    }

    input {
      border-style: none;

      &:focus {
        outline: none;
      }
    }
  }

  .p-autocomplete-item {
    padding: 0.5rem;
    &:hover {
      background-color: $gray-200 !important;
    }
  }
}
