// Color system
// stylelint-disable

$white: #ffffff;
$gray-base: #333333; // base font color
$gray-100: #fafbfc; // main bg, table even rows bg
$gray-200: #f0f1f2; // input border, input icon, dropdown hover, light button bg
$gray-255: lighten($gray-base, 65%);
$gray-300: lighten($gray-base, 55%);
$gray-400: lighten($gray-base, 50%);
$gray-500: lighten($gray-base, 45%);
$gray-600: lighten($gray-base, 40%); // secondary button bg
$gray-700: lighten($gray-base, 30%);
$gray-800: lighten($gray-base, 25%);
$black: #000000;

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
  ),
  $grays
);

$blue-base: #263551;
$blue-255: lighten($blue-base, 65%);
$violet: #6f377a;

$blue: #4276f2;

$red: #f52a29;
$red-secondary: #eb1d26;

$green: #23ae5d;
$green-secondary: #23ae5d;

$orange: #f8cb00;
$yellow: #ffc107;

$alert-error: #eed4d6;
$alert-warn: darken($yellow, 30%);
$alert-success: #e0ecdb;

$colors: ();
$colors: map-merge(
  (
    'pink': $blue-base,
    'violet': $violet,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'white': $white,
    'gray': $gray-300,
    'gray-dark': $gray-400,
  ),
  $colors
);

$primary: $blue-base;
$accent: $blue-base;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;

$secondary: $gray-700;
$light: $gray-200;
$dark: $gray-base;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'accent': $accent,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

$theme-schemas: ();
$theme-schemas: map_merge(
  (
    'primary': (
      bg-start: $primary,
      bg-end: $accent,
      color: $white,
    ),
    'success': (
      bg-start: $green,
      bg-end: $green-secondary,
      color: $white,
    ),
    'danger': (
      bg-start: $danger,
      bg-end: $red-secondary,
      color: $white,
    ),
    'secondary': (
      bg-start: $gray-700,
      bg-end: $gray-700,
      color: $white,
    ),
    'light': (
      bg-start: $gray-200,
      bg-end: $gray-200,
      color: $gray-base,
    ),
    'white': (
      bg-start: $white,
      bg-end: $white,
      color: darken($primary, 25%),
    ),
  ),
  $theme-schemas
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-base;

// Links
//
// Style anchor elements.

$link-color: $gray-base;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;
