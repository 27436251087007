.p-input-group {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: $ui-inputgroup-margin-bottom;

  .p-input-group-icon {
    position: relative;
  }

  .p-label {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px 0 0 10px;
    transition: all 350ms;
    opacity: 0.5;
    pointer-events: none;
    font-size: $font-size-sm;
  }

  .p-inputtext {
    &:not(:last-child) {
      border-width: 1px;
    }

    &:-webkit-autofill + label,
    &:focus + label,
    &:valid + label {
      top: -26px;
      opacity: 1;
      background: transparent;
      font-size: $font-size-xs;
    }
  }

  .p-inline-message {
    &.p-inline-message-error {
      color: $danger;

      .p-inline-message-text {
        font-size: $font-size-xs;
      }
    }
  }

  .p-form-control-icon {
    position: absolute;
    z-index: 2;
    display: block;
    width: $input-height;
    height: $input-height;
    line-height: $input-height;
    text-align: center;
    pointer-events: none;
    color: $primary;
    font-size: $input-font-size-sm;
    top: 0;
    background-color: white;
    border: 1px solid $gray-200;
    border-left: none;

    &.p-form-control-icon-append {
      right: 0;
    }

    &.p-form-control-icon-prepend {
      left: 0;
    }
  }

  .p-inputtext:focus {
    & + .p-form-control-icon {
      border: 2px solid black;
      border-left: none;
    }
  }
}
