p-tabView {
  .p-tabview {
    &-nav {
      border: 0;
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
      list-style-type: none;
      width: max-content;

      & {
        border-width: 0 0 2px 0;
      }

      li {
        margin-right: 0;
        border-bottom: 1px solid $gray-255;
        // flex: 0;

        .p-tabview-nav-link {
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          text-decoration: none;
          overflow: hidden;
          margin: 0 0 -2px 0;
          transition: box-shadow 0.2s;
          font-weight: $font-weight-semibold;
          padding: $card-spacer-y 1.5rem;
          color: $gray-400;
          border: 0 solid transparent;
          border-bottom: 2px $gray-255;

          .p-tabview-title {
            margin: 0 auto;
          }
        }

        &.p-highlight {
          .p-tabview-nav-link {
            border-color: $primary;
            color: $primary;
            border-style: solid;
          }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
          .p-tabview-nav-link {
            //background: $white;
            border-color: $gray-300;
            color: $gray-300;
          }
        }
      }
    }

    &-title {
      line-height: 1;
    }

    &-panels {
      border: 0 none;
      color: $input-color;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &-panel {
      //TODO change IT!!!
      //display: flex;
      // flex-direction: column;
      // align-items: flex-start;
      //padding: 0;
      //overflow-y: auto;
      max-height: 20rem;

      &-content {
        margin: 0.25rem 0;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}
