p-inputNumber {
  .p-inputnumber {
    display: block;
  }

  &.is-invalid {
    .p-inputtext {
      border: 1px solid $danger;
    }

    & + .p-inline-message {
      &.p-inline-message-error {
        color: $danger;

        .p-inline-message-text {
          font-size: $font-size-xs;
        }
      }
    }
  }
}
