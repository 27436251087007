@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

app-messages {
  .contextmenu-cell {
    text-align: center;
  }

  .column-action {
    width: 6rem;
    text-align: center;
  }
}
