.p-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-field {
  label {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px 0 0 10px;
    transition: all 350ms;
    opacity: 0.5;
    pointer-events: none;
    font-size: $font-size-sm;
  }

  .p-inputtext {
    &:-webkit-autofill + label,
    &:focus + label,
    &:valid + label {
      top: -26px;
      opacity: 1;
      background: transparent;
      font-size: $font-size-xs;
    }
  }
}
