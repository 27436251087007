@import "~bootstrap/scss/forms";

.form-control-label {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 0 0 10px;
  transition: all 350ms;
  opacity: .5;
  pointer-events: none;
  font-size: $font-size-sm;
}
