@import 'insights-header';
@import 'insights-table';

app-campaign-insights {
  .insights-navbar {
    font-size: 2rem;
    justify-content: flex-start;
  }

  .container-box {
    border: 1px solid $gray-255;
    padding: $input-btn-padding-y-sm * 2;
  }

  .insights-container {
    margin-bottom: 1rem;

    .insights-special-space {
      &.col-6 {
        padding-left: 2rem;
      }
    }
  }

  .insights-width {
    width: 100%;
  }

  .inner-space {
    min-height: 12rem;
  }

  .insights-text {
    color: $gray-800;
  }
}
