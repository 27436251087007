app-create-campaign,
app-edit-campaign {
  .create-campaign-column {
    padding: 0.5rem 1rem;
  }

  .create-campaign-type {
    padding-top: 0.5rem;
    height: 2rem;
  }

  .create-campaign-desc-input {
    min-height: 12rem;
    max-height: 12rem;
  }

  .create-campaign-calendar {
    display: flex;
    align-items: center;
  }

  .campaign-type {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
  }

  .end-date-calendar-container {
    app-form-group {
      position: relative;
    }

    app-icon {
      cursor: pointer;
      color: $gray-300;
      position: absolute;
      right: -2rem;
      top: 0.6rem;
      transform: translate(-50%, 0%);

      &:hover {
        color: $gray-600;
      }
    }
  }

  app-calendar {
    width: 100%;
  }

  p-calendar .p-calendar .p-inputtext {
    min-width: auto;
  }

  .p-dropdown-label.p-placeholder {
    opacity: 0.5;
  }

  .p-selectbutton-statuses {
    .p-selectbutton {
      display: flex;
      @media (min-width: 992px) {
        align-items: center;
      }
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .p-button:first-of-type {
        &.p-highlight {
          @extend .p-button-success;
        }

        .p-button-label {
          padding: 0;
        }
      }

      .p-button:last-of-type {
        &.p-highlight {
          @extend .p-button-danger;
        }

        .p-button-label {
          padding: 0;
        }
      }
    }
  }
}
