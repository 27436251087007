.p-selectbutton {
  display: inline-flex;
  //@extend .btn-group;
  //@extend .btn-group-sm;

  .p-button {
    @extend .p-button-light;
    cursor: pointer;
    padding: 0.4rem 1rem !important; //TODO: change global variables

    &.p-highlight {
      //background-image: none;
      //background-color: white;
      //color: green;
      //border: 1px solid green;
      @extend .p-button-primary;
    }

    .p-button-label {
      padding: 0;
    }
  }
}

.p-selectbutton-green {
  .p-selectbutton {
    display: inline-flex;

    .p-button {
      background-image: none;
      background-color: $white;
      color: $black;
      border: 1px solid $green;
      cursor: pointer;
      padding: 0.4rem 1rem !important;

      &.p-highlight {
        @extend .p-button-success;
      }

      &:hover {
        background-color: $green;
        color: $white;
        background-image: none;
        border: 1px solid $green;
      }

      .p-button-label {
        padding: 0;
      }
    }
  }
}
