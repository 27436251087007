.p-fileupload-choose {
  padding: 0;
}

.p-fileupload-row > div {
  flex: unset !important;
  width: unset !important;
  padding: 0 0.5rem;
}

.p-fileupload-buttonbar {
  display: flex;
  align-items: center;
}
