app-layout-footer {
  display: block;

  .app-layout-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 2rem 0;

    &.settings-navbar {
      justify-content: flex-start;
    }

    .row {
      flex: 1;
    }
  }
}
