app-settings-button,
app-settings-notification {
  $header-height: 2.5rem;
  $footer-height: 1rem;
  $container-height: 11rem;
  $container-padding: 0.75rem;
  $image-container-width: 16rem;
  $image-container-border: 1.5px solid $gray-300;

  .image-container {
    width: $image-container-width;
    height: $container-height;
    border: $image-container-border;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    padding: $container-padding;

    .image-header {
      font-size: 0.8rem;
      position: relative;
      height: calc(#{$header-height} - #{$container-padding});
    }

    .image-footer {
      font-size: 0.8rem;
      position: relative;
      height: $footer-height;

      .image-error {
        color: red;
      }
    }

    .inner-container {
      background-color: $gray-200;
      position: relative;
      text-align: center;
      vertical-align: bottom;
      width: 100%;
      height: calc(#{$container-height - 1} - (#{$container-padding} * 2));

      .fully-cover {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border:0;
      }

      .overlay {
        position: absolute;
        bottom: 0;
        height: 2rem;
        width: 100%;
        opacity: 0.9;
        cursor: pointer;

        span {
          display: none;
        }
      }

      &:hover .overlay,
      .overlay:hover {
        border: solid 1px $gray-100;
        background-color: $gray-200;

        span {
          @extend .fully-cover;
        }
      }

      .label-container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      img {
        width: 100px;
        height: auto;
        vertical-align: bottom;
        cursor: pointer;
      }

      input[type='file'] {
        display: none;
      }

      i {
        display: inline-block;
      }
    }

    .image-header + .inner-container {
      height: calc(#{$container-height - 1} - #{$header-height} - #{$container-padding});
    }
  }

  .right-positioned {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }

  .left-positioned {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;

    & > span:first-child {
      cursor: pointer;
    }
  }

  .ui-dialog p {
    white-space: normal;
  }
}
