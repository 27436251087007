p-toast {
  .p-toast {
    position: fixed;
    width: 20rem;

    &.p-toast-top-right {
      top: 1rem;
      right: 1rem;
    }

    &.p-toast-top-left {
    }

    &.p-toast-bottom-right {
    }

    &.p-toast-bottom-left {
    }

    &.p-toast-top-center {
    }

    &.p-toast-center {
    }

    p-toastItem {
      .p-toast-message {
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        margin: 0.25rem 0 0.25rem 0;
        overflow: hidden;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);

        &-content {
          display: flex;
          align-items: center;
        }

        &.p-toast-message-info {
        }

        &.p-toast-message-success {
          background: $alert-success;
        }

        &.p-toast-message-warn {
          background: $alert-warn;
        }

        &.p-toast-message-error {
          background: $alert-error;
        }
      }

      .p-message-icon,
      .p-toast-message-icon,
      .p-toast-icon-close {
        display: none;
      }
    }
  }
}
