app-campaign-insights-header {
  .outer-container {
    h2,
    h3 {
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.5rem;
      }
    }
  }

  .inner-container {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: $input-btn-padding-y-sm * 2;

    & .col-3 {
      text-align: center;
    }

    .col-7 {
      @media (max-width: 1440px) {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }

  .container-box {
    & .inner-container {
      padding-bottom: unset;
    }

    .inner-container-blue {
      color: $blue;
    }

    .inner-container-green {
      color: $green;
    }

    .col-3 {
      max-width: 33.3333333333%;
      @media (max-width: 1440px) and (min-width: 1200px) {
        max-width: 42%;
      }

      @include media-breakpoint-down(lg) {
        flex: 0;
        max-width: 62%;
      }
      @include media-breakpoint-up(lg) {
        h3 {
          font-size: 1.8rem;
        }
      }
    }

    .col-3:last-of-type {
      align-self: flex-end;
    }
  }
}
