app-campaign-insights-table {
  .container-box {
    height: 100%;

    .row-space {
      margin-bottom: 0.5rem;
    }
  }

  .container-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  .container-header {
    margin-bottom: 0.5rem;
  }

  .text-wrapping {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
