.sidebar {
  font-family: $font-family-headings;
  display: flex;
  flex-direction: column;
  padding: $sidebar-padding;
  color: $sidebar-color;
  @include gradient-y($sidebar-bg-start, $sidebar-bg-end);
  @include borders($sidebar-borders);

  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 $spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: $navbar-height;
    color: $sidebar-color;
    background: 0;
    border: 0;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .brand {
    display: block;
    padding: $spacer 0;
    text-align: center;

    img {
      color: $white;
      width: 10rem;
    }
  }

  .brand-minimized {
    display: none;
  }

  .sidebar-header {
    flex: 0 0 $sidebar-header-height;
    padding: $sidebar-header-padding-y $sidebar-header-padding-x;
    text-align: center;
    background: $sidebar-header-bg;
  }

  .sidebar-nav {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    @include sidebar-width($sidebar-borders, $sidebar-width);
  }

  .nav {
    @include sidebar-width($sidebar-borders, $sidebar-width);
    flex-direction: column;
    min-height: 100%;
    padding: 0;
  }

  .nav-title {
    padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
    font-size: 80%;
    font-weight: 700;
    color: $sidebar-nav-title-color;
    text-transform: uppercase;
  }

  .nav-divider {
    height: 10px;
  }

  .nav-item {
    position: relative;
    margin: 0;
    transition: background 0.3s ease-in-out;
  }

  .nav-dropdown-items {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    transition: max-height 0.3s ease-in-out;

    .nav-item {
      padding: 0;
      list-style: none;
    }
  }

  .nav-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: $sidebar-nav-link-height;
    padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
    color: $sidebar-nav-link-color;
    text-decoration: none;
    background: $sidebar-nav-link-bg;
    @include borders($sidebar-nav-link-borders);
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }

    .nav-icon {
      display: block;
      margin: 0 ($sidebar-nav-link-padding-x / 2) 0 0;
      font-size: $sidebar-nav-icon-size;
      color: $sidebar-nav-link-icon-color;
      text-align: center;
    }

    span {
      font-size: $sidebar-nav-link-font-size;
      text-transform: uppercase;
      font-weight: 300;
    }

    .badge {
      float: right;
      margin-top: 2px;
    }

    &.active {
      color: $sidebar-nav-link-active-color;
      background: $sidebar-nav-link-active-bg;
      @include borders($sidebar-nav-link-active-borders);

      .nav-icon {
        color: $sidebar-nav-link-active-icon-color;
      }
    }

    &:hover {
      color: $sidebar-nav-link-hover-color;
      background: $sidebar-nav-link-hover-bg;
      @include borders($sidebar-nav-link-hover-borders);

      .nav-icon {
        color: $sidebar-nav-link-hover-icon-color;
      }

      &.nav-dropdown-toggle::before {
        color: white;
      }
    }

    &.disabled {
      color: $sidebar-nav-link-disabled-color;
      cursor: $cursor-disabled;
      background: transparent;
      @include borders($sidebar-nav-link-disabled-borders);

      .nav-icon {
        color: $sidebar-nav-link-disabled-icon-color;
      }

      &:hover {
        color: $sidebar-nav-link-disabled-color;
        @include borders($sidebar-nav-link-hover-borders);

        .nav-icon {
          color: $sidebar-nav-link-disabled-icon-color;
        }

        &.nav-dropdown-toggle::before {
        }
      }
    }

    @each $color, $value in $theme-colors {
      &.nav-link-#{$color} {
        background: $value;

        .nav-icon {
          color: rgba(255, 255, 255, 0.7);
        }

        &:hover {
          background: darken($value, 5%);

          i {
            color: #fff;
          }
        }
      }
    }
  }

  .nav-dropdown-toggle {
    position: relative;

    &::before {
      //font-family: "Flaticon";
      position: absolute;
      top: calc(50% - 4px);
      right: $sidebar-nav-link-padding-x;
      display: block;
      width: 8px;
      font-size: 0.75rem;
      height: 8px;
      padding: 0;
      //content: $font-Flaticon-arrow-down;
      color: white;
      transition: transform 0.3s;
    }

    .badge {
      margin-right: 16px;
    }
  }

  .nav-dropdown.open {
    @include borders($sidebar-nav-dropdown-borders);
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }

    > .nav-dropdown-items {
      background: $sidebar-nav-dropdown-bg;
      max-height: 1500px;
      font-size: $font-size-sm;
    }

    .nav-link {
      padding-left: 2rem;
      color: $sidebar-nav-dropdown-color;
      border-left: 0;

      &.disabled {
        color: $sidebar-nav-link-disabled-color;
        background: transparent;

        &:hover {
          color: $sidebar-nav-link-disabled-color;

          .nav-icon {
            color: $sidebar-nav-link-disabled-icon-color;
          }
        }
      }
    }

    > .nav-dropdown-toggle::before {
      //content: $font-Flaticon-arrow-up;
    }

    .nav-dropdown.open {
      border-left: 0;
    }
  }

  .nav-label {
    display: block;
    padding: ($sidebar-nav-link-padding-y / 8) $sidebar-nav-link-padding-x;
    color: $sidebar-nav-title-color;

    &:hover {
      color: $sidebar-color;
      text-decoration: none;
    }

    .nav-icon {
      width: 20px;
      margin: -3px ($sidebar-nav-link-padding-x / 2) 0 0;
      font-size: 10px;
      color: $sidebar-nav-link-icon-color;
      text-align: center;
      vertical-align: middle;
    }
  }

  @if (lightness($sidebar-bg) < 40) {
    .progress {
      background-color: lighten($sidebar-bg, 15%) !important;
    }
  }

  .sidebar-footer {
    display: flex;
    justify-content: space-between;
    flex: 0 0 $sidebar-footer-height;
    padding: $sidebar-footer-padding-y $sidebar-footer-padding-x;
    background: $sidebar-footer-bg;
    @include borders($sidebar-footer-borders);

    .avatar {
      height: 2rem;
      width: 2rem;
      margin: 0;
    }

    ul.nav {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .sidebar-minimizer {
    position: relative;
    flex: 0 0 $sidebar-minimizer-height;
    cursor: pointer;
    background-color: $sidebar-minimizer-bg;
    border: 0;
    @include borders($sidebar-minimizer-borders);

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      width: $sidebar-minimizer-height;
      height: $sidebar-minimizer-height;
      content: '';
      background-image: $sidebar-minimizer-indicator;
      background-repeat: no-repeat;
      background-position: center;
      background-size: $sidebar-minimizer-height / 4;
      transition: 0.3s;
    }

    &:focus,
    &.focus {
      outline: 0;
    }

    &:hover {
      background-color: $sidebar-minimizer-hover-bg;

      &::before {
        background-image: $sidebar-minimizer-hover-indicator;
      }
    }
  }

  .sidebar-user {
    display: block;
    text-align: center;

    .user-image {
      img {
        width: 4rem;
        height: 4rem;
      }
    }

    .user-content {
      display: block;
      text-align: center;

      p {
        padding: $spacer / 4 0;
        margin: 0;
      }

      .fullname {
      }

      .group-name {
        font-size: $font-size-sm;
      }
    }

    ul.user-actions {
      display: block;
      list-style: none;
      margin: 0;
      padding: $spacer/2 0 0 0;
      font-size: $font-size-lg;

      li {
        padding: 0 $spacer/4;
        cursor: pointer;

        a {
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .sidebar-compact {
    .sidebar {
      .sidebar-nav {
        @include sidebar-width($sidebar-borders, $sidebar-compact-width);
      }

      .nav {
        @include sidebar-width($sidebar-borders, $sidebar-compact-width);
      }

      .d-compact-none {
        display: none;
      }

      .nav-title {
        text-align: center;
      }

      .nav-item {
        width: $sidebar-compact-width;
        border-left: 0;
      }

      .nav-link {
        text-align: center;

        .nav-icon {
          display: block;
          width: 100%;
          margin: $spacer / 4 0;
          font-size: 24px;
        }

        .badge {
          position: absolute;
          top: 18px;
          right: 10px;
        }

        &.nav-dropdown-toggle {
          &::before {
            top: 30px;
          }
        }
      }
    }
  }

  // Minimized Sidebar
  .sidebar-minimized {
    .sidebar {
      z-index: $zindex-sticky - 1;
      align-items : center;
      .brand {
        display: none;
      }

      .brand-minimized {
        display: block;
        padding: $spacer 0;
        text-align: center;

        .logo-container {
          height: 31.5px;
          width: 31.5px;
          background-image: url('../../../../assets/img/sc_blue_neg_grey_e6.png');
          background-size: contain;
          object-fit: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      .sidebar-nav {
        overflow: visible;
        @include sidebar-width($sidebar-borders, $sidebar-minimized-width);
      }

      .nav {
        @include sidebar-width($sidebar-borders, $sidebar-minimized-width);
      }

      .nav-dropdown.open {
        .nav-link {
          padding-left: 0;
        }
      }

      .d-minimized-none,
      .nav-divider,
      .nav-label,
      .nav-title,
      .sidebar-form,
      .sidebar-header {
        display: none;
      }

      .sidebar-minimizer {
        position: fixed;
        bottom: 0;
        width: $sidebar-minimized-width;
        height: $sidebar-minimizer-height;
        background-color: darken($sidebar-bg, 5%);
      }

      // stylelint-disable no-duplicate-selectors
      .sidebar-nav {
        padding-bottom: $sidebar-minimizer-height;
      }

      // Temporary fix end

      .sidebar-minimizer::before {
        width: 100%;
        transform: rotate(-180deg);
      }

      .nav-item {
        width: $sidebar-minimized-width;
        overflow: hidden;
        padding: .2rem 0;
      }

      section {
        :not(.nav-dropdown-items) > {
          .nav-item:last-child::after {
            display: block;
            margin-bottom: $sidebar-minimizer-height;
            content: '';
          }
        }
      }

      .nav-link {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 0;
        white-space: nowrap;
        border-left: 0;

        .nav-icon {
          display: block;
          margin: 0;
        }

        span {
          display: none;
        }

        .badge {
          position: absolute;
          right: 15px;
          display: none;
        }

        &:hover {
          width: $sidebar-minimized-width;
          background: $sidebar-nav-link-hover-bg;

          .badge {
            display: inline;
          }
        }

        &.nav-dropdown-toggle::before {
          display: none;
        }
      }

      .nav-dropdown-items {
        .nav-item {
          width: $sidebar-width;

          .nav-link {
            width: $sidebar-width;
          }
        }
      }

      .nav > .nav-dropdown {
        > .nav-dropdown-items {
          display: none;
          max-height: 1000px;
          background: $sidebar-bg;
        }

        &:hover {
          background: $sidebar-nav-link-hover-bg;

          > .nav-dropdown-items {
            position: absolute;
            left: $sidebar-minimized-width;
            display: inline;
          }
        }
      }

      .sidebar-user {
        .user-image {
          img {
            width: 2rem;
            height: 2rem;
          }
        }

        .user-content {
          display: none;
        }
      }

      .sidebar-footer {
        justify-content: center;
        padding: $sidebar-footer-padding-y $sidebar-footer-padding-x;

        ul.nav {
          .nav-item {
           text-align: center;
            padding: 0;
          }
        }
      }
    }
  }

  *[dir='rtl'] {
    .sidebar-minimized {
      .sidebar {
        .nav {
          list-style-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');

          .divider {
            height: 0;
          }
        }

        //.d-minimized-none,
        //.nav-divider,
        //.nav-label,
        //.nav-title,
        //.sidebar-footer,
        //.sidebar-form,
        //.sidebar-header {
        //  display: none;
        //}

        .sidebar-minimizer::before {
          width: 100%;
          transform: rotate(0deg);
        }

        .nav-link {
          padding-right: 0;

          .nav-icon {
            float: right;
          }

          .badge {
            right: auto;
            left: 15px;
          }

          &:hover {
            .badge {
              display: inline;
            }
          }
        }

        .nav > .nav-dropdown {
          > .nav-dropdown-items {
            display: none;
            max-height: 1000px;
            background: $sidebar-bg;
          }

          &:hover {
            background: $sidebar-nav-link-hover-bg;

            > .nav-dropdown-items {
              position: absolute;
              left: 0;
              display: inline;
            }
          }
        }
      }
    }
  }
}

*[dir='rtl'] {
  .sidebar {
    .nav-dropdown-toggle::before {
      position: absolute;
      right: auto;
      left: $sidebar-nav-link-padding-x;
      transform: rotate(180deg);
    }

    .nav-dropdown.open {
      > .nav-dropdown-toggle::before {
        transform: rotate(270deg);
      }
    }

    .nav-link {
      .nav-icon {
        margin: 0 0 0 ($sidebar-nav-link-padding-x / 2);
      }

      .badge {
        float: left;
        margin-top: 2px;
      }
    }

    .nav-link.nav-dropdown-toggle {
      .badge {
        margin-right: auto;
        margin-left: 16px;
      }
    }

    .sidebar-minimizer::before {
      right: auto;
      left: 0;
      transform: rotate(180deg);
    }
  }

  // Temp fix for rtl sidebar-toggler
  // ToDo: find better solution
  .sidebar-toggler {
    margin-right: 0 !important;
  }
}
.p-sidebar-content {
  height: 100%;
  background-color: #263551;
 
  a {
    color: white;
    text-decoration: none;
    &:hover{
      color: white;
    }
    .p-sidebar-close {
      color: white;
      background-color: #263551;
      .p-sidebar-close-icon {
        opacity: 1;
      }
    }
  }
  .logout-btn {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}