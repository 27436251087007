@import '~primeng/resources/components/contextmenu/contextmenu';

p-contextMenu {
  & + app-icon {
    &:hover {
      color: $gray-300;
    }
  }

  .p-contextmenu {
    padding: 0;
    background-color: $white;
    //@extend .card-shadowed;
    position: absolute;
    top: unset !important;
    left: unset !important;
    display: none;
    width: 10rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

      li.p-menuitem {
        &:focus {
          outline: none;
        }

        //.p-contextmenu ul.p-menu-list li.p-menuitem a.p-menuitem-text:hover {
        //  background-image: linear-gradient(to right, $primary, $accent);
        //  color: white !important;
        //}

        a.p-menuitem-link {
          display: block;
          padding: $input-padding-y $input-padding-x;
          text-decoration: none;

          &:hover {
            background-image: $background-image-gradient;
            color: white;
          }

          &:focus {
            outline: none;
          }

          .p-menuitem-icon {
            padding-right: $input-padding-x;
          }
        }
      }
    }
  }
}
